import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading"; 
import { openModal } from "../../actions/modalAction";
import { searchCompanys,updateCompanyRanking } from "../../actions/companysAction";
import { shortlistAddRemoveDealer } from "../../actions/authAction";
import {createContactReq} from "../../actions/contactReqAction";

import {
  Icon,
  Button,
} from "semantic-ui-react";



class DealerSearchList extends Component {
  state = {
    pageO:'',
    id:'',
  };
  componentDidMount() {
    const {offset, limit} = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
    this.props.searchCompanys(values, limit,offset);
    
  }
  handleSearchComp = () => {
    this.props.openModal("SearchCompanyModal");
  }
  //**** */
  handleOpenModalImage = (id) => {   
    this.props.openModal("ShowImagesCompanyModal" , {id:id});
    };
  handleOpenModal = (url) => {   
    this.props.openModal("ImageZoomModal" , {imageURL:url});
  };
  // **************
  contactReqCreateHandler=(id,name,contactR)=>{
    const values= {...contactR,dealer:id,dealerName:name,}
   
    if (!this.props.userA) {
      this.props.openModal('LoginModal')

    }else{
      this.props.createContactReq( values)
    }

  }
  
  
  shortlistAddRemoveHandler = (id) => {
      if (!this.props.userA) {
        this.props.openModal('LoginModal')

      }else{
        this.props.shortlistAddRemoveDealer(id);
      }

    };
    detailButtonHandler = () => {
      if (!this.props.userA) {
        this.props.openModal('LoginModal') 
      }
    };
// ***************************


    
  handleUpdateRanking = (id) => {
  this.props.updateCompanyRanking(id)
  }
  //^^^^^^^^^^^^^^^^^^^^^^^^^
    onChange=(e) => {
      this.setState({ [e.target.name]: e.target.value });
    }
  //*******************************************************
  back = () => {
    const {
      offset,
      limit,
      limitStatic,
      offsetStatic
      
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
    if (offset === 0) {
      return;
    }

    this.props.searchCompanys(values, limit, (offset - limit),limitStatic, (offsetStatic - limitStatic));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      offset,
     limit,
     count,
     limitStatic,
     offsetStatic
      
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
    if (offset + limit > count) {
      return;
    }

    this.props.searchCompanys(values, limit, (offset + limit), limitStatic, (offsetStatic + limitStatic));
    window.scroll(0,0)
  };
//***************** */
handleGoToPage = () => {
  const {
  
   limit,
   count,
   limitStatic,
   
  } = this.props;
  const values =
    (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
   // use Math.abs for strict equality ===  to work and safe guard against negative value from user
   if (Math.abs(this.state.pageO) === 0) {
    return; 
  }

const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)


  this.props.searchCompanys(values, limit, (page * limit), limitStatic, (page * limitStatic));
  window.scroll(0,0)

};

//****************** */
  renderPaginator() {
    if (this.props.companys.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
        />
      );
    }
  }

  //************************
  renderList=(company)=> {
    const {
      nameClass,
      cname,
      
      cimage,
      
      locality,
      city,
      
      nameCP1,
      //mobileCP1,
      
      designationCP1,
      
      nameCP2,
      //mobileCP2,
      
      designationCP2,
      
      typeOfDealings,
      typeOfPropertyDealsIn,
      photoCP1,
    photoCP2,
      locationOnMap,
      _id
    } = company;

    //const id =  company._id;

    const rendertypeOfDealings = () => {
      return typeOfDealings.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} <span style={{color:'skyblue'}}>|</span></b>
          </span>
        );
      });
    };
    const rendertypeOfPropertyDealsIn = () => {
      return typeOfPropertyDealsIn.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} <span style={{color:'skyblue'}}>|</span></b>
          </span>
        );
      });
    };
    const {uname,
      uphone1,
      uphone2,
      uemail,
      uwhatsapp,
  
        }=this.props.userA !== null && this.props.userA
      const  requesterId= this.props.userA !== null && this.props.userA._id
    const contactReq= {
      requestFor:'General',
      uname,
    uphone1,
    uphone2,
    uemail,
    uwhatsapp,
    requesterId,
      }
  
      
  
    const renderContactReqButtonOrNot = () =>
          this.props.userA != null &&
          (this.props.userA.userTypeW ==='Dealer' ||
          this.props.userA.userTypeW ==='Builder') ? (
            <div></div>
  
          ) : 
          (
        <Button
        floated="right"
        color="blue"
        type="button"
        onClick={() => this.contactReqCreateHandler(_id,cname,contactReq)}
        >
          Ask Dealer To Contact You
         </Button>
      
  
          );
          //************************
  

    const renderButton = () =>
     this.props.auth.isAuthenticated ? (
        <Button 
        onContextMenu={(e)=> e.preventDefault()}
        floated="right"
        color="teal"
        type="button"
        as={Link}
        to={`/company/company/${_id}`}
        onClick={() => this.detailButtonHandler()}
      >
        Detail
      </Button>

      ) :(
        <Button 
        floated="right"
        color="teal"
        type="button"
        //as={Link}
        // to={`/company/company/${_id}`}
        onClick={() => this.detailButtonHandler()}
      >
        Detail
      </Button>

      ) 

 
      const checkID = this.props.userA !== null && this.props.userA.shortlistDealer !==undefined && this.props.userA.shortlistDealer.filter(val => {

      return val.toString() === _id;
    });

    const setShortlistBgColor= () =>{
      if(checkID.length > 0){
        return {backgroundColor:'#e6f5ff'}
        }
    }

    const renderShortlistButton = () =>
          checkID.length > 0 ? (
            <span
            onClick={() => this.shortlistAddRemoveHandler(_id)}
            className="icon-button1 ml-010"
            >
            <img className= 'yimg ' src='/statusIconOk.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>
      ) : (
        <span
 onClick={() => this.shortlistAddRemoveHandler(_id)}
 className="icon-button1 ml-010"
 >
 <img className= 'yimg ' src='/shortlistIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
        
      );

  //******************
  const classN = nameClass ==='static'? 'cardStatic border mb-010':'card border mb-010'
//*******************************************
return (
  <div key={_id} >
        <div className={classN} style={{padding:'5px',...setShortlistBgColor()}}>{/* 1 */}


        <div className='disflex-dsl border-bottom nodisplay' >{/* 2 */}
          

        <img className= 'imgDimention img-object' src={`${cimage}`} alt="Company Logo"  />

        <div style={{flex:'80%',  marginLeft:'10px',padding:'0px'}}>
          <div className='font-dsl-head'> {cname}</div >
          <div className='font-dsl-loc'>{locality}{' '}{city} New Delhi 
          
          <span>
          <a target='_blank' rel="noreferrer noopener"   href={`${locationOnMap}`}>
              <span className="ml-05">
              {' '} <img className= 'yimg' src='/mapIcon.png' alt="Location on Map" style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
              </span>
              </a>
                  </span>
          </div>
          </div>

        </div>{/* 2 */}
        {/* for mobile start */}
<div className='nodisplay-tab'>
<div className='disflex-dsl border-bottom' >{/* 2 */}
              {/* <Image
              floated="left"
              size="tiny"
              src={`${cimage}`}
              /> */}

                <img className= 'imgDimention-ad img-object' src={`${cimage}`} alt="Company Logo"  />

                <div style={{flex:'80%',  marginLeft:'10px',padding:'0px'}}>
                <div className='font-dsl-head-main'> {cname}</div >

                </div>

                </div>{/* 2 */}
                <div className='font-dsl-loc-main'>{locality}{' '}{city} New Delhi 
                <span>
                <a target='_blank' rel="noreferrer noopener"   href={`${locationOnMap}`}>
              <span className="ml-05">
              {' '} <img className= 'yimg' src='/mapIcon.png' alt="Location on Map" style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
              </span>
              </a>
                  </span>
                </div>

</div>
        {/* for mobile end */}
        <div> {/* 3 */}
        <div style={{padding:'5px'}}>{/* 4 */}
        <div className='display-end' style={{marginBottom:'10px'}}>
        <div className= 'display-flex' >  
        {renderContactReqButtonOrNot()}
        {renderButton()}
        {(this.props.userA != null && 
          this.props.userA.userTypeW ==='Individual') && renderShortlistButton()}
        {/*  */}
		<span
 onClick={()=>this.handleOpenModalImage(_id)}
 className="icon-button1 ml-010"
 >
 <img className= 'yimg ' src='/imageAddIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
 {/*  */}
       
        </div>

         </div>
         {/*  */}
<div className='media-600 ' style={{display:'flex',justifyContent:'center'}}> {/*start of div containing cp1 and cp2 detail */}

{ nameCP1 &&  (<div className='card-cp' > {/* cp1 detail*/}
  <div className='flex-value-DA' style={{display:'flex',flexDirection:'column'}}>
  <img 
  className="icon-button"
  onClick={() => this.handleOpenModal(photoCP1)}
  src={`${photoCP1}`} alt="Photo_First contact person" 
  style={{width:"100px", height:"100px",padding:'5px'}} />
  </div>


<div className='flex-value-DB 'style={{padding:'10px',fontWeight:"bold"}}>


<div>{nameCP1 && (
<div >
  {nameCP1}
</div>
)}
</div>  {/* */}

<div>{designationCP1 && (
<div>{designationCP1}</div>
)}</div>  {/* */}

  {/* */}

  </div>
  </div>)}
{/*end cp1 details */}

  { nameCP2 &&  (<div className='card-cp' >{/* cp2 detail*/}
  <div className='flex-value-DA' style={{display:'flex',flexDirection:'column'}}>
  <img
  className="icon-button"
  onClick={() => this.handleOpenModal(photoCP2)}
  src={`${photoCP2}`} alt="Photo_Second contact person" 
  style={{width:"100px", height:"100px",padding:'5px'}} />
  </div>


<div className='flex-value-DB 'style={{padding:'10px',fontWeight:"bold"}}>


<div>{nameCP2 && (
<div >
  {nameCP2}
</div>
)}
</div>  {/* */}
<div>{designationCP2 && (
<div>{designationCP2}</div>
)}</div>  {/* */}
  {/* */}

  </div>
  </div>)}

</div> 

    {/*end of div containing cp1 and cp2 detail */}

<div className='row'></div>

 <div >
  {typeOfDealings != null &&
               typeOfDealings.length >= 1 && (
   <div className='row'>
     <div className='side'>
       <b>Type of Dealings</b>
     </div>
     <div className='main'>
       {rendertypeOfDealings()}
     </div>

   </div>
 )}
</div>
<div >
{typeOfPropertyDealsIn != null &&
             typeOfPropertyDealsIn.length >= 1 && (
   <div className='row'>
     <div className='side'>
       <b>Type Of Property deals in</b>
     </div>
     <div className='main'>
        {rendertypeOfPropertyDealsIn()}
     </div>

   </div>
 )}
</div>
</div>{/* 4 */}


        </div>{/* 3 */}

        </div>{/*1  */}
  </div>



);


  }

  //###########################################renderListStatic end
  renderLoading() {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
  }


  render() {
    //********************** */
    return (

      <div>
      <div className='maindiv-820'>
{/**/}
<div className='cashbook-H box-shadow'>

<div
style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
onClick={this.handleSearchComp}>
      <span className='icon-button2 border1' > <Icon name='search' /></span >
    </div>



<div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
{this.props.count}{' '}Dealers in {this.props.city}
</div>
</div>


</div>
{/**/}
      <div>
      
        {this.renderLoading()}


        {this.props.staticTop1.map((val)=>{ return {...val,nameClass:'static'}}).map(this.renderList)}
        {this.props.staticTop2.map((val)=>{ return {...val,nameClass:'static'}}).map(this.renderList)}

        {this.props.companys.map(this.renderList)}

        {this.props.staticBottom1.map((val)=>{ return {...val,nameClass:'static'}}).map(this.renderList)}
        {this.props.staticBottom2.map((val)=>{ return {...val,nameClass:'static'}}).map(this.renderList)}

        {/*  */}
<div className='disflex-paginator card-paginator media-600'>
{this.renderPaginator()}
<div className="disflex-paginator  " >
  <div className= 'font-paginator'>Go To Page</div>
  <form>
        <input
        className='inputPage'
          placeholder="number"
          name="pageO"
          type="number"
          value={this.state.pageO}
          onChange={this.onChange}
        />
        <span  className="btnNumber"  onClick={this.handleGoToPage} >
          Submit
        </span>
      </form>

        
</div>
<br/>
</div>
{/*  */}

      <br/><br/><br/>

      </div>
      


      </div>
      </div>

        );

    //************************ */


  }
}

const mapStateToProps = state => {

  return {
    city: state.companys.city,
    fetching: state.companys.fetching,
    offset: state.companys.offset,
    limit: state.companys.limit,
    count: state.companys.count,
    offsetStatic: state.companys.offsetStatic,
    limitStatic: state.companys.limitStatic,
    countStatic: state.companys.countStatic,
    staticTop1: state.companys.staticTop1,
    staticTop2: state.companys.staticTop2,
    staticBottom1: state.companys.staticBottom1,
    staticBottom2: state.companys.staticBottom2,
    companys: state.companys.companys,
    users: state.auth.usersA,
    userA: state.auth.userA,
    auth:state.auth,
    form: state.form.CompanySearch
  };
};
export default connect(
  mapStateToProps,
  { openModal, searchCompanys ,updateCompanyRanking,
    createContactReq, shortlistAddRemoveDealer}
)(DealerSearchList);
