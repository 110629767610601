import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from "redux-form";
import { connect } from 'react-redux';
import { formPassReset } from '../../actions/authAction';
import { closeModal } from "../../actions/modalAction";
import {
  Button,
  Form,
  Image,
} from 'semantic-ui-react';
import {userTypeWOption } from "./Data";
import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";
import {
  combineValidators,
  isRequired,
} from 'revalidate';

const validate = combineValidators({
//************user */

  cid:isRequired('Company ID') ,
  userId:isRequired('UserId') ,
  password:isRequired('Password') ,
  userTypeW:isRequired('Login As') ,
  
});

class ReqForOTP extends Component {
  // state = {
  //   showP:false,
  // }

   
  onSubmit = values => {
    this.props.formPassReset(values, this.props.history);
    this.props.reset()
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }

  //toggle = () => this.setState({ showP: !this.state.showP })
  render() {
  
    const { handleSubmit, pristine,  submitting } = this.props;
    //const showPass = this.state.showP ? "text" : "password"
    const { userTypeW} =
      this.props.state.form &&
      this.props.state.form.ReqForOTP !== undefined &&
      this.props.state.form.ReqForOTP.values !== undefined &&
      this.props.state.form.ReqForOTP.values;

      const renderCompanyIDField = ()=>{
        if(userTypeW==='Dealer' || userTypeW==='Builder'){
          return (
              <>
              <label>Company ID</label>{' '}<span style={{color:'red'}}><b>*</b></span>
              <Field
              name="cid"
              type="text"
              component={TextInput}
              placeholder="Company ID"
              />

              </>
              )
          }
        }

    return (
    
      <div style={{marginTop:'75px',padding:'10px'}}>
      <div className="card-form " style={{maxWidth:'500px',margin:'auto'}}>
            {/*  */}  
        <br/>
<div style={{display:'flex',alignItems:'center',justifyContent: 'center',marginBottom:'5px'}}><Image size='mini' src='/logo.png' style={{ marginRight: '.5em' }} />
<div  style={{fontSize:'1.2rem',fontWeight:'bold',marginRight:'5px'}}>
  <span style={{color:'dodgerblue'}}>FIND</span>
  
  <span style={{color:'blue'}}>PROP</span><span>.IN</span></div>
</div>


{/*  */}
      <div className='masthead  font-18B textAlign-center pxy-10 color-white'>  
      Submit Your Details and An OTP will be sent to Your Registered Email.
</div>
{/*  */}
            <div className="card-form ">
            <div className=" label" >
          
            <Form style={{ padding: 25 }} onSubmit={handleSubmit(this.onSubmit)}>
            <label>You Are</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              //search={true}
              name="userTypeW"
              type="text"
              component={SelectInput}
              options={userTypeWOption()}
              placeholder="You Are"
            />
            {renderCompanyIDField()}
            <label >User Id</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="userId"
              type="text"
              component={TextInput}
              placeholder="User Id"
            />
              
              {/* <label >Password</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="password"
              type={showPass}
              //type="password"
              component={TextInput}
              placeholder="Password"
            />
             */}
              {/* <Checkbox
              //name="showP"
              //type="checkbox"
              label='Show Password'
              onChange={this.toggle}
              checked={this.state.showP}
              /> */}
              <br/><br/>
              <div className='disflex-paginator' >
              <Button 
              color="teal" 
              type="submit"
              disabled={pristine || submitting}
               >
                Reset Password
              </Button>
              <Button
            color="blue"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
              </div>
              
            </Form>
          </div>

          

      </div>
      <div className='marginBottom'>

      </div>
      </div>

      </div>
      

    );
  }
}



const mapStateToProps = state => ({
  state,
});

export default connect(
  mapStateToProps,
  { formPassReset, closeModal }
)(
  reduxForm({
    form: "ReqForOTP",   // a unique identifier for this form
    //destroyOnUnmount: false,
     validate
  })(withRouter(ReqForOTP))
  
  );
