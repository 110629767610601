import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import {updateDealerRanking} from "../../actions/dealerSuggestedAction";
import { getUserForAuth } from "../../actions/authAction";


class CreateDealerSuggested extends Component {

  // componentDidMount() {
  //   this.props.fetchCompanys();
  //   const { id } = this.props.match.params;
	// 		this.props.fetchPropCom(id);
  // }

  handleCreateAd = async (values) => {
    

    if((Date.parse(values.creditExpiredOn) > Date.now()) && (values.balanceCreditProp > values.creditRequireForAd)){
      values ={...values,creditUsed : values.creditRequireForAd }
      try {
      
        const res = await axios.post(`/api/DealerSuggestedCreate`, values);
        if (res.data.msg ==='success'){
          this.props.updateDealerRanking();
          this.props.handleRefresh()
          this.props.handleCreateModalClose()
        toastr.success('Ad Created Successfully');
        }
        
      } catch (error) {
       
        toastr.error("Oops", "Something went wrong.Please Try Again CreateAd");
      }
    }else if (Date.parse(values.creditExpiredOn) < Date.now()){
      toastr.error("Your Credit Have Expired");
  
    }else{
      toastr.error("You Do Not Have Enough Credit To Create Ad");
  
    }
    
  };
  handleCreateAdAndLock = async (values) => {
    if((Date.parse(values.creditExpiredOn) > Date.now()) && (values.balanceCreditProp > values.creditRequireToLock)){
      values ={...values,creditUsed : values.creditRequireToLock,adlocked:'Yes' }
      try {
      
        const res = await axios.post(`/api/DealerSuggestedCreate`, values);
        if (res.data.msg ==='success'){
          this.props.updateDealerRanking();
          this.props.handleRefresh()
          this.props.handleCreateModalClose()
        toastr.success('Ad Created Successfully');
        }
        
      } catch (error) {
      
        toastr.error("Oops", "Something went wrong.Please Try Again CreateAdAndLock");
      }
    }else if (Date.parse(values.creditExpiredOn) < Date.now()){
      toastr.error("Your Credit Have Expired");
  
    }else{
      toastr.error("You Do Not Have Enough Credit To Create Ad");
  
    }
    
  };

  render() {
    
const {
  advertisedBy,
  balanceCreditProp,
  creditRequireForAd,
  creditRemainToLock,
  creditRequireToLock,
} = this.props.valueDS

const renderCreateAdAndLockButton = ()=>{
if(creditRequireToLock === creditRemainToLock){
  return(
      <span
      className = 'btnNumber ml-002 bgColor-indigo'
      onClick={()=> this.handleCreateAdAndLock (this.props.valueDS)}
      >
      Create Ad & Lock
      </span>
  )
}
}
//*********** */
const renderFieldAndButton = ()=>{
  if(advertisedBy !== 'Financer'){
    return(
        <>
        <div >
          { (
          <div className='row'>
            <div className='side flex-value-B'>
            <b>Total Credit Available</b>
            </div>
            <div className='main flex-value-A'>
            <b>{balanceCreditProp}{' '}</b>{' '}
            </div>
  
          </div>
          )}
          </div>
  
          <div >
          {creditRequireForAd && (
          <div className='row'>
            <div className='side flex-value-B'>
            <b>Credit Required To Create Ad</b>
            </div>
            <div className='main flex-value-A'>
            <b>{creditRequireForAd}{' '}</b>{' '}
            </div>
  
          </div>
          )}
          </div>
          <div >
          {creditRequireToLock && (
          <div className='row'>
            <div className='side flex-value-B'>
            <b>Credit Required To Create Ad and Lock</b>
            </div>
            <div className='main flex-value-A'>
            <b>{creditRequireToLock}{' '}</b>{' '}
            </div>
  
          </div>
          )}
          </div>
          {/* <div >
          {creditRequireToLock && ( */}
          <div className='row'>
            <div className='side flex-value-B'>
            <b>Ad Will be Created For Days</b>
            </div>
            <div className='main flex-value-A'>
            <b>30 Days{' '}</b>{' '}
            </div>
  
          </div>
          {/* )}
          </div> */}
          <br/>
          <div className = 'disflex-paginator'>
          <span
            className = 'btnNumber ml-002'
            onClick={()=> this.handleCreateAd (this.props.valueDS)}
          >
            Create Ad
          </span>
          {renderCreateAdAndLockButton()}
          <span
            className = 'btnNumber ml-002 bgColor-dodgerblue'
            onClick={this.props.handleCreateModalClose}
          >
            cancel
          </span>
        </div>
        </>
    )
  }
  if(advertisedBy === 'Financer'){
    return(
        <>
        <div >
          { (
          <div className='row'>
            <div className='side flex-value-B'>
            <b>Total Credit Available</b>
            </div>
            <div className='main flex-value-A'>
            <b>{balanceCreditProp}{' '}</b>{' '}
            </div>
  
          </div>
          )}
          </div>
  
          <div >
          {creditRequireForAd && (
          <div className='row'>
            <div className='side flex-value-B'>
            <b>Credit Required To Create Ad</b>
            </div>
            <div className='main flex-value-A'>
            <b>{creditRequireForAd}{' '}</b>{' '}
            </div>
  
          </div>
          )}
          </div>
          
          {/* <div >
          {creditRequireToLock && ( */}
          <div className='row'>
            <div className='side flex-value-B'>
            <b>Ad Will be Created For Days</b>
            </div>
            <div className='main flex-value-A'>
            <b>30 Days{' '}</b>{' '}
            </div>
  
          </div>
          {/* )}
          </div> */}
          <br/>
          <div className = 'disflex-paginator'>
          <span
            className = 'btnNumber ml-002'
            onClick={()=> this.handleCreateAd (this.props.valueDS)}
          >
            Create Ad
          </span>
          
          <span
            className = 'btnNumber ml-002 bgColor-dodgerblue'
            onClick={this.props.handleCreateModalClose}
          >
            cancel
          </span>
          </div>
        </>
    )
  }
  
  }
    //*************** */
    return (
      <div className = 'maindiv-768' >
        {/*  */}
<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > Create Ad</span>
	</div>

	</div>

	<div className='card-attached border-radius-bottom border-sb pxy-10 mb-010'  >

    {/*  */}

    {renderFieldAndButton()}
    {/*  */}
    </div>
    </div>

    );
  }
}

export default connect(
  null,
  {updateDealerRanking, getUserForAuth}
)(CreateDealerSuggested)
