import React, { Component } from "react";
import { connect } from "react-redux";
import axios from 'axios';
import format from "date-fns/format";
import { withRouter } from "react-router-dom";
import { openModal } from "../../actions/modalAction";
import {fetchProjectCForProp} from "../../actions/projectCAction";
import AllPropComSaleList from "../propertyCom/AllPropComSaleList";
import AllPropComRentList from "../propertyCom/AllPropComRentList";
import DealerSuggestedListForProject from "../dealerSuggesteds/DealerSuggestedListForProject";

import Loading from "../common/Loading";
import {
  Button,
} from "semantic-ui-react";




class ProjectCDetail extends Component {
  state = {
    propSale:true,
    propRent:false,
    propSaleCount:0 ,
    propRentCount:0 ,
  };

  componentDidMount = async ()  =>{
    const  id  = this.props.id;
    this.props.fetchProjectCForProp(id);
   
      const res = await axios.post(`/api/getCountPropCom/${id}`);

      this.setState({
        propSaleCount:res.data.propSaleCount ,
         propRentCount:res.data.propRentCount ,
      });

  }
//@@@@@@@

componentDidUpdate = async (prevProps) => {
  //Typical usage, don't forget to compare the props
  if (this.props.id !== prevProps.id) {
    const  id  = this.props.id;
    this.props.fetchProjectCForProp(id);
    
    const res = await axios.post(`/api/getCountPropCom/${id}`);
     
    this.setState({
       propSaleCount:res.data.propSaleCount ,
       propRentCount:res.data.propRentCount ,
    });
  }
   }

//@@@@@@@@@
        //**************** */
        handleOpenModalImage = (id,imageOf) => {   
          this.props.openModal("ShowImagesModal" , {id:id,imageOf:imageOf});
        };
        //^^^^^^^^^^^^^^^^^^
        showPropSale =()=> this.setState({ propSale: true,propRent: false, })
        showPropRent =()=> this.setState({ propSale: false,propRent: true, })
    
          //*************************** */
  
  render() {
     
    if (!this.props.projectC) {
      return (<Loading/>)
    }
    const {
      projectC: {
        reraNo,
        projectType,
        propertyTypeProj,
        category,
        projectName,
        addLine1,
        addLine2,
        locality,
        // subCity,
        // city,
        // state,
        // pincode,
        locationOnMap,
        configuration,
        towers,
        units,
        status,
        possession,
        ageOfConstruction,
        totalFloors,
        liftsInTheTower,
        amenitiesBasic,
        
        _id
      }
    } = this.props;
   

    const {uname,
      uphone1,
      uphone2,
      uemail,
      uwhatsapp,

        }=this.props.userA !== null && this.props.userA
      const  requesterId= this.props.userA !== null && this.props.userA._id
    const contactReq= {
      requestFor:'Project',
      uname,
    uphone1,
    uphone2,
    uemail,
    uwhatsapp,
    requesterId,
    propOrProjId:_id,
    projectType,
      projectName,locality,
      }

    
        
    const renderpropertyType = () => {
      return propertyTypeProj.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} |</b>
          </span>
        );
      });
    };
    const renderconfiguration = () => {
      return configuration.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} |</b>
          </span>
        );
      });
    };

    const renderamenitiesBasic = () => {
      return amenitiesBasic.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} |</b>
          </span>
        );
      });
    };



  
      //******************
      const  renderPropSale = () => {
      if (this.state.propSale===true) {

        return <AllPropComSaleList projectId={_id} />
      }
    };
  const  renderPropRent = () => {
      if (this.state.propRent===true) {

        return   <AllPropComRentList projectId={_id} />
      }
    };

    //******************
    const poss = (Date.parse(possession) < Date.now())? "Immediate":<span>{ possession &&  format(new Date(possession), "dd-MM-yyyy")}</span>
//*******************************************************
return (

      <div>


{/*  */}
<div className='card-attached  border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >

<div className='heading-2'>
<span  > {projectName}</span>	
</div>

</div>

    <div className='card-attached border-sb pxy-20'  >

            {/*  */}
	<a target='_blank'  rel="noreferrer noopener" href={`${locationOnMap}`} >
	<span
         className="icon-button"
        >
        <img className= 'yimg ' src='/mapIcon.png' alt="Location on Map" style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>
		</a>
		{/*  */}
		<span
 onClick={()=>this.handleOpenModalImage(_id,'Project')}
 className="icon-button"
 >
 <img className= 'yimg ' src='/imageAddIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
 {/*  */}

          
        <br />
        <br />
        <div className='border-bottom heading' >
    <span  > Project Detail </span>
    </div>
{/*  */}
<div >
{reraNo && (
<div className='row'>
<div className='side'>
<b>RERA No.</b>
</div>
<div className='main'>
<b>{reraNo}</b>
</div>

</div>
)}
</div>
        <div >
         {projectName && (
           <div className='row'>
             <div className='side'>
               <b>Project Name</b>
             </div>
             <div className='main'>
               <b>{projectName}</b>
             </div>

           </div>
         )}
       </div>
       <div >
       {addLine1 && (
         <div className='row'>
           <div className='side'>
             <b>Address</b>
           </div>
           <div className='main'>
             <b>{addLine1}{' '} {addLine2}</b>
           </div>

         </div>
       )}
     </div>
     <div >
     {locality && (
       <div className='row'>
         <div className='side'>
           <b>Locality</b>
         </div>
         <div className='main'>
           <b>{locality}</b>
         </div>

       </div>
     )}
   </div>
   <div >
   {projectType && (
     <div className='row'>
       <div className='side'>
         <b>Project Type</b>
       </div>
       <div className='main'>
         <b>{projectType}</b>
       </div>

     </div>
   )}
 </div>
 <div >
 {category && (
   <div className='row'>
     <div className='side'>
       <b>Category</b>
     </div>
     <div className='main'>
       <b>{category}</b>
     </div>

   </div>
 )}
</div>
   <div >
   {configuration &&
     configuration.length > 0 && (
     <div className='row'>
       <div className='side'>
         <b>Configuration</b>
       </div>
       <div className='main'>
         {renderconfiguration()}
       </div>

     </div>
   )}
   </div>
   <div >
   {propertyTypeProj &&
     propertyTypeProj.length > 0 && (
     <div className='row'>
       <div className='side'>
         <b>Property Type</b>
       </div>
       <div className='main'>
         {renderpropertyType()}
       </div>

     </div>
   )}
   </div>
   <div >
   {status && (
     <div className='row'>
       <div className='side'>
         <b>Status</b>
       </div>
       <div className='main'>
         <b>{status}</b>
       </div>

     </div>
   )}
 </div>
 <div >
 {possession && (
   <div className='row'>
     <div className='side'>
       <b>Possession</b>
     </div>
     <div className='main'>
       <b>{poss}</b>
     </div>

   </div>
 )}
</div>
<div >
{ageOfConstruction && (
 <div className='row'>
   <div className='side'>
     <b>Age Of Construction</b>
   </div>
   <div className='main'>
     <b>{ageOfConstruction}</b>
   </div>

 </div>
)}
</div>
<div >
{liftsInTheTower && (
 <div className='row'>
   <div className='side'>
     <b>Lifts In The Tower</b>
   </div>
   <div className='main'>
     <b>{liftsInTheTower}</b>
   </div>

 </div>
)}
</div>
<div >
{towers && (
 <div className='row'>
   <div className='side'>
     <b>Total Towers</b>
   </div>
   <div className='main'>
     <b>{towers}</b>
   </div>

 </div>
)}
</div>
<div >
{units && (
 <div className='row'>
   <div className='side'>
     <b>Total Units</b>
   </div>
   <div className='main'>
     <b>{units}</b>
   </div>

 </div>
)}
</div>
<div >
{totalFloors && (
 <div className='row'>
   <div className='side'>
     <b>Total Floors</b>
   </div>
   <div className='main'>
     <b>{totalFloors}</b>
   </div>

 </div>
)}
</div>

<div >
{amenitiesBasic &&
 amenitiesBasic.length > 0 && (
 <div className='row'>
   <div className='side'>
     <b>Amenities</b>
   </div>
   <div className='main'>
     {renderamenitiesBasic()}
   </div>

 </div>
)}
</div>

      </div>
      <DealerSuggestedListForProject  contactReq={contactReq}  />
{/*  */}
<div className='card-attached  border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >

<div className='heading-2' >
<span  > All Properties for Sale and Rent in {projectName}</span>
</div>
<Button.Group widths="2"  >

<Button
style={{marginRight:'1px'}}
// basic
//inverted
floated="right"
color="blue"
type="button"
onClick={this.showPropSale}
>
{this.state.propSaleCount} Properties for Resale
</Button>
<Button
style={{marginLeft:'1px'}}
// basic
//inverted
floated="right"
color="blue"
type="button"
onClick={this.showPropRent}
>
{this.state.propRentCount} Properties for Rent
</Button>

</Button.Group>
</div>
{/*  */}

      { renderPropSale()}
      { renderPropRent()}
      
      </div>


      //**********************************



    );

//*************************************************************




  }
}
function mapStateToProps(state) {
  
  return {
    userA: state.auth.userA,
    projectC: state.projectCs.projectC,
  };
}

export default connect(
  mapStateToProps,
  {fetchProjectCForProp,openModal}
)(withRouter(ProjectCDetail));
