import React from 'react';
import {  withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import writtenNumber from 'written-number';
import {capitalizeFirst} from "../functions/Functions";
import {resetOffsetPropResis} from '../../actions/propresiAction';
import { closeModal } from "../../actions/modalAction";
import {  Form, Button,   Popup } from 'semantic-ui-react';
import TextInput from '../form/TextInput';
import SelectInput from '../form/SelectInput';
//import DateInput from '../form/DateInput';

import {
  propertyType,
  bedroom,
  
  locality,
  
} from './Data';


const PropResiSaleSearch = props => {
  const { maxPrice, minPrice } =
    props.state.form &&
    props.state.form.PropResiSaleSearch !== undefined &&
    props.state.form.PropResiSaleSearch.values !== undefined &&
    props.state.form.PropResiSaleSearch.values;
  const { handleSubmit,  reset, submitting } = props;

  const projectName = () => {
    return props.state.projectRs.projectRsForData.map(value => ({
      key: value.projectName,
      text: `${value.projectName}` ,
      value: value.projectName,style:{fontSize:'18px'}
    }));
  };

  
  const onSubmit = () => {
    props.resetOffsetPropResis();
    props.closeModal()
    props.history.push(`/propresi/PropResiSaleSearchList`);

  };

  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Residential Property Available for Sale
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >
            <Button
            color="teal"
            type="button"
            onClick={()=>props.closeModal()}

            >
            Cancel
            </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
            style={{color:'black'}}
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <br/><br/>
            <label htmlFor="">Property Type</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search All Available Property Type or
        Enter Single or Multiple Values to Search Those Property Type
            </Popup>
            <Field
              name="propertyTypeA"
              placeholder="Property Type"
              type="text"
              options={propertyType()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Bedroom</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search Available Properties for All Bedrooms or
        Enter Single or Multiple Values to Search Properties for Those Bedrooms
            </Popup>
            <Field
              name="bedroomA"
              placeholder="Bedroom"
              type="text"
              options={bedroom()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label htmlFor="">
              Minimum Price{' '}
              <span>
              ({minPrice && capitalizeFirst(writtenNumber(minPrice, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                
              </span>
            </label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Enter Lower Value of your Budget Range
            </Popup>
            <Field
              name="minPrice"
              placeholder="Minimum Price"
              type="number"
              component={TextInput}
            />
            <label htmlFor="">
              Maximum Price{' '}
              <span>
              ({maxPrice && capitalizeFirst(writtenNumber(maxPrice, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                
              </span>
            </label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Enter Higher Value of your Budget Range
            </Popup>
            <Field
              name="maxPrice"
              placeholder="Maximum Price"
              type="number"
              component={TextInput}
            />



            <label htmlFor="">Society/Complex Name</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search Properties in All Society/Complex of Dwarka or
        Enter Single or Multiple Values to Search Properties in Those Society/Complex
            </Popup>
            <Field
              name="projectNameA"
              placeholder="Enter Key Word To Search"
              type="text"
              options={projectName()}
              search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Locality</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search Properties in All Sectors of Dwarka or
        Enter Single or Multiple Values to Search Properties in Those Sectors
            </Popup>
            <Field
              name="localityA"
              placeholder="Enter Key Word To Search"
              type="text"
              options={locality()}
              //search={true}
              multiple={true}
              component={SelectInput}
            /> 
            {/* <Popup

        content='Leave it Blank to Search Properties in All Sectors of Dwarka or
        Enter Single or Multiple Values to Search Properties in Those Sectors'
        on='focus'

           trigger= { }

/> */}
            <br />

            <Button
            className='pxy-10-10'
            style={{color:'black'}}
              color="blue"
              type="submit"
              disabled={submitting}
              // as={Link}
              // to={`/propresi/PropResiSaleSearchList`}
            >
              Search
            </Button>
            <Button
            className='pxy-10-10'
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"
              className='pxy-10-10'
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>

          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA,
  };
};
export default connect(
  mapStateToProps,
   {resetOffsetPropResis,closeModal}
)(
  reduxForm({
    form: 'PropResiSaleSearch', // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(withRouter(PropResiSaleSearch)) )
