import React, { Component } from "react";
import { Link,  } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";
import Loading from "../common/Loading"; 

import { getUserForAuth } from "../../actions/authAction";
import {fetchFinancerPropComsAd} from "../../actions/propcomAction";
import {fetchFinancerPropResisAd} from "../../actions/propresiAction";
import { openModal } from "../../actions/modalAction";
import {
  Icon,
  Button,
  Modal,
} from "semantic-ui-react";
import FinancerAd from './FinancerAd'


class AdByFinancerList extends Component {
  state = {
    pageO:'',
      id:'',
      creditRequireForFinancerAd:0,
      balanceCreditProp:0,
      modalOpen: false,
      open: false,
      result: "show the modal to capture a result"
    };
  componentDidMount() {
    
  if (this.props.auth.isAuthenticated) {
    this.props.fetchFinancerPropComsAd()
    this.props.fetchFinancerPropResisAd()
  }else{
    this.props.openModal('LoginModal')
  }
    
  }
   
    
    //**** */
    handleRefresh = () =>{
      this.props.getUserForAuth()
      this.props.fetchFinancerPropComsAd()
      this.props.fetchFinancerPropResisAd()   
    }
    //**** */
    handleModalOpen = (value) => this.setState({ modalOpen: true,valueFinAd:value})

    handleModalClose = () => this.setState({ modalOpen: false })  //contact modal

    
    //^^^^^^^^^^^^^^^^^^^^^^
    renderList=(item)=> {
    const {
    startedOn,
    expiredOn,
    creditRequireForFinancerAd,
    creditRemainToLock,
    //****************** */
    adFor,
    projectType,
    propertyFor,
    propertyType,
    bedroom,
    additionalRooms,
    expectedPrice,
    expectedRent,
    carpetArea,
    area,
    areaUnit,
    projectName,
    //projectId,
    locality,
    //	createdAt,

    _id
    } = item;

    const {balanceCreditProp}=this.props.userA !== null && this.props.userA
    //************************** render active/inactive/expired
const valueFinAd = {
  creditRemainToLock,
  projectType,
  balanceCreditProp,
  creditRequireForFinancerAd,
  expiredOn,
  _id
}

    const renderStatus=() => {


    if (Date.now()<Date.parse(startedOn)){
    return (<span >
    Inactive
    </span>)
    }
    if (Date.now()>Date.parse(startedOn) && Date.now()<Date.parse(expiredOn) ){
    return (<span >
    Active
    </span>)
    }
    if (Date.now()>Date.parse(expiredOn)){
    return (<span className='color-red'>
    Expired
    </span>)
    }
    return (<span >
    No Status
    </span>)
    }


    //**************************
    const roomOrBHK = (projectType==='Residential') ? 'BHK' :'Room'
    const renderBookOrRenewButton = () =>{
      if(Date.now() < Date.parse(expiredOn)){
     return (
        <span
        className = 'btnNumber ml-002 bgColor-indigo'
        onClick={() => this.handleModalOpen({...valueFinAd,action:'Renew'})}
        >
        Extend Validity
        </span>
      
      );
      }
      if(  Date.parse(expiredOn) < Date.now()){
        return (
           <span
           className = 'btnNumber ml-002 bgColor-dodgerblue'
           onClick={() => this.handleModalOpen({...valueFinAd,action:'Book'})}
           >
           Book Financer Ad
           </span>
         
         );
         }
    }
    


    //**************************
    const renderDetailButton=() => {
    if( projectType==='Residential' ){
    return (<Button
    inverted
    //floated="right"
    color="blue"
    type="button"
    as={Link}
    to={`/propresi/PropResiDetail/${_id}`}
    >
    Detail
    </Button>)
    }
    if( projectType==='Commercial' ){
    return (<Button
    inverted
    //floated="right"
    color="blue"
    type="button"
    as={Link}
    to={`/propcom/PropComDetail/${_id}`}
    >
    Detail
    </Button>)
    }
    
    
    }

    //**************************
    const renderadditionalRooms = () => {
    return additionalRooms.map(item => {
    return (
    <span key={item}>
    {" "}
    {item}|
    </span>
    );
    });
    };
    const price = (value) => {
    var val = Math.abs(value)
    if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lac';
    }
    /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
    return val;
    }

    //const daysAgo = Math.round((Date.now()-Date.parse(createdAt))/(24*60*60*1000));

    return (
    <div key={_id}>
    <div className='card' style={{padding:'10px',marginTop:'0px',border:'solid 2px dodgerblue',}} >
    {/*requester detqils start*/}
    {/*  */}
	<div className='border-bottom'  style= {{marginTop:'5px',marginBottom:'5px',}}>
	<div  className="heading" style= {{marginBottom:'10px'}} >
	<span >
	{projectType}{' '}{adFor}{' '}{propertyFor} 
	</span>
	</div>
	</div>
	{/*  */}
  
    <div style={{fontSize:'1rem'}}>
    <div >
    {startedOn && (
    <div className='row'>
    <div className='side'>
    <b>Duration</b>
    </div>
    <div className='main'>
    <b>{ format(new Date(startedOn), "dd-MM-yyyy")}{' '}To{' '}{ format(new Date(expiredOn), "dd-MM-yyyy")}{' '}
    <span style={{color:'dodgerblue',fontSize:'18px'}}>{ renderStatus()}</span>{' '}{renderBookOrRenewButton()}
    </b>
    </div>

    </div>
    )}
    </div>

    <div >
    {creditRequireForFinancerAd >0 && (
    <div className='row'>
    <div className='side'>
    <b>Credit Used</b>
    </div>
    <div className='main'>
    <b>{creditRequireForFinancerAd}{' '}</b>
    </div>

    </div>
    )}
    </div>




    <div >
    {projectName && (
    <div className='row'>
    <div className='side'>
    <b>Location</b>
    </div>
    <div className='main'>
    <b>

    <div>
    {bedroom && (

    <span >
    {bedroom} {' '}{roomOrBHK}
    </span>)

    }{' '}
    {additionalRooms && additionalRooms.length >0 && (

    <span >
    {renderadditionalRooms()}
    </span>)

    }{' '}
    {carpetArea >0 && (

    <span >
    {carpetArea} sq ft
    </span>)

    }{' '}
    {area && (

    <span >
    {area}
    </span>)

    }{' '}
    {areaUnit && (

    <span >
    {areaUnit}
    </span>)

    }{' '}
    {propertyType && (

    <span >
    {propertyType}
    </span>)

    }{' '}
    {propertyFor && (

    <span >
    For  {propertyFor}
    </span>)

    }{' '}
    {expectedPrice>0 && (

    <span >
    For  <Icon className="rupee" ></Icon>
    {price(expectedPrice)}{' '}In
    </span>)

    }{' '}
    {expectedRent>0 && (

    <span >
    For  <Icon className="rupee" ></Icon>
    {expectedRent}{' '}In
    </span>)

    }{' '}
    {projectName && (

    <span >
    {' '}{projectName}
    </span>)

    }{' '}
    {locality && (

    <span >
    {locality} Dwarka, New Delhi
    </span>)

    }{' '}
    {renderDetailButton()}
    </div>

    </b>
    </div>

    </div>
    )}
    </div>

    </div>


    </div>
    </div>


    );
    }

  

  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
  }

//^^^^^^^^^^^^^^^^^^^^^^^^^
onChange=(e) => {
  this.setState({ [e.target.name]: e.target.value });
}
//^^^^^^^^^^^^^^^^^^^^
  render() {

    return (

  <div className='maindiv-820'>
  <div >
  

  <div>
    {/**/}
<div className='cashbook-H box-shadow'>

{/* <div
style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
onClick={this.handleSearchAdByDealer}>
      <span className='icon-button2 border1' > <Icon name='search' /></span >
    </div> */}



<div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
Financer Advertisements
</div>
</div>


</div>
{/**/}
  
  <Modal
        // trigger={<Button onClick={this.handleOpen}>Show Modal</Button>}
        open={this.state.modalOpen}
        onClose={this.handleModalClose}
        basic
         size='small'
      >

        <Modal.Content>
        <FinancerAd 
  handleModalClose = {this.handleModalClose}
  handleRefresh ={this.handleRefresh}
  valueFinAd={this.state.valueFinAd}

  />

        </Modal.Content>

      </Modal>
      {/*  */}
  {this.renderLoading()}
  {[...this.props.propresisFIN, ...this.props.propcomsFIN].map(this.renderList)}

  
  <br/><br/>
  
  </div>
  


  </div>
  </div>

    );
  }
}


const mapStateToProps = ({ propcoms,propresis, auth}) => {
  
  return {
    auth,
    userA: auth.userA,
    propcomsFIN: propcoms.propcomsFIN,
    propresisFIN: propresis.propresisFIN,
    

  };
};

export default connect(
  mapStateToProps,
  {fetchFinancerPropComsAd ,fetchFinancerPropResisAd,openModal,getUserForAuth}
)(AdByFinancerList);
