

//###############
const adCategoryO = [
  '',
  'Residential',
  'Commercial'
];
export const adCategory = () => {
  return adCategoryO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//###############
const adForOptionO = [
'',
  'Property SALE',
  'Property RENT',
  'Project',
  'Dealer'
];
export const adForOption = () => {
  return adForOptionO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};

//@@@@@@@@
const adForPropertySearchO = [
  '',
    'Property SALE',
    'Property RENT'
  ];
  export const adForPropertySearch = () => {
    return adForPropertySearchO.map(value => ({
      key: value,
      text: value,
      value: value,style:{fontSize:'18px'}
    }));
  };

  
const adLocationPropertyO = ['','STATIC ALL','BUILDER FLOOR STATIC ALL','SIMILAR PROPERTY','BUILDER FLOOR SIMILAR PROPERTY'];
export const adLocationProperty = () => {
  return adLocationPropertyO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};


const adLocationDealerO = ['','RESIDENTIAL SALE BUILDER FLOOR LIST','RESIDENTIAL SALE LIST','RESIDENTIAL RENT LIST','COMMERCIAL SALE LIST','COMMERCIAL RENT LIST','HOME PAGE'];
export const adLocationDealer = () => {
  return adLocationDealerO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//#######
const adLocationSearchO = [
  '','STATIC ALL','SIMILAR PROPERTY',
  'RESIDENTIAL SALE BUILDER FLOOR LIST',
  'RESIDENTIAL SALE LIST','RESIDENTIAL RENT LIST',
  'COMMERCIAL SALE LIST','COMMERCIAL RENT LIST','HOME PAGE'
];
export const adLocationSearch = () => {
  return adLocationSearchO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//#######
// const adLocationForSearchPropO = ['STATIC ALL','SIMILAR PROPERTY AT RESI SALE','SIMILAR PROPERTY AT RESI RENT','SIMILAR PROPERTY AT COM SALE','SIMILAR PROPERTY AT COM RENT'];
// export const adLocationForSearchProp = () => {
//   return adLocationForSearchPropO.map(value => ({
//     key: value,
//     text: value,
//     value: value
//   }));
// };
// //#######
// const adLocationForSearchDealerO = ['STATIC ALL','DEALER AT RESI SALE BF LIST','DEALER AT RESI SALE LIST','DEALER AT RESI RENT LIST','DEALER AT COM SALE LIST','DEALER AT COM RENT LIST','DEALER AT HOME PAGE'];
// export const adLocationForSearchDealer = () => {
//   return adLocationForSearchDealerO.map(value => ({
//     key: value,
//     text: value,
//     value: value
//   }));
// };
//#######
const adSubLocationO = [
  '',
'Top1'	,
'Top2'	,
 'Bottom1',
 'Bottom2'
];
export const adSubLocation = () => {
  return adSubLocationO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};


//###############
export const localityO = [
  'Sector-01',
  'Sector-02',
  'Sector-03',
  'Sector-04',
  'Sector-05',
  'Sector-06',
  'Sector-07',
  'Sector-08',
  'Sector-09',
  'Sector-10',
  'Sector-11',
  'Sector-12',
  'Sector-13',
  'Sector-14',
  'Sector-15',
  'Sector-16',
  'Sector-17',
  'Sector-18A',
  'Sector-18B',
  'Sector-19A',
  'Sector-19B',
  'Sector-20',
  'Sector-21',
  'Sector-22',
  'Sector-23',
  'Sector-24',
  'Sector-25',
  'Sector-26',
  'Sector-27',
  'Sector-28',
  'Sector-29',
  'Sector-30'
];
export const locality = () => {
  return localityO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};


