import {
  FETCH_PROPCOMS,
  FETCH_PROPCOM,
  SEARCH_PROPCOMS,
  FETCHING_PROPCOMS,
} from '../actions/types';

const INITIAL_STATE = { 
  city:'', 
  fetching:true,
  fetchingCS:true,
  fetchingCSBF:true,
  fetchingCR:true,
  propcomsMy: [],
  propcomsFIN: [], //list of single financer's property
  propcomsCS: [],
  propcomsCSF: [],
  propcomsCR: [],
  propcom: null,

  offsetCS: 0,
  offsetCR: 0,
  offsetCSF: 0,
  
  offsetCRSH: 0,
  offsetCSSH: 0,   //for shortlist

  limitCS: 10,
  limitCR: 10,
  limitCSF: 5,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case FETCH_PROPCOMS:
      return { ...state, ...action.payload };
    case FETCH_PROPCOM:
      return { ...state, propcom: action.payload };
    
    case SEARCH_PROPCOMS:
      
      return { ...state, ...action.payload };
    
    case FETCHING_PROPCOMS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
