import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";


import {fetchAdByDealer} from "../../actions/dealerSuggestedAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button, } from "semantic-ui-react";

import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";

import {
  propertyFor,
  adFor,
  projectType,
  locality,

} from './DataS';



const SearchAdByDealer = props => {
  
  const { handleSubmit,  reset, submitting } = props;

  const onSubmit = values => {
    const offset = 0
const { limit} = props.state.dealerSuggesteds;
    props.fetchAdByDealer(values, limit,offset);
  };
  return (
    
          <div style={{maxWidth:'700px',margin:'auto'}}>

    {/**/}
    <div className='margin-auto-H' >
          <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
            {/*  */}
            Search Advertisements
        {/*  */}
        </div>
        </div>
        {/**/}
      
        <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >
            <Button
            color="teal"
            type="button"
            onClick={()=>props.closeModal()}

            >
            Cancel
            </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
            style={{color:'black'}}
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <br/><br/>

          <label htmlFor=""> Ad For </label>
            <Field
             // search={true}
              name="adFor"
              type="text"
              component={SelectInput}
             // multiple={true}
              options={adFor()}
              placeholder="Ad For  "
            /> 
            <label htmlFor=""> Project/Property Type </label>
            <Field
            //  search={true}
              name="projectType"
              type="text"
              component={SelectInput}
             // multiple={true}
              options={projectType()}
              placeholder="Project/Property Type"
            /> 
            <label htmlFor=""> Property For </label>
            <Field
             // search={true}
              name="propertyFor"
              type="text"
              component={SelectInput}
             // multiple={true}
              options={propertyFor()}
              placeholder="Property For"
            /> 
            <label htmlFor=""> Locality </label>
            <Field
             // search={true}
              name="locality"
              type="text"
              component={SelectInput}
             // multiple={true}
              options={locality()}
              placeholder="Locality"
            /> 
            {/*  */}
            <label htmlFor="">Started On</label>
            <Field
              name="startedOn"
              type="text"
              component={DateInput}
              placeholder= "In DD-MM-YYYY format Lower Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label htmlFor="">Expired On</label>
            <Field
              name="expiredOn"
              type="text"
              component={DateInput}
              placeholder=" In DD-MM-YYYY format Higher Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />




            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state
  };
};
export default connect(
  mapStateToProps,
   {fetchAdByDealer,closeModal}
)(
  reduxForm({
    form: "SearchAdByDealer", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(SearchAdByDealer)
);
