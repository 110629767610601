import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import writtenNumber from "written-number";
import {capitalizeFirst} from "../functions/Functions";
import {searchReqComsUnlocked} from "../../actions/reqcomAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button,} from "semantic-ui-react";
import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";


import {
  requiredFor,
  propertyType,
  furnishedStatus,
  entry,
  floorNo,
  locality,
  booleanOption,
  sorting
} from "./Data";


  class ReqComSearch extends Component {
    projectName = () => {
      return this.props.state.projectCs.projectCsForData.map(value => ({
        key: value.projectName,
        text: `${value.projectName}` ,
        value: value.projectName,style:{fontSize:'18px'}
      }));
    };
   onSubmit = values => {
    const offsetULock = 0
    const { limit} = this.props.state.reqcoms;
    this.props.searchReqComsUnlocked(values, limit,offsetULock);
  };
  render(){
  const { handleSubmit, pristine, reset, submitting } = this.props;
  const { budgetMinL,budgetMinH,budgetMaxL,budgetMaxH,areaCarpetMinL,areaCarpetMinH,areaCarpetMaxL,areaCarpetMaxH  } =
    this.props.state.form &&
    this.props.state.form.ReqComSearch !== undefined &&
    this.props.state.form.ReqComSearch.values !== undefined &&
    this.props.state.form.ReqComSearch.values;



  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search  Requirement for Commercial Property
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(this.onSubmit)}
          >
          <Button
              color="teal"
              type="button"
              onClick={()=>this.props.closeModal()}

            >
              Cancel
            </Button>
                        
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
            style={{color:'black'}}
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          <br /><br />
          <label >Sort By</label>
          <Field
            //search={true}
            name="sorting"
            type="text"
            component={SelectInput}
            options={sorting()}
            placeholder="Sort By"
          />
          <label >Required For</label>
          <Field
            name="requiredFor"
            placeholder="Required For"
            type="text"
            options={requiredFor()}
            //search={true}
            component={SelectInput}
          />
            <label >Property Type</label>
            <Field
              name="propertyType"
              placeholder="Property Type"
              type="text"
              options={propertyType()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Furnished Status</label>
            <Field
              name="furnishedStatus"
              placeholder="Furnished Status"
              type="text"
              options={furnishedStatus()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Common Washroom</label>
            <Field
              name="washroomCommon"
              placeholder="Common Washroom"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Personal Washroom</label>
            <Field
              name="washroomPersonal"
              placeholder="Personal Washroom"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Personal Pantry</label>
            <Field
              name="pantryPersonal"
              placeholder="Personal Pantry"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Power BackUp</label>
            <Field
              name="powerBackUp"
              placeholder="Power BackUp"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Lifts In The Tower</label>
            <Field
              name="liftsInTheTower"
              placeholder="Lifts In The Tower"
              type="text"
              options={booleanOption()}
              //search={true}
              component={SelectInput}
            />
            <label >Car Parking</label>
            <Field
              name="parking"
              placeholder=" Parking"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Reserve Car Parking</label>
            <Field
              name="reserveParking"
              placeholder="Reserve Car Parking"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Visitor Car Parking</label>
            <Field
              name="visitorParking"
              placeholder="Visitor Car Parking"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />

            <label >Entry</label>
            <Field
              name="entry"
              placeholder="Entry"
              type="text"
              options={entry()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Floor No</label>
            <Field
              name="floorNo"
              placeholder="Floor No"
              type="text"
              options={floorNo()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Complex/Project Preferred</label>
            <Field
              name="projectName"
              placeholder="Complex/Project Preferred"
              type="text"
              options={this.projectName()}
              search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Locality</label>
            <Field
              name="locality"
              placeholder="Locality"
              type="text"
              options={locality()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Minimum Carpet Area Low {" "}
                        <span>
                          ({areaCarpetMinL && capitalizeFirst(writtenNumber(areaCarpetMinL, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                          
                        </span></label>
                      <Field
                        name="areaCarpetMinL"
                        placeholder="Lower Value of Minimum Carpet Area to Search Requirement in a Range"
                        type="number"
                        component={TextInput}
                      />
                      <label >Minimum Carpet Area High {" "}
                        <span>
                          ({areaCarpetMinH && capitalizeFirst(writtenNumber(areaCarpetMinH, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                          
                        </span></label>
                      <Field
                        name="areaCarpetMinH"
                        placeholder="Higher Value of Minimum Carpet Area to Search Requirement in a Range"
                        type="number"
                        component={TextInput}
                      />
                      <label >Maximum Carpet Area Low {" "}
                        <span>
                          ({areaCarpetMaxL && capitalizeFirst(writtenNumber(areaCarpetMaxL, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                          
                        </span></label>
                      <Field
                        name="areaCarpetMaxL"
                        placeholder="Lower Value of Maximum Carpet Area to Search Requirement in a Range"
                        type="number"
                        component={TextInput}
                      />
                      <label >Maximum Carpet Area High {" "}
                        <span>
                          ({areaCarpetMaxH && capitalizeFirst(writtenNumber(areaCarpetMaxH, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                          
                        </span></label>
                      <Field
                        name="areaCarpetMaxH"
                        placeholder="Higher Value of Maximum Carpet Area to Search Requirement in a Range"
                        type="number"
                        component={TextInput}
                      />

            <label >Minimum Budget Low {" "}
            <span>
              ({budgetMinL && capitalizeFirst(writtenNumber(budgetMinL, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
              
            </span></label>
          <Field
            name="budgetMinL"
            placeholder="Lower Value of Minimum Budget to Search Requirement in a Range"
            type="number"
            component={TextInput}
          />
          <label >Minimum Budget High {" "}
            <span>
              ({budgetMinH && capitalizeFirst(writtenNumber(budgetMinH, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
              
            </span></label>
          <Field
            name="budgetMinH"
            placeholder="Higher Value of Minimum Budget to Search Requirement in a Range"
            type="number"
            component={TextInput}
          />
          <label >Maximum Budget Low {" "}
            <span>
              ({budgetMaxL && capitalizeFirst(writtenNumber(budgetMaxL, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
              
            </span></label>
          <Field
            name="budgetMaxL"
            placeholder="Lower Value of Maximum Budget to Search Requirement in a Range"
            type="number"
            component={TextInput}
          />
          <label >Maximum Budget High {" "}
            <span>
              ({budgetMaxH && capitalizeFirst(writtenNumber(budgetMaxH, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
              
            </span></label>
          <Field
            name="budgetMaxH"
            placeholder="Higher Value of Maximum Budget to Search Requirement in a Range"
            type="number"
            component={TextInput}
          />
          <label >Requirement Created FROM</label>
          <Field
            name="from"
            type="text"
            component={DateInput}
            dateFormat="dd-MM-yyyy"
            placeholder="Requirement Created FROM"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          <label >Requirement Created TO</label>
          <Field
            name="to"
            type="text"
            component={DateInput}
            dateFormat="dd-MM-yyyy"
            placeholder="Requirement Created TO"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />

            <br />

            <Button
              color="blue"
              type="submit"
              disabled={pristine || submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"
              type="button"
              onClick={()=>this.props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/><br/><br/>
              </div>
              </div>
              </div>
  );
}
}
const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA
  };
};
export default connect(
  mapStateToProps,
  {searchReqComsUnlocked,closeModal}
)(
  reduxForm({
    form: "ReqComSearch", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(ReqComSearch)
);
