import React, { Component } from "react";
import { connect } from "react-redux";
import {  withRouter } from "react-router-dom";
import {fetchBuilder} from "../../actions/builderAction";
import { openModal } from "../../actions/modalAction";
import { openPopup } from "../../actions/popupAction";
import {fetchProjectRForBuilder} from "../../actions/projectRAction";
import { shortlistAddRemoveDealer,viewCountChecker } from "../../actions/authAction";
import {createContactReq} from "../../actions/contactReqAction";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import ProjectRSearchListBuilder from "../projectR/ProjectRSearchListBuilder";
import format from "date-fns/format";
import Loading from "../common/Loading"; 

import {
  Button,
  Divider,
  Image,
} from "semantic-ui-react";


 

class OtherBuilderDetail extends Component {

  componentDidMount() {
    window.scroll(0,0)
    const { id } = this.props.match.params;
    this.props.fetchBuilder(id);
   const value1={id:id,status:['New launch','Under Construction']};
   const value2={id:id,status:['Ready To Move']};
   const value3={id:id,status:['Upcoming']};
    this.props.fetchProjectRForBuilder(value1)
    this.props.fetchProjectRForBuilder(value2)
    this.props.fetchProjectRForBuilder(value3)
  }

  handleOpenModal = (url) => {   
    this.props.openModal("ImageZoomModal" , {imageURL:url});
  };
  handleOpenPopup = (ph , col) => {
    const message= `${ph} Mobile Number Copied` 
    this.props.openPopup("CopyPhoneFixedPopup" , {message:message, color:col,});
  };
  
//*************************** */
contactReqCreateHandler=(id,name,contactR)=>{
  const values= {...contactR,builder:id,dealerName:name,}
 
  if (!this.props.userA) {
    this.props.openModal('LoginModal')

  }else{
    this.props.createContactReq( values)
  }

}

// shortlistAddRemoveHandler = (id) => {
//   if (!this.props.userA) {
//     this.props.openModal('LoginModal')

//   }else{
//     this.props.shortlistAddRemoveDealer(id);
//   }

//   };

  
//************************ */ 1 <= req.body.viewCount < 40
  render() {
    

    // const rendertypeOfDealings = () => {
    //   return typeOfDealings.map(item => {
    //     return (
    //       <span key={item}>
    //         {" "}
    //         <b>{item} |</b>
    //       </span>
    //     );
    //   });
    // };
    // const rendertypeOfPropertyDealsIn = () => {
    //   return typeOfPropertyDealsIn.map(item => {
    //     return (
    //       <span key={item}>
    //         {" "}
    //         <b>{item} |</b>
    //       </span>
    //     );
    //   });
    // };


    if (!this.props.builder) {
      return (<Loading/>)
    }
    const {
      reraNo,
      cname,
      cmobile1,
      cmobile2,
      cemail,
      website,
      cimage,
      address,
      locality,
      city,
      state,
      pincode,
      operatingSince,
      nameCP1,
      mobileCP1,
      //emailCP1,
      designationCP1,
      photoCP1,
      nameCP2,
      mobileCP2,
     // emailCP2,
      designationCP2,
      photoCP2,
      // typeOfDealings,
      // typeOfPropertyDealsIn,
      locationOnMap,
      description,
      _id
    } = this.props.builder;


    //***************************
    const {uname,
      uphone1,
      uphone2,
      uemail,
      uwhatsapp,
  
        }=this.props.userA !== null && this.props.userA
      const  requesterId= this.props.userA !== null && this.props.userA._id
    const contactReq= {
      requestFor:'General',
      companyType:'Builder',
      uname,
      uphone1,
      uphone2,
      uemail,
      uwhatsapp,
      requesterId,
      }
  
      
  
    const renderContactReqButtonOrNot = () =>
          this.props.userA != null &&
          (this.props.userA.userTypeW ==='Dealer' ||
          this.props.userA.userTypeW ==='Builder') ? (
            <div></div>
  
          ) : (
        <Button
        // basic
         inverted
        floated="right"
        color="blue"
        type="button"
        onClick={() => this.contactReqCreateHandler(_id,cname,contactReq)}
        >
          Ask Builder To Contact You
         </Button>
      
  
          );
          //************************
  
    // const checkID = this.props.userA !== null && this.props.userA.shortlistDealer !==undefined && this.props.userA.shortlistDealer.filter(val => {

    //   return val.toString() === _id;
    // });
    // const renderShortlistButton = () =>
    //       checkID.length > 0 ? (
    //     <Button

    //       inverted
		// 			floated="right"
		// 			color="violet"
    //       onClick={() => this.shortlistAddRemoveHandler(_id)}
    //     >
    //       Remove From Shortlist
    //     </Button>
    //   ) : (
    //     <Button
    //       inverted
		// 			floated="right"
		// 			color="violet"
    //       onClick={() => this.shortlistAddRemoveHandler(_id)}
    //     >
    //       Add To Shortlist
    //     </Button>
    //   );


          //###########################

          const rendermobileCP1 = () => 
             (
            <span >
            <span >
              {mobileCP1}
            </span>
            <span className='nodisplay-tab'>
               <CopyToClipboard text={mobileCP1}  >
               <span className="icon-button"  onClick= {() => this.handleOpenPopup(mobileCP1,'blue' )}>
            <img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>
              </CopyToClipboard>
              </span>
              </span>
              )
              const rendermobileCP2 = () => 
                  (
                  <span >
                  <span >
                    {mobileCP2}
                  </span>
                  <span className='nodisplay-tab'>
                     <CopyToClipboard text={mobileCP2}  > 
                     <span className="icon-button"  onClick= {() => this.handleOpenPopup(mobileCP2,'teal' )}>
                  <img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
                  </span>
                    </CopyToClipboard>
                    </span>
                    </span>
                   )
         
                   const renderCompanyMobile = () => 
                     (
                     < React.Fragment>
                      <div >
              {cmobile1 && (
                <div className='row'>
                  <div className='side'>
                    <b>Phone</b>
                  </div>
                  <div className='main'>
                    <b>{cmobile1}{' '}</b>
                    {cmobile2 && (

                          <b>,{' '} {cmobile2}</b>

                      )}
                  </div>

                      </div>
                    )}
                  </div>

                  <div >
                  {cemail && (
                    <div className='row'>
                      <div className='side'>
                        <b>Email</b>
                      </div>
                      <div className='main'>
                        <b>{cemail}</b>
                      </div>

                    </div>
                  )}
                  </div>
                   </React.Fragment>
                       )
  //******************
// const values= {
//   _id
// }
//**********************

    return (
      
      <div className='maindiv-768'>


          {/* start */}

      
        
      <div   >
                
                {/*  */}
      <div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
      <div className='heading-2'>
      <span  > {cname}</span>
      </div>
      </div>
                {/*  */}




            <div className='card-attached border-sb pxy-20'>

{/*  */}
<div className='media-600 ' style={{display:'flex',justifyContent:'center'}}> {/*start of div containing cp1 and cp2 detail */}

{ nameCP1 &&  (<div className='card-cp' > {/* cp1 detail*/}
  <div className='flex-value-DA' style={{display:'flex',flexDirection:'column'}}>
  <img 
  className="icon-button"
  onClick={() => this.handleOpenModal(photoCP1)}
  src={`${photoCP1}`} alt="Photo_First contact person" 
  style={{width:"100px", height:"100px",padding:'5px'}} />
  </div>


<div className='flex-value-DB 'style={{padding:'10px',fontWeight:"bold"}}>


<div>{nameCP1 && (
<div >
  {nameCP1}
</div>
)}
</div>  {/* */}

<div>{designationCP1 && (
<div>{designationCP1}</div>
)}</div>  {/* */}

<div>{mobileCP1 && (
rendermobileCP1()
)}</div>  {/* */}

  </div>
  </div>)}
{/*end cp1 details */}

  { nameCP2 &&  (<div className='card-cp' >{/* cp2 detail*/}
  <div className='flex-value-DA' style={{display:'flex',flexDirection:'column'}}>
  <img
  className="icon-button"
  onClick={() => this.handleOpenModal(photoCP2)}
  src={`${photoCP2}`} alt="Photo_Second contact person" 
  style={{width:"100px", height:"100px",padding:'5px'}} />
  </div>


<div className='flex-value-DB 'style={{padding:'10px',fontWeight:"bold"}}>


<div>{nameCP2 && (
<div >
  {nameCP2}
</div>
)}
</div>  {/* */}
<div>{designationCP2 && (
<div>{designationCP2}</div>
)}</div>  {/* */}
<div>{mobileCP2 && (
rendermobileCP2()
)}</div>  {/* */}

  </div>
  </div>)}

</div> 

    {/*end of div containing cp1 and cp2 detail */}
    

              <br />
             {/*  */}
		
			<div className='display-flex'  style= {{ marginBottom:'20px'}} >
			
      {renderContactReqButtonOrNot()}
      {/* { renderShortlistButton()} */}
			</div>
		
		{/*  */}
              <div>
              
              <Divider fitted />
                <div >
                {address && (
                  <div className='row'>
                    <div className='side'>
                    <Image
                  floated="left"
                  size="tiny"
                  src={`${cimage}`}
                />
                    </div>
                    <div className='main'style={{ display:'flex',alignItems:'center'}} >
                      <b>{address}{' '}{locality}{' '}{city}{' '}{state}{' '}{pincode}
                      {' '} <span>
                      <a target='_blank'  rel="noreferrer noopener"   href={`${locationOnMap}`}>
              <span className="ml-05">
              {' '} <img className= 'yimg' src='/mapIcon.png' alt="Location on Map" style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
              </span>
              </a>
          </span>
          </b>
                    </div>

                  </div>
                )}
              </div>
              {renderCompanyMobile()}

            
          <div >
          {website && (
            <div className='row'>
              <div className='side'>
                <b>Website</b>
              </div>
              <div className='main'>
              <b>{website}</b>
               {/* <a  href={website} target='_blank'><b>{website}</b></a> */}
              </div>
             
            </div>
          )}
        </div>
        
        <div >
        {reraNo && (
        <div className='row'>
        <div className='side'>
        <b>Company's RERA No.</b>
        </div>
        <div className='main'>
        <b>{reraNo}</b>
        </div>

        </div>
        )}
        </div>

        
        <div >
        {operatingSince && (
          <div className='row'>
            <div className='side'>
              <b>Operating Since</b>
            </div>
            <div className='main'>
              <b>{format(new Date(operatingSince), "dd-MM-yyyy")}</b>
            </div>

          </div>
        )}
      </div>



              {/* <div >
               {typeOfDealings != null &&
                            typeOfDealings.length >= 1 && (
                <div className='row'>
                  <div className='side'>
                    <b>Type of Dealings</b>
                  </div>
                  <div className='main'>
                    {rendertypeOfDealings()}
                  </div>

                </div>
              )}
             </div> */}
             {/* <div >
             {typeOfPropertyDealsIn != null &&
                          typeOfPropertyDealsIn.length >= 1 && (
                <div className='row'>
                  <div className='side'>
                    <b>Type Of Property deals in</b>
                  </div>
                  <div className='main'>
                     {rendertypeOfPropertyDealsIn()}
                  </div>

                </div>
              )}
             </div> */}

             <div >
             {description && (
               <div className='row'>
                 <div className='side'>
                   <b>About Company</b>
                 </div>
                 <div className='main'>
                   <b>{description}</b>
                 </div>

               </div>
             )}
             </div>


              </div>

              
              </div>
              
              <div className='card-attached border-sb border-db-bottom '  >
              <ProjectRSearchListBuilder 
              heading={'On Going / New Projects'}
              project={this.props.projectRsBuilderOngoing}  />
              <ProjectRSearchListBuilder 
               heading={'Past Projects'}
              project={this.props.projectRsBuilderPast}    />
              <ProjectRSearchListBuilder 
               heading={'Upcoming Projects'}
              project={this.props.projectRsBuilderUpcoming}    />
      
              </div>

          </div>
        



        
      
      {/* end */}
      
<br/><br/><br/><br/>
{/* closing first div */}
      </div> 

    );
  }
}

function mapStateToProps(state) {
 
  return { 
  projectRsBuilderOngoing:state.projectRs.projectRsBuilderOngoing,
  projectRsBuilderPast:state.projectRs.projectRsBuilderPast,
  projectRsBuilderUpcoming:state.projectRs.projectRsBuilderUpcoming,
  builder: state.builders.builder ,
  userA :state.auth.userA
  };
}

export default connect(
  mapStateToProps,
  {fetchProjectRForBuilder,fetchBuilder, shortlistAddRemoveDealer,createContactReq,viewCountChecker,openPopup, openModal}
)(withRouter(OtherBuilderDetail));
