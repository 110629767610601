import React, { Component } from "react";

import { connect } from "react-redux";
import format from "date-fns/format";
import Loading from "../common/Loading"; 
import {fetchCreditAdds} from "../../actions/creditAddAction";
import writtenNumber from "written-number";
import { openModal } from "../../actions/modalAction";
import {
  Icon,
} from "semantic-ui-react";


class CreditDetailList extends Component {

  componentDidMount() {
    const values = {};
    this.props.fetchCreditAdds(values);
  }
  capitalizeFirst=(input)=> {
    var words = input.split(' ');
    var CapitalizedWords = [];
    words.forEach(element => {
      CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
    });
    return CapitalizedWords.join(' ');
  }
  renderList=(item)=> {
          const {
          creditType,
          creditAdd,
          amountPaid,
          paymentDetail,
          expiredOn,
          createdAt,

          _id
          } = item;

    
    return (
      <div key={_id}>
      <div className='card' style={{padding:'10px',marginTop:'0px',border:'solid 2px dodgerblue',}} >
      
      <div ><h6 className='border-bottom'style={{marginTop:'5px',marginBottom:'5px'}}>Credit Details
      <span style={{color:'dodgerblue',marginLeft:'20px'}}></span>
      </h6></div>
      <div style={{fontSize:'1rem'}}>
      <div >

      {creditType && (
      <div className='row'>
      <div className='side'>
      <b>CREDIT FOR</b>
      </div>
      <div className='main'>
      <b>{creditType}</b>
      </div>

      </div>
      )}
      </div>
      <div >
      {creditAdd && (
      <div className='row'>
      <div className='side'>
      <b>CREDIT ADDED</b>
      </div>
      <div className='main'>
      
          <b> {creditAdd}</b>
          <b>
           {' '} ({this.capitalizeFirst(writtenNumber(creditAdd, {
            lang: "enIndian"
          }))}
          ){" "}
          </b>
      
      </div>

      </div>
      )}
      </div>

      <div >
      {amountPaid && (
      <div className='row'>
      <div className='side'>
      <b>AMOUNT PAID</b>
      </div>
      <div className='main'>
      <Icon className="rupee" ></Icon>
          <b> {amountPaid}</b>
          <b>
           {' '} ({this.capitalizeFirst(writtenNumber(amountPaid, {
            lang: "enIndian"
          }))}
          ){" "}
          </b>
     
      </div>

      </div>
      )}
      </div>

      <div >
      {paymentDetail && (
      <div className='row'>
      <div className='side'>
      <b>PAYMENT DETAIL</b>
      </div>
      <div className='main'>
      <b>{paymentDetail}</b>
      </div>

      </div>
      )}
      </div>

      <div >
      {expiredOn && (
      <div className='row'>
      <div className='side'>
      <b>EXPIRED ON</b>
      </div>
      <div className='main'>
      <b>{format(new Date(expiredOn), "dd-MM-yyyy")}</b>
      </div>

      </div>
      )}
      </div>
      <div >
      {createdAt && (
      <div className='row'>
      <div className='side'>
      <b>ADDED ON</b>
      </div>
      <div className='main'>
      <b>{format(new Date(createdAt), "dd-MM-yyyy")}</b>
      </div>

      </div>
      )}
      </div>


      </div>


      </div>
      </div>


      );
        }

  

  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
  }


  render() {
    const {balanceCreditProp, balanceCreditReq, creditExpiredOn}=this.props.userA !== null && this.props.userA
    const expire =()=>{
      if(Date.parse(creditExpiredOn) < Date.now()){
    return (
      <span className="color-red">Expired</span>
    )
      }else{
        return (
          <span >Expire</span>
        )
      }
    } 
    return (

      <div className='maindiv-820'>
  <div >
  

  <div>
    {/**/}
<div className='cashbook-H box-shadow'style={{justifyContent: 'center'}}>


<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
<span>Credit Details</span>
</div>



</div>
{/**/}
<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
<div className='heading-2'>
	<span  > {balanceCreditProp}{' '} Credits Available For Property</span>
	</div>

  <div className='heading-2'>
	<span  > {balanceCreditReq}{' '} Credits Available For Require</span>
	</div>

  <div className='heading-2'>
	<span  >Credits {expire()} On{' '} {creditExpiredOn && format(new Date(creditExpiredOn), "dd-MM-yyyy")}</span>
	</div>

	</div>
  {/*  */} 
  

  {this.renderLoading()}
  {this.props.creditAdds.map(this.renderList)}

  
  <br/><br/><br/>
  
  </div>
 


  </div>
  </div>

    );
  }
}


const mapStateToProps = ({ creditAdds, selection, form,auth, }) => {
  const { fetching,limit, offset, count,} = creditAdds;

  return {
    
    limit,
    offset,
    count,
    fetching,
    form: form.CreditAddSearch,
    selection,
    creditAdds: creditAdds.creditAdds,
    userA:auth.userA
  };
};
export default connect(
  mapStateToProps,
  {fetchCreditAdds,openModal}
)(CreditDetailList);
