


//#########
const propertyForO = [ '','SALE', 'RENT'];
export const propertyFor = () => {
  return propertyForO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};

//############
const adForO = ['','Property', 'Project'];
export const adFor = () => {
  return adForO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};

//#######
const projectTypeO = ['', 'Residential','Commercial'];
export const projectType = () => {
  return projectTypeO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//###############
const propertyTypeO = [
  'FLAT-Society',
  'FLAT-DDA',
  'Builder Floor',
  'Penthouse',
  'Duplex House',
  'Residential Land /Plot'
];
export const propertyType = () => {
  return propertyTypeO.map(value => ({
    key: value,
    text: value,
    value: value ,style:{fontSize:'18px'}
  }));
};
//###############
const localityO = [
  '',
  'Sector-1',
  'Sector-2',
  'Sector-3',
  'Sector-4',
  'Sector-5',
  'Sector-6',
  'Sector-7',
  'Sector-8',
  'Sector-9',
  'Sector-10',
  'Sector-11',
  'Sector-12',
  'Sector-13',
  'Sector-14',
  'Sector-15',
  'Sector-16',
  'Sector-17',
  'Sector-18',
  'Sector-19',
  'Sector-20',
  'Sector-21',
  'Sector-22',
  'Sector-23',
  'Sector-24',
  'Sector-25',
  'Sector-26',
  'Sector-27',
  'Sector-28',
  'Sector-29',
  'Sector-30'
];
export const locality = () => {
  return localityO.map(value => ({
    key: value,
    text: value,
    value: value ,style:{fontSize:'18px'}
  }));
};