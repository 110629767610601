import React, { Component } from "react";
import { withRouter, } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import writtenNumber from "written-number";
import {capitalizeFirst} from "../functions/Functions";
import {resetOffsetReqResis} from "../../actions/reqresiAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button,  } from "semantic-ui-react";
import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";



import {
  requiredFor,
  propertyType,
  bedroom,
  bathroom,
  additionalRooms,
  
} from "./Data";


  class ReqResiSearch extends Component {
    // projectName = () => {
    //   return this.props.state.projectRs.projectRsForData.map(value => ({
    //     key: value.projectName,
    //     text: `${value.projectName}` ,
    //     value: value.projectName,style:{fontSize:'18px'}
    //   }));
    // };
   onSubmit = () => {
    this.props.resetOffsetReqResis();
    this.props.closeModal()
    this.props.history.push(`/reqresi/ReqResiSearchList`);
  };
  render(){
  const { handleSubmit,reset,  submitting } = this.props;
  const { budgetMinL,budgetMinH,budgetMaxL,budgetMaxH, } =
    this.props.state.form &&
    this.props.state.form.ReqResiSearch !== undefined &&
    this.props.state.form.ReqResiSearch.values !== undefined &&
    this.props.state.form.ReqResiSearch.values;



  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search  Requirement for Residential Property
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(this.onSubmit)}
          >
            <Button
            color="teal"
            type="button"
            onClick={()=>this.props.closeModal()}

            >
            Cancel
            </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
            style={{color:'black'}}
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <br/><br/>
          <label >Required For</label>
          <Field
            name="requiredFor"
            placeholder="Required For"
            type="text"
            options={requiredFor()}
            //search={true}
            component={SelectInput}
          />
            <label >Property Type</label>
            <Field
              name="propertyType"
              placeholder="Property Type"
              type="text"
              options={propertyType()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Bedroom</label>
            <Field
              name="bedroom"
              placeholder="Bedroom"
              type="text"
              options={bedroom()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Bathroom</label>
            <Field
              name="bathroom"
              placeholder="Bathroom"
              type="text"
              options={bathroom()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Additional Rooms</label>
            <Field
              name="additionalRooms"
              placeholder="Additional Rooms"
              type="text"
              options={additionalRooms()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />


            <label >Minimum Budget Low {" "}
            <span>
            ({budgetMinL && capitalizeFirst(writtenNumber(budgetMinL, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
              
            </span></label>
          <Field
            name="budgetMinL"
            placeholder="Lower Value of Minimum Budget to Search Requirement in a Range"
            type="number"
            component={TextInput}
          />
          <label >Minimum Budget High {" "}
            <span>
            ({budgetMinH && capitalizeFirst(writtenNumber(budgetMinH, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
              
            </span></label>
          <Field
            name="budgetMinH"
            placeholder="Higher Value of Minimum Budget to Search Requirement in a Range"
            type="number"
            component={TextInput}
          />
          <label >Maximum Budget Low {" "}
            <span>
            ({budgetMaxL && capitalizeFirst(writtenNumber(budgetMaxL, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
              
            </span></label>
          <Field
            name="budgetMaxL"
            placeholder="Lower Value of Maximum Budget to Search Requirement in a Range"
            type="number"
            component={TextInput}
          />
          <label >Maximum Budget High {" "}
            <span>
            ({budgetMaxH && capitalizeFirst(writtenNumber(budgetMaxH, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
              
            </span></label>
          <Field
            name="budgetMaxH"
            placeholder="Higher Value of Maximum Budget to Search Requirement in a Range"
            type="number"
            component={TextInput}
          />

            <br />

            <Button
            style={{color:'black'}}
               color="blue"
               type="submit"
               disabled={submitting}
              //  as={Link}
              //  to={`/reqresi/ReqResiSearchList`}
             >
               Search
             </Button>
             <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
             <Button
               color="teal"

               type="button"
               onClick={()=>this.props.closeModal()}

             >
               Cancel
             </Button>
          </Form>
          <br/><br/><br/>
              </div>
              </div>
              </div>
  );
}
}
const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA
  };
};
export default connect(
  mapStateToProps,
  {resetOffsetReqResis,closeModal}
)(
  reduxForm({
    form: "ReqResiSearch", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(withRouter(ReqResiSearch))
);
