import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import {fetchAllProjectCs} from '../../actions/projectCAction';
import {fetchAllProjectRs} from '../../actions/projectRAction';
import { getUserForAuth,logoutUser,clearStorage,showSidebar,hideSidebar } from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";

import {  Button, Image,
 // Modal,
  Icon,
  Popup,
  } from "semantic-ui-react";

class Navbar extends Component {
  state = {
    openPop: false,
    display:'none',
    openPop2: true
  };

  componentDidMount() {
    // if (!this.props.parameter ){
    //   this.props.fetchAppParameter()
    //     }
    if (!this.props.userA){
      this.props.getUserForAuth();
    }
    if(this.props.state.projectCs.projectCsForData.length <1){
      this.props.fetchAllProjectCs();
    }
    if(this.props.state.projectRs.projectRsForData.length <1){
      this.props.fetchAllProjectRs();
    }

this.openPopup()
  }
  onLogoutClick = () => {
  this.props.logoutUser();
  };
  onSignInClick = () => {
    this.props.openModal('LoginModal');
  };
    
  //************************************Activity/Profile Popup */
    handleOpenPop = () => {this.setState({ openPop: true }); window.scroll(0,0)}
    handleClosePop = () => this.setState({ openPop: false })
  //*******************************************
  handleSidebarToggle=()=>{
    this.props.showSidebar();
    window.scroll(0,0)
  }

openPopup =()=>{
  if(localStorage.alertRead !=='Yes'){
  setTimeout(()=>this.props.openModal('AlertModal'),10000)
}
}
 // *****************************************

  render() {
    
    const { isAuthenticated,user  } = this.props.auth;
    
//&&&&&&&&&&&&&&&&&&&&&&

  const renderBackButton =()=>{
    return this.props.visibleBB=== true ? (
      <button

    onClick={() => this.props.history.goBack()}

          className='back-btn'
        >
          <Icon name="arrow left" />

        </button>
    ) : (
      <div></div>
      )}
      //@@@@

      const renderRefreshButton =()=>{
        return  (
          <Button
          circular
          icon='refresh'
          onClick={() => window.location.reload(false)}
          style={{backgroundColor: 'dodgerblue', color:'white'}}
        />
        ) }
    ///$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ Activity/Profile Popup

		const renderLoginLogoutButton=()=>{
      return isAuthenticated ? (

          <Button onClick={this.onLogoutClick}
          color='red'
          size="mini"
          style={{maxWidth:'105px',padding:'7px',fontSize:'16px'}}
          ><Icon name="power" />Sign Out</Button>
        ) : (
          <Button
          onClick={this.onSignInClick}
          size="mini"
          color='teal'
          style={{maxWidth:'105px',padding:'7px',fontSize:'16px'}}
          >
          <Icon name="power" />
          Sign In
          </Button>
        )}

        //@@@ 
        const renderRegisterButton=()=>{
          return  (
            <Button 
            as={Link}
            to={`/register`}
            color='blue'
            size="mini"
            style={{maxWidth:'150px',padding:'7px',fontSize:'18px'}}
            >
              <Icon name="signup" />
            Register
            </Button>
            ) 
          }
    

        //########################################
        const userA=this.props.userA !== null && this.props.userA
      
        const renderProfileActivityLink=() => {

          const linkHeadClass = 'linkFP-head activity-head-padding'
          const linkClass = 'linkFP textAlign-center'
          
          
          if (userA.userTypeW ==='Individual') {
        
          return (<div className='card-attached'>
            <div className='pxy-10 textAlign-center'> 
            {renderLoginLogoutButton()}
            <br/>
            </div>

            <div className={linkClass}
            style={{backgroundColor:'dodgerblue',padding:'5px',cursor:'default'}}
            >
          
          <b><span style={{color:'white'}}>{user.name}{' '}</span></b>
        
          </div>
            
            <div className={linkClass}>
          <Link to={`/company/UserDetail/${userA._id}`} >
          <b>User Details</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={`/passchange`} >
          <Icon name='settings' />
          <b>Change Password</b>
        </Link>
          </div>
          {/* <div className={linkHeadClass} >
            REQUESTS</div>
          <div className={linkClass}>
          <Link to={'/activity/ContactReqByPartyList'} >
          <b>Contact Requests Sent</b>
        </Link>
          </div> */}

          <div className={linkHeadClass} >
            SHORTLISTS</div>

            <div className={linkClass}>
          <Link to={'/company/ShortlistCompany'} >
          <b>Shortlist Dealers</b>
        </Link>
          </div>
          {/* <div className={linkClass}>
          <Link to={'/builder/ShortlistBuilder'} >
          <b>Shortlist Builders</b>
        </Link>
          </div> */}
            <div className={linkClass}>
          <Link to={'/projectR/ShortlistProject'} >
          <b>Shortlist Projects</b>
        </Link>
          </div>
          
          <div className={linkClass}>
          <Link to={'/propresi/ShortlistResi'} >
          <b>Shortlist Residential Properties</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/propcom/ShortlistCom'} >
          <b>Shortlist Commercial Properties</b>
        </Link>
          </div>

          <div className={linkHeadClass} >
            PROPERTIES</div>
          <div className={linkClass}>
          <Link to={'/propresi/MyPropResiList'} >
          <b>My Residential Properties</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/propcom/MyPropComList'} >
          <b>My Commercial Properties</b>
        </Link>
          </div>

          
          <div className={linkHeadClass} >
            REQUIREMENTS</div>
          <div className={linkClass}>
          <Link to={'/reqresi/MyReqResiList'} >
          <b>My Requirement For Residential Properties</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/reqcom/MyReqComList'} >
          <b>My Requirement For Commercial Properties</b>
        </Link>
          </div>
          </div>)
          
          } else if (userA.userTypeW ==='Dealer'){
            
          return (<div className='card-attached '>

            <div className='pxy-10 textAlign-center'>
            
            {renderLoginLogoutButton()}
            <br/>
            </div>

            <div className={linkClass}
            style={{backgroundColor:'dodgerblue',padding:'5px',cursor:'default'}}
            >
          
          <b><span style={{color:'white',}}>{user.name}{' '}</span></b>
        
          </div>
            
            <div className={linkClass}>
          <Link to={'/activity/CreditDetailList'} >
          <b>Credit Detail</b>
        </Link>
          </div>
            <div className={linkClass}>
          <Link to={`/company/UserDetail/${userA._id}`} >
          <b>User Details</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/company/mycompany'} >
          <b>Company Details</b>
        </Link>
          </div>

          
          <div className={linkClass}>
          <Link to={`/passchange`} >
          <Icon name='settings' />
          <b>Change Password</b>
        </Link>
          </div>

          <div className={linkHeadClass} >
            REQUESTS</div>
          <div className={linkClass}>
          <Link to={'/activity/ContactReqForDealerList'} >
          <b>Contact Requests Received</b>
        </Link>
          </div>

          <div className={linkHeadClass} >
            ADVERTISEMENTS</div>
            <div className={linkClass}>
          <Link to={'/static/StaticSearchList'} >
          <b>Book Static Advertisement</b>
        </Link>
          </div>
          
            <div className={linkClass}>
          <Link to={'/activity/AdByFinancerList'} >
          <b>Financer Advertisement Details</b>
        </Link>
          </div>

          <div className={linkClass}>
          <Link to={'/activity/AdByDealerList'} >
          <b>Dynamic Advertisement Details</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/static/StaticDealerAdByDealer'} >
          <b>Static Dealer Advertisement Details</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/static/StaticPropAdByDealer'} >
          <b>Static Property Advertisement Details</b>
        </Link>
          </div>
                

          <div className={linkHeadClass} >
            REQUIREMENTS</div>
          <div className={linkClass}>
          <Link to={'/reqresi/ReqResiUnlockedList'} >
          <b>Unlocked Residential Requirements</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/reqcom/ReqComUnlockedList'} >
          <b>Unlocked Commercial Requirements</b>
        </Link>
          </div>

          </div>)
            
          }else if (userA.userTypeW ==='Builder'){

            
          return (<div className='card-attached ' style={{paddingBottom:'280px',paddingTop:'20px'}}>

          <div className='pxy-10 textAlign-center'>
          
          {renderLoginLogoutButton()}
          <br/>
          </div>

          <div className={linkClass}
          style={{backgroundColor:'dodgerblue',padding:'5px',cursor:'default'}}
          >
        
        <b><span style={{color:'white',}}>{user.name}{' '}</span></b>
      
        </div>
          
          
          <div className={linkClass}>
        <Link to={`/company/UserDetail/${userA._id}`} >
        <b>User Details</b>
      </Link>
        </div>
        <div className={linkClass}>
        <Link to={`/builder/mycompany`} >
        <b>Company Details</b>
      </Link>
        </div>

        
        <div className={linkClass}>
        <Link to={`/passchange`} >
        <Icon name='settings' />
        <b>Change Password</b>
      </Link>
        </div>

        <div className={linkHeadClass} >
          REQUESTS</div>
        <div className={linkClass}>
        <Link to={'/activity/ContactReqForBuilderList'} >
        <b>Contact Requests Received</b>
      </Link>
        </div>


        </div>
        )
            
          }else{
            return (
              <div className='card-attached pxy-20 '
              style={{minHeight:'60vh',minWidth:'40vh'}}
              >
                
                
                <div className="border pxy-20">
                <b>To View Please</b>
                {renderLoginLogoutButton()}
                </div>
                
                <div className="border pxy-20">
                <b>If Not Registered Please </b>
                {renderRegisterButton()}
                </div>
  
                </div>
                
                )
          }
                      
        }

        //@@@@@@@@@@@@@@@@

        const postPropertyRequirementLink=() => {

          return (
        <div className='card-attached'>
          <div className='dropdownFP-head' >
            POST PROPERTIES</div> 

          <div className='linkFP  textAlign-center'>
          <Link to={'/propresi/PropResiCreate'} >
          <b> Residential </b>
        </Link>
          </div>

          <div className='linkFP  textAlign-center'>
          <Link to={'/propcom/PropComCreate'} >
          <b> Commercial </b>
        </Link>
          </div>

          
          <div className='dropdownFP-head' >
            POST REQUIREMENTS</div>

          <div className='linkFP textAlign-center'>
          <Link to={'/reqresi/ReqResiCreate'} >
          <b>Residential</b>
        </Link>
          </div>

          <div className='linkFP textAlign-center'>
          <Link to={'/reqcom/ReqComCreate'} >
          <b>Commercial</b>
        </Link>
          </div>
          
          
          </div>
          )
        
        }


        

        //@@@@@@@@@@@@


//########################################



    //$$$$$$$$$$$$$$$$$$$$$$$$$$$

const renderShowHideSidebarButton =()=>{
  return this.props.visible=== true ? (
    <div>
    <button className='btn'  onClick={this.props.hideSidebar}

    ><Icon name="close" /></button></div>
  ) : (
    <div>
    <button  className='btn' onClick={this.handleSidebarToggle }

    ><Icon name="sidebar" /></button></div>
    )}

    //@@@@@@
// const showDisplay = ()=>{
//  this.setState({display:'block'})
// }
// const hideDisplay = ()=>{
//   this.setState({display:'none'})
// }
// const toggleDisplay = ()=>{
//   if(this.state.display==='none'){
//   this.setState({display:'block'})
//  }
//  if(this.state.display==='block'){
//   this.setState({display:'none'})
//  }
// }
    //@@@@

    const authLinks = (
      <div>

      <nav id="navbar-top" className='border-bottom-nav' style={{backgroundColor:'white',flexDirection:'row'}}>



         <div><Image size='mini' src='/logo.png' style={{ marginRight: '.5em' }} />
         </div>
         {renderBackButton()}
         {/* <div>FindRealEstate</div> */}
         {renderShowHideSidebarButton()}
         {/* {this.openPopup()} */}
{/*  */}

<div className="dropdown-container">
<button>Post Requirement | Property</button>
<ul className='margin-postProp'>

{postPropertyRequirementLink()} 

</ul>
</div>
{/*  */}
      </nav>
      {/* NAV DESKTOP */}

      <nav id="navbar-desktop" className='border-bottom-nav'  style={{backgroundColor:'white',flexDirection:'row'}}>

        <div style={{display:'flex'}}>
{/*  */}
<div style={{display:'flex',alignItems:'center'}}><Image size='mini' src='/logo.png' style={{ marginRight: '.5em' }} />
<div className='nodisplay' style={{fontSize:'1.2rem',fontWeight:'bold',marginRight:'5px'}}>
  <span style={{color:'dodgerblue'}}>FIND</span>
  
  <span style={{color:'blue'}}>PROP</span>
  <span style={{color:'orange'}}>.IN</span></div>
</div>
{/*  */}
        
        {renderBackButton()}
        {/* <div>FindRealEstate</div> */}
        {renderShowHideSidebarButton()}
        {renderRefreshButton()}
        </div>


 <ul>

        <li>
            <Link to={'/'} >

        <div style={{fontSize:'1.2rem',fontWeight:'bold',marginRight:'10px'}}><Icon name='home' />Home</div>
        </Link>

      </li>
      {/*  */}
{/* <li>
<div class="dropdownFP">
  <button class="dropbtn">Post Requirement | Property</button>
  <div class="dropdownFP-content">
  {postPropertyRequirementLink()}
  </div>
</div>
</li> */}
      {/*  */}

          <li>

        <div className="dropdown-container">
        <button>Post Requirement | Property</button>
        <ul className='margin-postProp'>

        {postPropertyRequirementLink()} 

        </ul>
        </div>

          
        </li>
         

      <li>
      
        <div className="dropdown-container">
        <button><Icon name="user outline" />Profile|Activity</button>
        <ul className='margin-activity'>

        {renderProfileActivityLink()} 

        </ul>
        </div>

      </li>



        </ul>
     </nav>

{/* bottom navbar */}
<nav id="navbar-bottom" className='border-top-nav' style={{backgroundColor:'white'}}>

        <ul>
          {/* <li>
          {this.renderBackButton()}
          </li> */}
          <li><button className='btn'  style={{fontSize:'1.5rem',fontWeight:'bold'}}>
            <Link to={'/'} >
            <Icon name='home' />
        {/* <div>Home</div> */}
        </Link>

       </button></li>
{/*  */}
       <li><button className='btn'  style={{fontSize:'1.5rem',fontWeight:'bold'}}  
       onClick={() => window.location.reload(false)}

><Icon name='refresh' />
{/* <div>Refresh</div> */}
</button>
</li>
{/*  */}
      <li><button className='btn'  style={{fontSize:'1.5rem',fontWeight:'bold'}}  
      onClick={this.handleOpenPop}

      ><Icon name="user outline" />
      {/* <div>Profile|Activity</div> */}
      </button>
      </li>

{/*  */}



        </ul>
      </nav>
      <Popup

onOpen={this.handleOpenPop}
position='bottom right'
      open={this.state.openPop}
      onClose={this.handleClosePop}
      wide
      // size='small'
      onClick={this.handleClosePop}
      style={{padding:'0px'}}
    >
        <Popup.Content >
          <div style={{textAlign:'center'}}>
        {/* <div className='sidebar-border-divider sidebar-div-link'>
           {renderLoginLogoutButton()}
           <br/>
        </div> */}
        {renderProfileActivityLink()}
        </div>
      </Popup.Content>

    </Popup>

    </div>
    );



    return (
      <div>
        {
          authLinks
        }
      </div>
    );
  }
}
//@@@@@@@@@@@@@@@@@



const mapStateToProps = state => ({
  state,
  auth: state.auth,
  visible: state.auth.visible,
  visibleBB: state.auth.visibleBB,
  userA: state.auth.userA,
});

export default connect(
  mapStateToProps,
  { getUserForAuth,logoutUser,clearStorage, openModal,
    showSidebar,hideSidebar ,fetchAllProjectRs,fetchAllProjectCs }
)(withRouter(Navbar));
