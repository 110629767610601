import React, { Component } from "react";
import { Link,  } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";
import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading"; 
import {fetchStaticPropAdByDealer,updateStaticToRemoveProp} from "../../actions/staticAction";


import AddPropertyToStatic from './AddPropertyToStatic';

import { openModal } from "../../actions/modalAction";
import {
  Icon,
  Button,
  Modal
  
} from "semantic-ui-react";



class StaticPropAdByDealer extends Component {
  state = {
    pageO:'',
    modalOpen: false,
    valuesAddToProp: {} ,
      };
  componentDidMount() {

    const {offset, limit} = this.props;
      
      const values =
        (this.props.form !== undefined && this.props.form.values) || {};
    
    this.props.fetchStaticPropAdByDealer(values, limit,offset);
  }

  handleSearchStaticProperty = () => {
    this.props.openModal("SearchStaticPropertyModal");
  };
  //**************** */
handleRefresh = () =>{
	const {offset, limit} = this.props;
      
      const values =
        (this.props.form !== undefined && this.props.form.values) || {};
    
    this.props.fetchStaticPropAdByDealer(values, limit,offset);
}
//**************** */
 
  handleModalOpen = (value) => this.setState({ modalOpen: true,valuesAddToProp:value })

  handleModalClose = () => this.setState({ modalOpen: false })  //contact modal

    //***************************
    handleUpdateToRemoveProp= (item) => {
     
      
     
      const {offset, limit} = this.props;
      
      const values =
        (this.props.form !== undefined && this.props.form.values) || {};
           
        const valuesF= {...item,...values,offset,limit};
       
      this.props.updateStaticToRemoveProp(valuesF);
      //this.props.history.goBack()
      //this.props.handleClose()
    };   
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    //^^^^^^^^^^^^^^^^^^^^^^^^^
onChange=(e) => {
  this.setState({ [e.target.name]: e.target.value });
}
//***************************
  renderList=(item)=> {
    const {
      creditUsed,
      start,
       expire,
       adFor,
       adCategory,
       adLocation,
       adSubLocation,
       position,
    //  dealerName,
    //  dealer,
    //***************** */

    
    //****************** */
    propOrProjId,
    propertyFor,
    propertyType,
    bedroom,
    additionalRooms,
    expectedPrice,
    expectedRent,
    carpetArea,
    area,
    areaUnit,
    projectName,
    //	projectId,
    locality,
    //createdAt,

    _id
    } = item;
    const propertyForValue = (value) =>{

      if( value ==="Property SALE"){
        return 'SALE'
      }
      if( value ==="Property RENT"){ 
       return 'RENT'
     }
     
     }
     
    const valuesAddToProp ={
    propertyFor:propertyForValue(adFor),
    adCategory,
    _id
    }

    //************************** render active/inactive/expired
const startOn= Date.parse(start)
const expireOn = (Date.parse(expire))
const now = Date.now()
//**************************


const renderStatus=() => {
  if ((now<startOn)){
    return (<span >
      Inactive
     </span>)
  }

  if( (now>startOn && now<expireOn )){
    return (<span >
       Active
     </span>)
  }
  if ((now>expireOn)){
  return (<span className='color-red'>
      Expired
     </span>)
  }
  return (<span >
    No Status
   </span>)
  }


    //**************************


    const renderRemovePropButton = () =>{
    if(projectName){
    return (
    <Button
    // floated="right"
    color="blue"
    onClick={() => this.handleUpdateToRemoveProp({_id})}
    >
    Remove Property
    </Button>
    )
    }
    }

    //**************************
    const roomOrBHK = (adCategory==='Residential') ? 'BHK' :'Room'
    const renderPropOrProj = () =>{
    if(projectName){
    return  (
    <div >
    {projectName && (
    <div className='row'>
    <div className='side'>
    <b>Location</b>
    </div>
    <div className='main'>
    <b>

    <div>
    {bedroom && (

    <span >
    {bedroom}  {' '}{roomOrBHK}
    </span>)

    }{' '}
    {additionalRooms && additionalRooms.length >0 && (

    <span >
    {renderadditionalRooms()}
    </span>)

    }{' '}
    {carpetArea >0 && (

    <span >
    {carpetArea} sq ft
    </span>)

    }{' '}
    {area && area>0 (

    <span >
    {area}
    </span>)

    }{' '}
    {areaUnit && (

    <span >
    {areaUnit}
    </span>)

    }{' '}
    {propertyType && (

    <span >
    {propertyType}
    </span>)

    }{' '}
    {propertyFor && (

    <span >
    For  {propertyFor}
    </span>)

    }{' '}
    {expectedPrice>0 && (

    <span >
    For  <Icon className="rupee" ></Icon>
    {price(expectedPrice)}{' '}In
    </span>)

    }{' '}
    {expectedRent>0 && (

    <span >
    For  <Icon className="rupee" ></Icon>
    {expectedRent}{' '}In
    </span>)

    }{' '}
    {projectName && (

    <span >
    {' '}{projectName}
    </span>)

    }{' '}
    {locality && (

    <span >
    {locality} Dwarka, New Delhi
    </span>)

    }{' '}
    {renderDetailButton()}
    {renderRemovePropButton()}
    </div>

    </b>
    </div>

    </div>
    )}
    </div>

    )
    }else{
    if( (now>startOn && now<expireOn )){
    return (<Button
    color="blue"
    type="button"
    //as={Link}
    //to={`/static/AddPropertyToStatic/${_id}`}
    onClick={() => this.handleModalOpen(valuesAddToProp)}
    >
    Add Property
    </Button>)
    }   
    }
    }
    //**************************
    const renderDetailButton=() => {

    if(adFor==="Property" && adCategory==='Residential' ){
    return (<Button
    inverted
    //floated="right"
    color="blue"
    type="button"
    as={Link}
    to={`/propresi/PropResiDetail/${propOrProjId}`}
    >
    Detail
    </Button>)
    }
    if(adFor==="Property" && adCategory==='Commercial' ){
    return (<Button
    inverted
    //floated="right"
    color="blue"
    type="button"
    as={Link}
    to={`/propcom/PropComDetail/${propOrProjId}`}
    >
    Detail
    </Button>)
    }



    }

    //**************************
    const renderadditionalRooms = () => {
    return additionalRooms.map(item => {
    return (
    <span key={item}>
    {" "}
    {item}|
    </span>
    );
    });
    };
    const price = (value) => {
    var val = Math.abs(value)
    if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lac';
    }
    /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
    return val;
    }

    //const daysAgo = Math.round((Date.now()-Date.parse()/(24*60*60*1000));

    return (
<div key={_id}>

<div className='card' style={{padding:'10px',marginTop:'0px',border:'solid 2px dodgerblue',}} >
{/*ad detqils start*/}
<div ><h6 className='border-bottom'style={{marginTop:'5px',marginBottom:'5px'}}>{(adFor !== 'Dealer') && adCategory}{' '} {' '} {adFor}{' '}
At {adLocation}
<span style={{color:'dodgerblue',marginLeft:'20px'}}>{ renderStatus()}</span>
</h6></div>

<div style={{fontSize:'1rem'}}>
<div >
{start && (
<div className='row'>
<div className='side'>
<b>Duration</b>
</div>
<div className='main'>
<b>{ format(new Date(start), "dd-MM-yyyy")}{' '}To{' '}{ format(new Date(expire), "dd-MM-yyyy")}{' '}   </b>
</div>

</div>
)}
</div>

<div >
{creditUsed && (
<div className='row'>
<div className='side'>
<b>Credit Used</b>
</div>
<div className='main'>
<b>{ creditUsed}   </b>
</div>

</div>
)}
</div>



{/* {renderDealer()} */}
{/* static ad location start */}

<div >
{adFor && (
<div className='row'>
<div className='side'>
<b>Ad Location</b>
</div>
<div className='main'>
<b>

<div>
{(adFor !== 'Dealer') && adCategory && (

<span >
{adCategory}
</span>)

}{' '}

{adFor && (

<span >
{adFor}
</span>)

}{' '}

{adLocation && (

<span >
At {adLocation}
</span>)

}{' '}
{/*  */}
{((adLocation==='STATIC ALL')||(adLocation==='STATIC SECTOR')) 
&& adSubLocation && (
<span >
{adSubLocation}
</span>)

}{' '}
{position && (

<span >
On Position  {position}
</span>)

}{' '}
{/*  */}



</div>

</b>
</div>

</div>
)}
</div>
    {/* static ad location end */}
    {renderPropOrProj()} {/* property details */}

    </div>


    </div>
    </div>


    );
    }

    back = () => {
    const {
    offset,
    limit

    } = this.props;
   
    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    if (offset === 0) {
    return;
    }

    this.props.fetchStaticPropAdByDealer(values, limit, (offset - limit));
    window.scroll(0,0)
    };

    advance = () => {
    const {
    offset,
    limit,
    count

    } = this.props;
    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    if (offset + limit > count) {
    return;
    }

    this.props.fetchStaticPropAdByDealer(values, limit, (offset + limit));
    window.scroll(0,0)
    };


    handleGoToPage = () => {
    const {

    limit,
    count

    } = this.props;

    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    // use Math.abs for strict equality ===  to work and safe guard against negative value from user
    if (Math.abs(this.state.pageO) === 0) {
    return; 
    }

    const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)


    this.props.fetchStaticPropAdByDealer(values, limit, (page * limit));
    window.scroll(0,0)

    }

    //****************** */
    renderPaginator=()=> {
    if (this.props.statics.length) {
    return (
    <Paginator
    advance={this.advance}
    back={this.back}
    offset={this.props.offset}
    limit={this.props.limit}
    count={this.props.count}
    //values={this.props.values}
    />
    );
    }
    }


    renderLoading=()=> {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
    }


    render() {

    return (

    <div className='maindiv-820'>
    <div >


    <div>
      {/**/}
  
  <Modal
        style={{maxWidth:'900px'}}
        open={this.state.modalOpen}
        onClose={this.handleModalClose}
        basic
        //size='small'
      >

        <Modal.Content 
        style={{maxWidth:'900px',padding:'10px'}}
        >

<AddPropertyToStatic
valuesAddToProp = {this.state.valuesAddToProp}
handleModalClose = {this.handleModalClose}
handleRefresh = {this.handleRefresh}
/>
        </Modal.Content>

      </Modal>
      {/*  */}
      {/**/}
<div className='cashbook-H box-shadow'>

<div
style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
onClick={this.handleSearchStaticProperty}>
      <span className='icon-button2 border1' > <Icon name='search' /></span >
    </div>



<div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
All Static Properties Advertisement ({this.props.count})
</div>
</div>


</div>
{/**/}
    

    {this.renderLoading()}
    {this.props.statics.map(this.renderList)}
{/*  */}
<div className='disflex-paginator card-paginator media-600'>
{this.renderPaginator()}
<div className="disflex-paginator  " >
  <div className= 'font-paginator'>Go To Page</div>
  <form>
        <input
        className='inputPage'
          placeholder="number"
          name="pageO"
          type="number"
          value={this.state.pageO}
          onChange={this.onChange}
        />
        <span  className="btnNumber"  onClick={this.handleGoToPage} >
          Submit
        </span>
      </form>

        
</div>
<br/>
</div>
{/*  */}
    

    <br/><br/><br/><br/>

    <div></div>
    <div></div>
    </div>
    <div className='nodisplay'>
    {/* side grid space*/}
    </div>


    </div>
    </div>

    );
    }
    }


    const mapStateToProps = ({ statics, selection, form }) => {
    const { fetching,limitP, offsetP, countP,} = statics;
    
    return {

    limit:limitP ,
    offset:offsetP ,
    count: countP,
    fetching,
    form: form.StaticSearchProperty,
    selection,
    statics: statics.staticsProperty
    };
    };
    export default connect(
    mapStateToProps,
    {fetchStaticPropAdByDealer,updateStaticToRemoveProp,openModal,}
    )(StaticPropAdByDealer);
