import axios from "axios";
import { toastr } from "react-redux-toastr";

//import { closeModal } from "./modalAction";

import {
  TRANSPORT,
  //MESSAGE,
} from "./types";


//search  Images

export const transport = ( transporter) => async dispatch => {

  try {

    dispatch({ type: TRANSPORT, payload: {transporter:transporter} });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//fetch AppParameter
export const fetchAppParameter = () => async dispatch => {
  try {
    const res = await axios.get(`/api/AppParameter`);
    dispatch({ type: TRANSPORT, payload: res.data });
    

  } catch (error) {
  
    toastr.error("Oops", "Something went wrong.Please Try Again_fetchAppParameter");
  }
};
