import React, { Component } from "react";
import { connect } from "react-redux";
import axios from 'axios';
import format from "date-fns/format";
import {  withRouter } from "react-router-dom";
import {fetchProjectC} from "../../actions/projectCAction";
		
import {fetchDealerSuggestedsForProj,} from "../../actions/dealerSuggestedAction";
import { openModal } from "../../actions/modalAction";
import { shortlistAddRemoveProject } from "../../actions/authAction";
import AllPropComSaleList from "../propertyCom/AllPropComSaleList";
import AllPropComRentList from "../propertyCom/AllPropComRentList";

import DealerSuggestedListForProject from "../dealerSuggesteds/DealerSuggestedListForProject";
import AddDealerForProj from './AddDealerForProj';
import Loading from "../common/Loading";
import {
  Button,
  Modal
} from "semantic-ui-react";






class ProjectCDetail extends Component {
  state = {
      propSale:true,
      propRent:false,
     
      propSaleCount:0 ,
      propRentCount:0 ,
      createModalOpen: false,
  };

  componentDidMount = async ()  =>{
    window.scroll(0,0)
    const { id } = this.props.match.params;
    this.props.fetchProjectC(id);
   
      const res = await axios.post(`/api/getCountPropCom/${id}`);
     
      this.setState({
         propSaleCount:res.data.propSaleCount ,
         propRentCount:res.data.propRentCount ,
      });

  }

        //**************** */
handleRefresh = () =>{
	const { id } = this.props.match.params;
	this.props.fetchProjectC(id);
	this.props.fetchDealerSuggestedsForProj(id);
}
//**************** */
handleOpenModalImage = (id,imageOf) => {   
  this.props.openModal("ShowImagesModal" , {id:id,imageOf:imageOf});
};
//^^^^^^^^^^^^^^^^^^
handleOpenModalVideo = (id,imageOf) => {   
  this.props.openModal("ShowVideoModal" , {id:id,imageOf:imageOf});
};
  
        //*************************** */
        
		handleCreateModalOpen = () => {
			if (!this.props.userA) {
				this.props.openModal('LoginModal')
				}else{
				this.setState({ createModalOpen:true });
				}	
		}
		handleCreateModalClose = () => this.setState({ createModalOpen:false, });

		//*************************** */
        shortlistAddRemoveHandler = (id) => {
        if (!this.props.userA) {
        this.props.openModal('LoginModal')

        }else{
        this.props.shortlistAddRemoveProject(id);
        }

        };
        //************************ */
        showPropSale =()=> this.setState({ propSale: true,propRent: false, })
        showPropRent =()=> this.setState({ propSale: false,propRent: true, })
        //#######################
        

//********************************* */


  render() {
    // const { open, result } = this.state;
    if (!this.props.projectC) {
      return (<Loading/>)
    }
    const {
      projectC: {
        reraNo,
        imagePath,
        projectType,
        propertyTypeProj,
        category,
        projectName,
        address,
        locality,
        // subCity,
         city,
        // state,
        // pincode,
        locationOnMap,
        configuration,
        towers,
        units,
        status,
        possession,
        ageOfConstruction,
        totalFloors,
        liftsInTheTower,
        amenitiesBasic,
        //@@
				creditRequireForAd,
				creditRemainToLock,
				creditRequireToLock,
				//@@
        adAllowedToDealers,
        idsOfDealerAllowedToAd,
       // description,
        _id
      }
    } = this.props;
    

    const {uname,
      uphone1,
      uphone2,
      uemail,
      uwhatsapp,

        }=this.props.userA !== null && this.props.userA
      const  requesterId= this.props.userA !== null && this.props.userA._id
    
      const {creditExpiredOn, balanceCreditProp, userTypeW,companyId} = this.props.userA !== null && this.props.userA
      //** */
  
      const propOrprojDetail= {
        propOrProjId:_id,
        projectType,
        projectName,
        locality,
        }
      const contactReq= {
        requestFor:'Project',
      uname,
      uphone1,
      uphone2,
      uemail,
      uwhatsapp,
      requesterId,
      ...propOrprojDetail
      }
      
      //******************
      const valueDS = {
      adFor: 'Project',
      creditExpiredOn,
      balanceCreditProp,
      creditRequireForAd,
      creditRemainToLock,
      creditRequireToLock,
      ...propOrprojDetail
      }
      //**************** */
    
      
      
      //******************
      const checkID = this.props.userA !== null && this.props.userA.shortlistProj !==undefined &&  this.props.userA.shortlistProj.filter(val => {

      return val.toString() === _id;
      });
      //^^^^^^
      const renderShortlistButton = () =>{
        if((userTypeW !== 'Dealer') && (userTypeW !== 'Builder')){
         
        return   checkID.length > 0 ? (
		<span
 onClick={() => this.shortlistAddRemoveHandler(_id)}
 className="icon-button"
 >
 <img className= 'yimg ' src='/statusIconOk.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
	
	) : (
		<span
 onClick={() => this.shortlistAddRemoveHandler(_id)}
 className="icon-button"
 >
 <img className= 'yimg ' src='/shortlistIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
	
	);
  }
}

     
      //******************

      
      const renderpropertyType = () => {
      return propertyTypeProj.map(item => {
      return (
      <span key={item}>
      {" "}
      <b>{item} <span className='pipe'>|</span></b>
      </span>
      );
      });
      };
      const renderconfiguration = () => {
      return configuration.map(item => {
      return (
      <span key={item}>
      {" "}
      <b>{item} <span className='pipe'>|</span></b>
      </span>
      );
      });
      };

      const renderamenitiesBasic = () => {
      return amenitiesBasic.map(item => {
      return (
      <span key={item}>
      {" "}
      <b>{item} <span className='pipe'>|</span></b>
      </span>
      );
      });
      };



      //******************
      const  renderPropSale = () => {
      if (this.state.propSale===true) {

      return <AllPropComSaleList projectId={_id} />
      }
      };
      const  renderPropRent = () => {
      if (this.state.propRent===true) {

      return <AllPropComRentList projectId={_id} />
      }
      };
      //*************************
      const checkIdForAd = idsOfDealerAllowedToAd.filter(val => {

        return val.toString() === companyId;
        });
      //^^^^^^^^^^^^^^^^^^^^^^^^^
      const renderCreateAdButton = () =>{

        if((userTypeW === 'Dealer' && creditRemainToLock >0) && ((adAllowedToDealers === 'Some' && checkIdForAd.length >0) || (adAllowedToDealers === 'All'))) { 
        return (
        <span
        onClick={() => this.handleCreateModalOpen()}
        className="icon-button"
        >
        <img className= 'yimg ' src='/adsIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>

        )
        } else{
        return (
        <span></span>
        )
        }

        }

      
      //******************
      const poss = (Date.parse(possession) < Date.now())? "Immediate":<span>{ possession &&  format(new Date(possession), "dd-MM-yyyy")}</span>
      //*******************************************************
     
     
      return (
      <div>
      <div className='maindiv-820'>


      <div>
      <div style={{boderRadius:'2px',marginBottom:'0px'}} >
      <img
      className= 'yimg imgDimention-detailPage'
      src={`${imagePath}`}
      alt="project image" 
      onClick={()=>this.handleOpenModalImage(_id,'Project')}
      />
      </div>
      
    {/* create Modal*/}
    <Modal
      open={this.state.createModalOpen}
      onClose={this.handleCreateModalClose}
      basic
      closeOnEscape={false}
      closeOnDimmerClick={false}
      // size='small'
      >

      <Modal.Content>
      <AddDealerForProj
      valueDS = {valueDS} 
      handleCreateModalClose = {this.handleCreateModalClose}
      handleRefresh = {this.handleRefresh}
      />
      </Modal.Content>

      </Modal>

            {/* create Modal end*/}

	<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > {projectName}</span>
	</div>

	</div>

	<div className='card-attached border-sb pxy-20'  >
	{/*  */}
      
{/*  */}
<a target='_blank'  rel="noreferrer noopener" href={`${locationOnMap}`} >
	<span
         className="icon-button"
        >
        <img className= 'yimg ' src='/mapIcon.png' alt="Location on Map" style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>
		</a>
		{/*  */}
		<span
 onClick={()=>this.handleOpenModalImage(_id,'Project')}
 className="icon-button"
 >
 <img className= 'yimg ' src='/imageAddIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
 {/*  */}
 <span
 onClick={()=>this.handleOpenModalVideo(_id,'Project')} 
 className="icon-button"
 >
 <img className= 'yimg ' src='/videoViewIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
 {/*  */}
 { renderShortlistButton()}
 {renderCreateAdButton()} 

 {/*  */}
{/*  */}
<div className='border-bottom'  style= {{marginTop:'10px',marginBottom:'10px',}}>
	<div  className="heading" style= {{marginBottom:'10px'}} >
	<span >
	Project Detail 
	</span>
	</div>
	</div>
	{/*  */}
      

<div >
{reraNo && (
<div className='row'>
<div className='side'>
<b>RERA No.</b>
</div>
<div className='main'>
<b>{reraNo}</b>
</div>

</div>
)}
</div>

        
      <div >
      {projectName && (
      <div className='row'>
      <div className='side'>
      <b>Project Name</b>
      </div>
      <div className='main'>
      <b>{projectName}</b>
      </div>

      </div>
      )}
      </div>
      <div >
      {address && (
      <div className='row'>
      <div className='side'>
      <b>Address</b>
      </div>
      <div className='main'>
      <b>{address}{' '}</b>
      </div>

      </div>
      )}
      </div>
      <div >
      {locality && (
      <div className='row'>
      <div className='side'>
      <b>Locality</b>
      </div>
      <div className='main'>
      <b>{locality}</b>
      </div>

      </div>
      )}
      </div>

      <div >
     {city && (
       <div className='row'>
         <div className='side'>
           <b>City/District</b>
         </div>
         <div className='main'>
           <b>{city}</b>
         </div>

       </div>
     )}
   </div>

      <div >
      {projectType && (
      <div className='row'>
      <div className='side'>
      <b>Project Type</b>
      </div>
      <div className='main'>
      <b>{projectType}</b>
      </div>

      </div>
      )}
      </div>
      <div >
      {category && (
      <div className='row'>
      <div className='side'>
      <b>Category</b>
      </div>
      <div className='main'>
      <b>{category}</b>
      </div>

      </div>
      )}
      </div>
      <div >
      {configuration &&
      configuration.length > 0 && (
      <div className='row'>
      <div className='side'>
      <b>Configuration</b>
      </div>
      <div className='main'>
      {renderconfiguration()}
      </div>

      </div>
      )}
      </div>
      <div >
      {propertyTypeProj &&
      propertyTypeProj.length > 0 && (
      <div className='row'>
      <div className='side'>
      <b>Property Type</b>
      </div>
      <div className='main'>
      {renderpropertyType()}
      </div>

      </div>
      )}
      </div>
      <div >
      {status && (
      <div className='row'>
      <div className='side'>
      <b>Status</b>
      </div>
      <div className='main'>
      <b>{status}</b>
      </div>

      </div>
      )}
      </div>
      <div >
      {possession && (
      <div className='row'>
      <div className='side'>
      <b>Possession</b>
      </div>
      <div className='main'>
      <b>{poss}</b>
      </div>

      </div>
      )}
      </div>
      <div >
      {ageOfConstruction && (
      <div className='row'>
      <div className='side'>
      <b>Age Of Construction</b>
      </div>
      <div className='main'>
      <b>{ageOfConstruction}</b>
      </div>

      </div>
      )}
      </div>
      <div >
      {liftsInTheTower && (
      <div className='row'>
      <div className='side'>
      <b>Lifts In The Tower</b>
      </div>
      <div className='main'>
      <b>{liftsInTheTower}</b>
      </div>

      </div>
      )}
      </div>
      <div >
      {towers && (
      <div className='row'>
      <div className='side'>
      <b>Total Towers</b>
      </div>
      <div className='main'>
      <b>{towers}</b>
      </div>

      </div>
      )}
      </div>
      <div >
      {units && (
      <div className='row'>
      <div className='side'>
      <b>Total Units</b>
      </div>
      <div className='main'>
      <b>{units}</b>
      </div>

      </div>
      )}
      </div>
      <div >
      {totalFloors && (
      <div className='row'>
      <div className='side'>
      <b>Total Floors</b>
      </div>
      <div className='main'>
      <b>{totalFloors}</b>
      </div>

      </div>
      )}
      </div>

      <div >
      {amenitiesBasic &&
      amenitiesBasic.length > 0 && (
      <div className='row'>
      <div className='side'>
      <b>Amenities</b>
      </div>
      <div className='main'>
      {renderamenitiesBasic()}
      </div>

      </div>
      )}
      </div>

      </div>
      <DealerSuggestedListForProject  contactReq={contactReq}  />
      {/*  */}
<div className='card-attached  border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >

<div className='heading-2' >
<span  > All Properties for Sale and Rent in {projectName}</span>
</div>
<Button.Group widths="2"  >

<Button
style={{marginRight:'1px'}}
// basic
//inverted
floated="right"
color="blue"
type="button"
onClick={this.showPropSale}
>
{this.state.propSaleCount} Properties for Resale
</Button>
<Button
style={{marginLeft:'1px'}}
// basic
//inverted
floated="right"
color="blue"
type="button"
onClick={this.showPropRent}
>
{this.state.propRentCount} Properties for Rent
</Button>

</Button.Group>
</div>
{/*  */}

      { renderPropSale()}
      { renderPropRent()}
        
      <br/>
	
	<br/>
	<br/>
      
      </div>
      


      </div>


      </div>

      //**********************************



    );

//*************************************************************




  }
}
function mapStateToProps(state) {
 
  return {
    userA: state.auth.userA,
    projectC: state.projectCs.projectC,
  };
}

export default connect(
  mapStateToProps,
  {fetchProjectC,shortlistAddRemoveProject,openModal,
    fetchDealerSuggestedsForProj}
)(withRouter(ProjectCDetail));
