//import React, { Component } from 'react';
import React  from 'react';
import { Popup,Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { closePopup } from '../../actions/popupAction';

const actions = { closePopup };


 const CopyPhoneFixedPopup = (props)=> {
    
    return (
      <Popup size="tiny" open={true} 
      onClose={props.closePopup}
      //content={props.message}
      style = {{
        borderRadius: 15,
        fontSize:"20px",
        fontWeight:"bold",
        color:props.color,
        backgroundColor:'#fae6ff',
        //backgroundColor:'Salmon',
        //opacity: 0.7,
        padding: '30px',
        //zIndex:5
      }}
      offset={[100, 50]}
      position='bottom center'
      size = 'tiny'
      positionFixed
      
      
     >
       <div className='disflex-paginator'>
       <span className='border2 '
        onClick={props.closePopup}
        > 
        <Icon name='close' />
        </span >
        </div>

        <div>
        {props.message}
        </div>
     </Popup>
       
    );
  }

// const mapState = state => ({
//   message1: state.popups.popupProps.message
// });
export default connect(
  null,
  actions
)(CopyPhoneFixedPopup); 
