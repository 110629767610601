import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
//import writtenNumber from "written-number";

import {searchStatics} from "../../actions/staticAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button, } from "semantic-ui-react";
//import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";

import {
  adCategory,
  adForOption,
  adLocationProperty,
  adLocationDealer,
  adSubLocation,
  // position,
  // locality,

} from './Data';




const StaticSearch = props => {
  
  const { handleSubmit,  reset, submitting } = props;

  const onSubmit = values => {
    const offset = 0
const { limit} = props.state.statics;
    props.searchStatics(values, limit,offset);
  };
  //********************** */
  const { adFor, } =
  props.state.form &&
  props.state.form.StaticSearch !== undefined &&
  props.state.form.StaticSearch.values !== undefined &&
  props.state.form.StaticSearch.values;


//@@@@@


const renderAdForPropertyField = ()=>{
return (
 < React.Fragment>
        
        <label >Ad Category</label>
        <Field
          search={true}
          name="adCategory"
          type="text"
          component={SelectInput}
          options={adCategory()}
          placeholder="Ad Category"
        />

        
          <label >Ad Location</label>
          
          <Field
            search={true}
            name="adLocationA"
            type="text"
            component={SelectInput}
            multiple={true}
            options={adLocationProperty()}
            placeholder="Ad Location "
            />
          
 </React.Fragment>
)

}

//@@@@@
const renderAdForDealerField = ()=>{

return (
 < React.Fragment>
        
        <label >Ad Category</label>
        <Field
          search={true}
          name="adCategory"
          type="text"
          component={SelectInput}
          options={adCategory()}
          placeholder="Ad Category"
        />

        
          
          <Field
            search={true}
            name="adLocationA"
            type="text"
            component={SelectInput}
            multiple={true}
            options={adLocationDealer()}
            placeholder="Ad Location "
            />
          
 </React.Fragment>
)

}


//@@@@@@@@@@@@
const renderDealerOrPropertyField = () =>{
if((adFor==='Property SALE') || (adFor==='Property RENT')){
return renderAdForPropertyField()
}
if((adFor==='Dealer') ){
return renderAdForDealerField()
}
}



//********************** */


  return (
    
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Static Property Ad
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >

          <Button
          color="teal"
          type="button"
          onClick={()=>props.closeModal()}

          >
          Cancel
          </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting} 
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
            style={{color:'black'}}
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          <br /><br />
          <label >Ad For</label>
        <Field
          search={true}
          name="adFor"
          type="text"
          component={SelectInput}
          options={adForOption()}
          placeholder="Ad For"
        />

        {renderDealerOrPropertyField()}

        <label >Ad Sub Location</label>
           <Field
            search={true}
            name="adSubLocationA"
            type="text"
            component={SelectInput}
            multiple={true}
            options={adSubLocation()}
            placeholder="Ad Sub Location "
            />
          
            
            {/*  */}
            <label htmlFor="">Lower Started On Date</label>
            <Field
              name="startL"
              type="text"
              component={DateInput}
              placeholder= "In DD-MM-YYYY format Lower Started On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label htmlFor="">Higher Started On Date</label>
            <Field
              name="startH"
              type="text"
              component={DateInput}
              placeholder=" In DD-MM-YYYY format Higher Started On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label htmlFor="">Lower Expired On Date</label>
            <Field
              name="endL"
              type="text"
              component={DateInput}
              placeholder= " In DD-MM-YYYY format Lower Expired On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label htmlFor="">Higher Expired On Date</label>
            <Field
              name="endH"
              type="text"
              component={DateInput}
              placeholder=" In DD-MM-YYYY format Higher Expired On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />



            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting} 
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state
  };
};
export default connect(
  mapStateToProps,
   {searchStatics,closeModal}
)(
  reduxForm({
    form: "StaticSearch", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(StaticSearch)
);
