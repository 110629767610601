import React from "react";
import { withRouter, } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";


import {resetOffsetProjectRs} from "../../actions/projectRAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button,   Popup } from "semantic-ui-react";
//import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";

import {
 
  category,
  // city,
  // state,
  
  status,
  
  locality,
 
} from "./Data";


const ProjectRSearch = props => {
  
  const { handleSubmit,  reset, submitting } = props;
  const projectName = () => {
    return props.state.projectRs.projectRsForData.map(value => ({
      key: value.projectName,
      text: `${value.projectName}` ,
      value: value.projectName,style:{fontSize:'18px'}
    }));
  };
  const onSubmit = () => {
    props.resetOffsetProjectRs();
    props.closeModal()
    props.history.push(`/projectR/ProjectRSearchList`);
  };
  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Residential Projects
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >
            <Button
            color="teal"
            type="button"
            onClick={()=>props.closeModal()}

            >
            Cancel
            </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
            style={{color:'black'}}
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>

            <br/><br/>
            <label htmlFor="">Category</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search All Available Category or
        Enter Single or Multiple Values to Search Those Category
            </Popup>
            <Field
              name="categoryA"
              placeholder="Category"
              type="text"
              options={category()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />


            <label htmlFor="">Status</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search All Available Status or
        Enter Single or Multiple Values to Search Those Status
            </Popup>
            <Field
              name="statusA"
              placeholder="Status"
              type="text"
              options={status()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />



            <label htmlFor="">Project/Complex Name</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search All Project/Complex of Dwarka or
        Enter Single or Multiple Values to Search Those Project/Complex
            </Popup>
            <Field
              name="projectNameA"
              placeholder="Project/Complex Name"
              type="text"
              options={projectName()}
              search={true}
              multiple={true}
              component={SelectInput}
            />

            <label htmlFor="">Locality</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search Projects in All Sectors of Dwarka or
        Enter Single or Multiple Values to Search Projects in Those Sectors
            </Popup>
            <Field
              name="localityA"
              placeholder="Locality"
              type="text"
              options={locality()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />




            <br />

            <Button
            style={{color:'black'}}
              color="blue"
              type="submit"
              disabled={submitting}
              // as={Link}
              // to={`/projectR/ProjectRSearchList`}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state
  };
};
export default connect(
  mapStateToProps,
   {resetOffsetProjectRs,closeModal}
)(
  reduxForm({
    form: "ProjectRSearch", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(withRouter(ProjectRSearch))
);
