import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { closeModal, } from '../../actions/modalAction';
import {
  Button,
  Image,
  Modal,
  //Checkbox
} from 'semantic-ui-react';
import TAndC from './TAndC'


class Alert extends Component {
  
  state = {
    modalOpenTC: false,
  };
   handleLocalStorage=()=>{
    localStorage.setItem("alertRead", 'Yes');
     this.props.closeModal()
    //  window.location.reload(false)
   }
//***** */
handleOpenTC = () => this.setState({ modalOpenTC: true })
handleCloseTC = () => this.setState({ modalOpenTC: false })
//***** */
   
  
  render() {
  
    return (<React.Fragment >
            {/*  */}  
        {/* about modal */}
<Modal
style={{maxWidth:'768px'}}
open={this.state.modalOpenTC}
onClose={this.handleCloseTC}
//size='tiny'
// size='small'
>
<Modal.Content style={{maxWidth:'768px',padding:'0px'}}>

<TAndC 
handleCloseTC = {this.handleCloseTC}
/>
</Modal.Content>


</Modal>
{/*  */}
<div style={{display:'flex',alignItems:'center',justifyContent: 'center',marginBottom:'5px'}}><Image size='mini' src='/logo.png' style={{ marginRight: '.5em' }} />
<div  style={{fontSize:'1.2rem',fontWeight:'bold',marginRight:'5px'}}>
  <span style={{color:'dodgerblue'}}>FIND</span>
  
  <span style={{color:'blue'}}>PROP</span><span>.IN</span></div>
</div>


{/*  */}
      <div className='masthead format-login'>  
      <div className="font-16B" style={{textAlign: 'justify',marginBottom:'2px'}}>
      Although we try to provide the most genuine and accurate information on the website findprop.in we shall neither be responsible nor liable for any inaccuracy in the information provided here.
      </div>
      <div className="font-16B" style={{textAlign: 'justify',marginBottom:'2px'}}>
findprop.in is a platform for advertisement and does not vouch for the project or the details provided in the advertisement. Users are required to verify the authenticity of the claims made therein. 
</div>
<div className="font-16B" style={{textAlign: 'justify',marginBottom:'2px'}}>
You are advised to visit the relevant RERA website and contact builder
/ advertisers directly to know more about the project / property and independently validate the information before taking any decision based on the contents displayed on the website findprop.in
</div>
<br/>
<div className="font-16B" style={{textAlign: 'justify',marginBottom:'2px'}}>
By using or accessing the website findprop.in  you agree to our 
{' '}<span 
className='icon-button px-0'
onClick={this.handleOpenTC}
//onClick={this.handleTermsAndConditionsModal}
style={{color:'orangered',fontSize:'16px',fontWeight:'bold'}}> 
  Terms & Conditions
</span>

</div>
</div>
{/*  */}
      <div >




          <Button
            color="blue"
           // size="mini"
            type="button"
            onClick={this.handleLocalStorage}

          >
            Ok,Got it
          </Button>

      </div>
      
      </React.Fragment>
    );
  }
}



// const mapStateToProps = state => ({
//   auth: state.auth,
// });

export default connect(
  null,
  { closeModal, }
) (Alert);


// window.location.reload(false)
// localStorage.clear()