import axios from "axios";
import { toastr } from "react-redux-toastr";
import { closeModal, openModal } from "./modalAction";

import {
  FETCH_PROJECTRS,
  FETCH_PROJECTR,
  UPDATE_PROJECTR,
  SEARCH_PROJECTRS,
  FETCHING_PROJECTRS,
} from "./types";


//search  ProjectRs

export const searchProjectRs = (
  values,
  limit,
  offset
) => async dispatch => {
 
  try {
    const res = await axios.post("/api/ProjectRSearch", {
      values,
      offset
    });

  
    dispatch({ type: SEARCH_PROJECTRS, payload: res.data });
    dispatch({ type: FETCHING_PROJECTRS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//fetchAllProjectRs  for create props page //fetch All ProjectRs for Data use

export const fetchAllProjectRs = () => async dispatch => {

  try {
    const res = await axios.post("/api/AllProjectRList");

    
    dispatch({ type: SEARCH_PROJECTRS, payload: res.data });
    
  } catch (error) {
 
    toastr.error("Oops", "Something went wrong.Please Try Again searchMyProjectRs");
  }
};

//fetch shortlist ProjectRs
export const fetchShortlistProjectR= (values, limitSH, offsetSH) => async dispatch => {
  try {
    const res = await axios.post(`/api/ShortlistProjectR`, {
      values,
      limitSH,
      offsetSH
    });
    
    dispatch({ type: FETCH_PROJECTRS, payload: res.data });
    dispatch({ type: FETCHING_PROJECTRS, payload: false });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
      }else if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
}; 
//fetch single ProjectR for Propeerty
export const fetchProjectRForProp = id => async dispatch => {
  try {
    const res = await axios.post(`/api/ProjectRDetailForProp`,{id:id});
   
    dispatch({ type: FETCH_PROJECTR, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//fetch all ProjectR of Builder
export const fetchProjectRForBuilder = (value) => async dispatch => {
  try {
    const res = await axios.post(`/api/ProjectRForBuilder`,value);

    dispatch({ type: SEARCH_PROJECTRS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//#####################################################
//fetch single ProjectR
export const fetchProjectR = id => async dispatch => {
  try {
    const res = await axios.get(`/api/ProjectRDetail/${id}`);
    
    dispatch({ type: FETCH_PROJECTR, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//update ProjectR
export const updateProjectRForAddImagePath = (id, values) => async dispatch => {
  try {
    const res = await axios.put(`/api/ProjectREditForAddImagePath/${id}`, values);
    

    dispatch({ type: UPDATE_PROJECTR, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again AddImagePath");
  }
};
//resetOffset

export const resetOffsetProjectRs = () => async dispatch => {
  
  try {
        
    dispatch({ type: SEARCH_PROJECTRS, payload: {offset:0} });
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};