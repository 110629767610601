
import axios from "axios";
import { toastr } from "react-redux-toastr";

import { closeModal,openModal } from "./modalAction";

import {
  FETCH_PROPRESIS,
  FETCH_PROPRESI,
  UPDATE_PROPRESI,
  SEARCH_PROPRESIS,
  FETCHING_PROPRESIS,
  MESSAGE,
  SEARCH_STATICS,
} from "./types";



//List All SALE Properties In Project
export const allSalePropertiesInProjectR = (
  values,

) => async dispatch => {
 
  try {
    const res = await axios.post("/api/AllSalePropertiesInProjectR", {
      values,

    });

    
    dispatch({ type: SEARCH_PROPRESIS, payload: res.data });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//List All RENT Properties In Project
export const allRentPropertiesInProjectR = (
  values,

) => async dispatch => {
 
  try {
    const res = await axios.post("/api/AllRentPropertiesInProjectR", {
      values,

    });

   
    dispatch({ type: SEARCH_PROPRESIS, payload: res.data });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//#######################################################
//fetch individual  MyPropResi
export const fetchMyPropResis = () => async dispatch => {
  try {
    const res = await axios.post(`/api/MyPropResis`);

    dispatch({ type: FETCH_PROPRESIS, payload: res.data });
    dispatch({ type: FETCHING_PROPRESIS, payload: {fetching:false} });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
      }else if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
};

//******************
//fetch individual  FinancerPropResi
export const fetchFinancerPropResis = (id) => async dispatch => {
  try {
    const res = await axios.post(`/api/FinancerPropResis`,{id});
    
    dispatch({ type: FETCH_PROPRESIS, payload: res.data });
    dispatch({ type: FETCHING_PROPRESIS, payload: {fetching:false} });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try AgainFinancerPropResis");
  }
}; 
//fetch individual  FinancerPropResi
export const fetchFinancerPropResisAd = () => async dispatch => {
  try {
    const res = await axios.post(`/api/FinancerPropResisAd`);
    
    dispatch({ type: FETCH_PROPRESIS, payload: res.data });
    dispatch({ type: FETCHING_PROPRESIS, payload: {fetching:false} });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try AgainFinancerPropResis");
  }
};
//******************
//search  PropResis SALE

export const searchPropResiSales = (
    values,
    limitRS,
    offsetRS,
    limitRSF,
    offsetRSF,
    limitStaticRS,
    offsetStaticRS
) => async dispatch => {
 
  try {
    const res = await axios.post("/api/PropResiSaleSearch", {
    values,
    limitRS,
    offsetRS,
    limitRSF,
    offsetRSF,
    limitStaticRS,
    offsetStaticRS
    });

   
    dispatch({ type: SEARCH_PROPRESIS, payload: res.data });
    dispatch({ type: SEARCH_STATICS, payload: res.data });
    dispatch({ type: FETCHING_PROPRESIS, payload: {fetchingRS:false} });
    dispatch(closeModal());
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again searchMyPropResis");
  }
};

//******************
//search  BuilderFloor SALE

export const searchBuilderFloorSales = (
    values,
    limitRSBF,
    offsetRSBF,
    limitRSBFF,
    offsetRSBFF,
    limitStaticRSBF,
    offsetStaticRSBF
) => async dispatch => {
  
  try {
    const res = await axios.post("/api/BuilderFloorSaleSearch", {
    values,
    limitRSBF,
    offsetRSBF,
    limitRSBFF,
    offsetRSBFF,
    limitStaticRSBF,
    offsetStaticRSBF
    });

    

    dispatch({ type: SEARCH_PROPRESIS, payload: res.data });
    dispatch({ type: SEARCH_STATICS, payload: res.data });
    dispatch({ type: FETCHING_PROPRESIS, payload: {fetchingRSBF:false} });
    dispatch(closeModal());
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again searchMyPropResis");
  }
};

//*****************
//search  PropResis RENT

export const searchPropResiRents = (
    values,
    limitRR,
    offsetRR,
    limitStaticRR,
    offsetStaticRR
) => async dispatch => {
 
  try {
    const res = await axios.post("/api/PropResiRentSearch", {
    values,
    limitRR,
    offsetRR,
    limitStaticRR,
    offsetStaticRR,
    });


    dispatch({ type: SEARCH_PROPRESIS, payload: res.data });
    dispatch({ type: SEARCH_STATICS, payload: res.data });
    dispatch({ type: FETCHING_PROPRESIS, payload: {fetchingRR:false} });
    dispatch(closeModal());
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again searchMyPropResis");
  }
};
//fetchShortlistResiSale 
export const fetchShortlistResiSale = ( limitRS, offsetRSSH) => async dispatch => {
  try {
    const res = await axios.post(`/api/ShortlistResiSale`, {
      limitRS,
      offsetRSSH, 
    });
    
    dispatch({ type: FETCH_PROPRESIS, payload: res.data });
    dispatch({ type: FETCHING_PROPRESIS, payload: {fetching:false} });
  } catch (error) {
        if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};
//fetchShortlistResiRent 
export const fetchShortlistResiRent = ( limitRR, offsetRRSH) => async dispatch => {
  try {
    const res = await axios.post(`/api/ShortlistResiRent`, {
      limitRR,
      offsetRRSH,
    });
    
    dispatch({ type: FETCH_PROPRESIS, payload: res.data });
    dispatch({ type: FETCHING_PROPRESIS, payload: {fetching:false} });
  } catch (error) {
        if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};
//fetch single PropResi
export const fetchPropResi = id => async dispatch => {
  try {
    const res = await axios.get(`/api/PropResiDetail/${id}`);
  
    dispatch({ type: FETCH_PROPRESI, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};


//update updatePropResiForAddImagePath
export const updatePropResiForAddImagePath = (id, values) => async dispatch => {
  try {
    const res = await axios.put(`/api/PropResiEditForAddImagePath/${id}`, values);
    

    dispatch({ type: UPDATE_PROPRESI, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};



// create PropResi
export const createPropResi = (values, history) => async dispatch => {
  try {

    const res = await axios.post("/api/PropResiCreate", {
      values
    });
   
    dispatch({ type: MESSAGE, payload: res.data });
    history.push('/');
    dispatch(openModal('MessageModal'));

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//resetOffset

export const resetOffsetPropResis = () => async dispatch => {
  
  try {
        
    dispatch({ type: SEARCH_PROPRESIS, payload: {offsetRS:0 , offsetRR:0 , offsetRF:0} });
    dispatch({ type: SEARCH_STATICS, payload: {offsetStaticRS:0 , offsetStaticRR:0}, offsetStaticDealerRS:0, offsetStaticDealerRR:0 });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
