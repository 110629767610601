import axios from "axios";
import { toastr } from "react-redux-toastr";

import { closeModal, openModal } from "./modalAction";

import {
  FETCH_PROJECTCS,
  FETCH_PROJECTC,
  UPDATE_PROJECTC,
  SEARCH_PROJECTCS,
  FETCHING_PROJECTCS,
} from "./types";


//search  ProjectCs

export const searchProjectCs = (
  values,
  limit,
  offset
) => async dispatch => {
  
  try {
    const res = await axios.post("/api/ProjectCSearch", {
      values,
      limit,
      offset
    });

    
    dispatch({ type: SEARCH_PROJECTCS, payload: res.data });
    dispatch({ type: FETCHING_PROJECTCS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//fetch All ProjectRs for Data use

export const fetchAllProjectCs = () => async dispatch => {

  try {
    const res = await axios.post("/api/AllProjectCList");

   
    dispatch({ type: SEARCH_PROJECTCS, payload: res.data });
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again searchMyProjectCs");
  }
};

//fetch oshortlist ProjectCs
export const fetchShortlistProjectC = (values, limitSH, offsetSH) => async dispatch => {
  try {
    const res = await axios.post(`/api/ShortlistProjectC`, {
      values,
      limitSH,
      offsetSH
    });
   
    dispatch({ type: FETCH_PROJECTCS, payload: res.data });
    dispatch({ type: FETCHING_PROJECTCS, payload: false });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
      }else if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
};
//fetch single ProjectC for Propeerty
export const fetchProjectCForProp = id => async dispatch => {
  try {
    const res = await axios.post(`/api/ProjectCDetailForProp`,{id:id});
   
    dispatch({ type: FETCH_PROJECTC, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//#####################################################
//fetch single ProjectC
export const fetchProjectC = id => async dispatch => {
  try {
    const res = await axios.get(`/api/ProjectCDetail/${id}`);
   
    dispatch({ type: FETCH_PROJECTC, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//#####################################################



//update ProjectC
export const updateProjectCForAddImagePath = (id, values) => async dispatch => {
  try {
    const res = await axios.put(`/api/ProjectCEditForAddImagePath/${id}`, values);
    
    dispatch({ type: UPDATE_PROJECTC, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again AddImagePath");
  }
};


//resetOffset

export const resetOffsetProjectCs = () => async dispatch => {
  
  try {
        
    dispatch({ type: SEARCH_PROJECTCS, payload: {offset:0} });
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};