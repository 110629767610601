import React, { Component } from "react";
import { connect } from "react-redux";
import format from "date-fns/format";
import {  withRouter } from "react-router-dom";
import writtenNumber from "written-number";
import {fetchReqResi} from "../../actions/reqresiAction";
import { getUserForAuth } from "../../actions/authAction";
import Loading from "../common/Loading"; 
import {
  Icon,
  Button,
  Confirm,
  Modal,
} from "semantic-ui-react";
import UnlockContactDetail from './UnlockContactDetail';

class ReqResiDetail extends Component {
  state = {
    id:'',
    creditUsed:0,
    balanceCreditReq:0,
    modalOpen: false,
		modalOpenP: false,
    open: false,
    result: "show the modal to capture a result"
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchReqResi(id);
  }
  show = () => {
    this.setState({ open: true });
  };
  handleConfirm = () => {
    this.setState({ result: "confirmed", open: false });
    const { id } = this.props.match.params;

    this.props.updateReqResiToInactive(id, this.props.history);
  };
  handleCancel = () => this.setState({ result: "cancelled", open: false });

  
	//**************** */
handleRefresh = () =>{
	this.props.getUserForAuth()
	const { id } = this.props.match.params;
	this.props.fetchReqResi(id);    
}
	//**************** */
  
  handleModalOpen = (value) => {
    if (!this.props.userA) {
      this.props.openModal('LoginModal')
      }else{
      this.setState({ modalOpen:true , valueCUnlock: value});
      }	
  }
  handleModalClose = () => this.setState({ modalOpen:false, });

  //*************************** */
	
	handleOpenP = () => this.setState({ modalOpenP: true })

	handleCloseP = () => this.setState({ modalOpenP: false })
	//***************************BACK BUTTON */



	capitalizeFirst=(input)=> {
	var words = input.split(' ');
	var CapitalizedWords = [];
	words.forEach(element => {
	CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
	});
	return CapitalizedWords.join(' ');
	}

	//********************************* */
	render() {
	const { open,  } = this.state;
	if (!this.props.reqresi) {
		return (<Loading/>)
	}
	const {

	creditUsed,
	veiwLimit,
	dealers,
	requiredFor,
	propertyType,
	bedroom,
	bathroom,
	additionalRooms,
	furnishedStatus,
	entry,
	floorNo,
	projectName,
	locality,
	city,
	budgetMin,
	budgetMax,
	areaCarpetMin,
	areaCarpetMax,

	note,
	createdAt,
	_id
	} =
	this.props.reqresi != null && this.props.reqresi;

	const {creditExpiredOn,balanceCreditReq,}=this.props.userA !== null && this.props.userA
	const companyId = this.props.userA !== null && this.props.userA.companyId
	const valueCUnlock = {id:_id,creditExpiredOn,balanceCreditReq,creditUsed}

	const checkID = dealers !== undefined && dealers.filter(val => {

		return val.toString() === companyId;
		});

		const renderUnlockedButton = () =>{
		if(checkID.length > 0) {
		return (
		<Button
		disabled
		floated="right"
		color="orange"
		>
		Contact Detail Unlocked
		</Button>
		) 
		} }

		const renderUnlockButton = () =>{
		if(checkID.length < 1) {
		return (
		<Button
		floated="right"
		color="orange"
		onClick={() => this.handleModalOpen(valueCUnlock)}
		>
		Unlock Contact Detail
		</Button>
		) 
		} }

	const renderUnlockButtonOrNot = () =>{
	if( (this.props.userA !== null && this.props.userA.userTypeW ==='Dealer')
	&&   (dealers.length < veiwLimit) ) {
	return renderUnlockButton()}
	}


	//**************************

	const renderpropertyType = () => {
	return propertyType.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} |</b>
	</span>
	);
	});
	};

	const renderbedroom = () => {
	return bedroom.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} |</b>
	</span>
	);
	});
	};

	const renderbathroom = () => {
	return bathroom.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} |</b>
	</span>
	);
	});
	};

	const renderadditionalRooms = () => {
	return additionalRooms.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} |</b>
	</span>
	);
	});
	};

	const renderfurnishedStatus = () => {
	return furnishedStatus.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} |</b>
	</span>
	);
	});
	};

	const renderentry = () => {
	return entry.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} |</b>
	</span>
	);
	});
	};

	const renderfloorNo = () => {
	return floorNo.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} |</b>
	</span>
	);
	});
	};

	const renderprojectName = () => {
	return projectName.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} |</b>
	</span>
	);
	});
	};

	const renderlocality = () => {
	return locality.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} |</b>
	</span>
	);
	});
	};
	// const renderButton = () =>
	//   this.props.userA != null &&
	//   (this.props.userA.propRSDelete === "Yes" ||
	// 	this.props.userA.userType === "admin") ? (
	// 	<Button
	// 	  // basic

	// 	  inverted
	// 	  floated="right"
	// 	  color="red"
	// 	  type="button"
	// 	  onClick={() => this.show(_id)}
	// 	>
	// 	  Delete
	// 	</Button>
	//   ) : (
	// 	<div></div>
	//   );
	//******************


	return (
	<div>
	<div className='maindiv-820'>


	<div>


	<Confirm
	// header={_id}
	open={open}
	onCancel={this.handleCancel}
	onConfirm={() => this.handleConfirm()}
	/>

	
	{/* */}
	<Modal
	open={this.state.modalOpen}
	onClose={this.handleModalClose}
	basic
	size='small'
	>

	<Modal.Content>
	<UnlockContactDetail 
  handleModalClose = {this.handleModalClose}
  handleRefresh ={this.handleRefresh}
  valueCUnlock={this.state.valueCUnlock}

  />
	</Modal.Content>

	</Modal>
	{/* */}
	

	</div>
	
	
	{/*  */}


	<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > Requirement For Residential Property For {requiredFor}</span>
	</div>

	</div>

	<div className='card-attached border-sb border-db-bottom pxy-20'  >
	{/*  */}
	<div>
		{/*  */}
	
		{renderUnlockButtonOrNot()}
		{renderUnlockedButton()}
 {/*  */}
	
	 
	<br />
	</div>
	{/*  */}

	

	{/*  */}
	<div className='border-bottom'  style= {{marginTop:'10px',marginBottom:'10px',}}>
	<div  className="heading" style= {{marginBottom:'10px'}} >
	<span >
	Requirement Detail 
	</span>
	</div>
	</div>
	{/*  */}
	<div >
	{requiredFor && (
	<div className='row'>
	<div className='side'>
	<b>Require For</b>
	</div>
	<div className='main'>
	<b>{requiredFor}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{propertyType != null &&
	propertyType.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Type Of Property</b>
	</div>
	<div className='main'>
	{renderpropertyType()}
	</div>

	</div>
	)}
	</div>

	<div >
	{bedroom != null &&
	bedroom.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Bedroom</b>
	</div>
	<div className='main'>
	{renderbedroom()}
	</div>

	</div>
	)}
	</div>
	<div >
	{bathroom != null &&
	bathroom.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Bathroom</b>
	</div>
	<div className='main'>
	{renderbathroom()}
	</div>

	</div>
	)}
	</div>
	<div >
	{additionalRooms != null &&
	additionalRooms.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Additional Rooms</b>
	</div>
	<div className='main'>
	{renderadditionalRooms()}
	</div>

	</div>
	)}
	</div>



	<div >
	{furnishedStatus != null &&
	furnishedStatus.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Furnished Status</b>
	</div>
	<div className='main'>
	{renderfurnishedStatus()}
	</div>

	</div>
	)}
	</div>
	{/**/}



	<div >
	{floorNo != null &&
	floorNo.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Floor No</b>
	</div>
	<div className='main'>
	{renderfloorNo()}
	</div>

	</div>
	)}
	</div>

	<div >
	{entry != null &&
	entry.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Entry</b>
	</div>
	<div className='main'>
	{renderentry()}
	</div>

	</div>
	)}
	</div>

	{/**/}


	<div >
	{budgetMin > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Minimum Budget</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {budgetMin}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(budgetMin, {
	lang: "enIndian"
	}))}
	){" "}
	</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{budgetMax > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Maximum Budget</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {budgetMax}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(budgetMax, {
	lang: "enIndian"
	}))}
	){" "}
	</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{areaCarpetMin > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Minimum Carpet Area</b>
	</div>
	<div className='main'>

	<b> {areaCarpetMin}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(areaCarpetMin, {
	lang: "enIndian"
	}))}
	){" "} sq ft
	</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{areaCarpetMax > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Maximum Carpet Area</b>
	</div>
	<div className='main'>

	<b> {areaCarpetMax}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(areaCarpetMax, {
	lang: "enIndian"
	}))}
	){" "} sq ft
	</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{projectName  != null &&
	projectName.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Complex/Project Preferred</b>
	</div>
	<div className='main'>
	{renderprojectName ()}
	</div>

	</div>
	)}
	</div>

	<div >
	{locality != null &&
	locality.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Locality</b>
	</div>
	<div className='main'>
	{renderlocality()}
	</div>
	</div>
	)}
	</div>

	<div >
     {city && (
       <div className='row'>
         <div className='side'>
           <b>City/District</b>
         </div>
         <div className='main'>
           <b>{city}</b>
         </div>

       </div>
     )}
   </div>

	<div >
	{createdAt && (
	<div className='row'>
	<div className='side'>
	<b>Created On</b>
	</div>
	<div className='main'>

	<b>{format(new Date(createdAt), "dd-MM-yyyy")}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{note && (
	<div className='row'>
	<div className='side'>
	<b>Note/Comments</b>
	</div>
	<div className='main'>
	<b>{note}</b>
	</div>

	</div>
	)}
	</div>


	{/* other rent details  END*/}



	</div>


	</div>
	
	<br/>
	
	<br/>
	<br/>

	</div>



	//**********************************



	);
	}
	}
	function mapStateToProps(state) {
	
	return {
	reqresi: state.reqresis.reqresi,
	userA: state.auth.userA
	};
	}

	export default connect(
	mapStateToProps,
	{fetchReqResi,getUserForAuth}
	)(withRouter(ReqResiDetail));
