import React, { Component } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
// import {
//   Button,
// } from "semantic-ui-react";

class RenewDealerSuggestedProjectAd extends Component {
  state = {  
    creditRequireForAd:0 ,
    creditRequireToLock:0,
  };

  componentDidMount = async ()  =>{
   console.log('projectAd')
    const propOrProjId = this.props.valueRenew.propOrProjId;
    
    if(this.props.valueRenew.projectType ==='Residential'){
      const res = await axios.get(`/api/ProjectRCreditDetail/${propOrProjId}`);
      this.setState({
        creditRequireForAd:res.data.creditRequireForAd ,
        creditRequireToLock:res.data.creditRequireToLock ,
      });
    }
    if(this.props.valueRenew.projectType ==='Commercial'){
      const res = await axios.get(`/api/ProjectCCreditDetail/${propOrProjId}`);
      this.setState({
        creditRequireForAd:res.data.creditRequireForAd ,
        creditRequireToLock:res.data.creditRequireToLock ,
      });
    }
      
     

  }
  
  handleRenewDealerSuggestedAd = async (value) => {
    
    if((Date.parse(value.creditExpiredOn) > Date.now()) && (value.balanceCreditProp > this.state.creditRequireForAd)){
     
      const values = {
        id:value.id,
        creditUsed:(value.adlocked === 'Yes') ? this.state.creditRequireToLock : this.state.creditRequireForAd,
        balanceCreditProp:value.balanceCreditProp, 
        expiredOn:value.expiredOn
      }
      try {
      
        const res = await axios.put(`/api/DealerSuggestedEditForRenew`, values);
    

        if (res.data.msg ==='success'){
        this.props.handleRefresh()
        this.props.handleModalClose()
        toastr.success('Ad Renew Successfully');
        }
        
      } catch (error) {
     
        toastr.error("Oops", "Something went wrong.Please Try Again CreateAd");
      }
    }else if (Date.parse(value.creditExpiredOn) < Date.now()){
      toastr.error("Your Credit Have Expired");
  
    }else{
      toastr.error("You Do Not Have Enough Credit To Create Ad");
  
    }
    
  };
  

  render() {
    
const {
  balanceCreditProp,
  //creditUsed,
}= this.props.valueRenew 


    //*************** */
    return (
      <div className = 'maindiv-768' >
        {/*  */}
<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > Extend Validity For 30 Days</span>
	</div>

	</div>

	<div className='card-attached border-radius-bottom border-sb pxy-10 mb-010'  >

{/*  */}
      <div >
          { (
          <div className='row'>
            <div className='side flex-value-B'>
            <b>Total Credit Available</b>
            </div>
            <div className='main flex-value-A'>
            <b>{balanceCreditProp}{' '}</b>{' '}
            </div>
  
          </div>
          )}
          </div>

      <div >
          {this.state.creditRequireForAd && (
          <div className='row'>
            <div className='side flex-value-B'>
            <b>Credit Required To Extend Validity</b>
            </div>
            <div className='main flex-value-A'>
            <b>{(this.props.valueRenew.adlocked === 'Yes') ? this.state.creditRequireToLock : this.state.creditRequireForAd}{' '}</b>{' '}
            </div>
  
          </div>
          )}
          </div>
      <br/>
          <div className = 'disflex-paginator'>
          <span
            className = 'btnNumber ml-002'
            onClick={()=> this.handleRenewDealerSuggestedAd (this.props.valueRenew)}
          >
           Extend Validity
          </span>
          
          <span
            className = 'btnNumber ml-002 bgColor-dodgerblue'
            onClick={this.props.handleModalClose}
          >
            cancel
          </span>
          </div>
        {/*  */}
        </div>
      </div>


    );
  }
}

export default RenewDealerSuggestedProjectAd
