import React, { Component } from "react";
import { connect } from "react-redux";
import {  withRouter } from "react-router-dom";
import {fetchCompany} from "../../actions/companysAction";
import { openModal } from "../../actions/modalAction";
import { openPopup } from "../../actions/popupAction";
import { shortlistAddRemoveDealer,viewCountChecker } from "../../actions/authAction";
import {createContactReq} from "../../actions/contactReqAction";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FinancerPropResiList from "../propertyResi/FinancerPropResiList";
import FinancerPropComList from "../propertyCom/FinancerPropComList";
import format from "date-fns/format";
import Loading from "../common/Loading"; 

import {
  Button,
  Divider,
  Modal,
  Icon,
  Image,
} from "semantic-ui-react";


 

class OtherCompanyDetail extends Component {
  state ={
    showContact:false,
    modalOpen: false,
    msg:'',
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchCompany(id);
    window.scroll(0,0)
  }

  handleOpenModal = (url) => {   
    this.props.openModal("ImageZoomModal" , {imageURL:url});
  };
  handleOpenPopup = (ph , col) => {
    const message= `${ph} Mobile Number Copied` 
    this.props.openPopup("CopyPhoneFixedPopup" , {message:message, color:col,});
  };
  //***** */
  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })
//*************************** */
contactReqCreateHandler=(id,name,contactR)=>{
  const values= {...contactR,dealer:id,dealerName:name,}
 
  if (!this.props.userA) {
    this.props.openModal('LoginModal')

  }else{
    this.props.createContactReq( values)
  }

}

shortlistAddRemoveHandler = (id) => {
  if (!this.props.userA) {
    this.props.openModal('LoginModal')

  }else{
    this.props.shortlistAddRemoveDealer(id);
  }

  };

  contactDetailHandler = () => {
    if (!this.props.userA) {
      this.props.openModal('LoginModal')
    }
      if((this.props.userA !== null) && (this.props.userA.viewCount===0 || this.props.userA.viewCount===40)){
        const values = {viewCount : this.props.userA.viewCount}
        this.props.viewCountChecker(values)
        this.setState({showContact:true})
      } 
      if( (1 <= this.props.userA.viewCount && this.props.userA.viewCount < 20 )){
        
        const values = {viewCount : this.props.userA.viewCount}
        this.props.viewCountChecker(values)
        this.setState({showContact:true})
      } 
      if(  this.props.userA.viewCount === 20 ){
        if((Date.parse(this.props.userA.firstViewDate)+(60*1000)) < Date.now()){
          const values = {viewCount : this.props.userA.viewCount}
        this.props.viewCountChecker(values)
        this.setState({showContact:true})
        }else{
          this.setState({modalOpen:true,msg:'today'})
        }

      } 
        
      if( (21 <= this.props.userA.viewCount && this.props.userA.viewCount < 25 )){
        
          const values = {viewCount : this.props.userA.viewCount}
          this.props.viewCountChecker(values)
          this.setState({showContact:true})
        } 

        if(  this.props.userA.viewCount === 25 ){
          if((Date.parse(this.props.userA.firstViewDate)+(1*24*60*60*1000)) < Date.now()){
            const values = {viewCount : this.props.userA.viewCount}
          this.props.viewCountChecker(values)
          this.setState({showContact:true})
          }else{
            this.setState({modalOpen:true,msg:'this week'}) 
          }
  
        } 
        
     

  };
//************************ */ 1 <= req.body.viewCount < 40
  render() {
    

    const rendertypeOfDealings = () => {
      return typeOfDealings.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} <span className='pipe'>|</span></b>
          </span>
        );
      });
    };
    const rendertypeOfPropertyDealsIn = () => {
      return typeOfPropertyDealsIn.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} <span className='pipe'>|</span></b>
          </span>
        );
      });
    };


    if (!this.props.company) {
      return (<Loading/>)
    }
    const {
      reraNo,
      cname,
      cmobile1,
      cmobile2,
      cemail,
      website,
      cimage,
      address,
      locality,
      city,
      state,
      pincode,
      operatingSince,
      nameCP1,
      mobileCP1,
      //emailCP1,
      designationCP1,
      photoCP1,
      nameCP2,
      mobileCP2,
     // emailCP2,
      designationCP2,
      photoCP2,
      typeOfDealings,
      typeOfPropertyDealsIn,
      locationOnMap,
      description,
      _id
    } = this.props.company;


    //***************************
    const {uname,
      uphone1,
      uphone2,
      uemail,
      uwhatsapp,
  
        }=this.props.userA !== null && this.props.userA
      const  requesterId= this.props.userA !== null && this.props.userA._id
    const contactReq= {
      requestFor:'General',
      uname,
    uphone1,
    uphone2,
    uemail,
    uwhatsapp,
    requesterId,
      }
  
      
  
    const renderContactReqButtonOrNot = () =>
          this.props.userA != null &&
          (this.props.userA.userTypeW ==='Dealer' ||
          this.props.userA.userTypeW ==='Builder') ? (
            <div></div>
  
          ) : (
        <Button
        // basic
        // inverted
        floated="right"
        color="blue"
        type="button"
        onClick={() => this.contactReqCreateHandler(_id,cname,contactReq)}
        >
          Ask Dealer To Contact You
         </Button>
      
  
          );
          //************************
  
    const checkID = this.props.userA !== null && this.props.userA.shortlistDealer !==undefined && this.props.userA.shortlistDealer.filter(val => {

      return val.toString() === _id;
    });
    const renderShortlistButton = () =>
          checkID.length > 0 ? (
            <span
            onClick={() => this.shortlistAddRemoveHandler(_id)}
            className="icon-button ml-010"
            >
            <img className= 'yimg ' src='/statusIconOk.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>
      ) : (
        <span
 onClick={() => this.shortlistAddRemoveHandler(_id)}
 className="icon-button ml-010"
 >
 <img className= 'yimg ' src='/shortlistIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
        
      );


      const renderViewContact = () =>{
        if(!this.state.showContact){
          return (
        <Button
        // basic
        // inverted
        floated="right"
        color="teal"
        type="button"
        onClick={() => this.contactDetailHandler()}
        >
          View Contact Details
         </Button>
      
          );
           }
           }
          //###########################

          const rendermobileCP1 = () => 
           this.state.showContact  ?  (
            <span >
            <span >
              {mobileCP1}
            </span>
            <span className='nodisplay-tab'>
               <CopyToClipboard text={mobileCP1}  >
               <span className="icon-button"  onClick= {() => this.handleOpenPopup(mobileCP1,'blue' )}>
            <img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>
              </CopyToClipboard>
              </span>
              </span>
              ): (
                <span > </span>
              )
              const rendermobileCP2 = () => 
                this.state.showContact ?  (
                  <span >
                  <span >
                    {mobileCP2}
                  </span>
                  <span className='nodisplay-tab'>
                     <CopyToClipboard text={mobileCP2}  > 
                     <span className="icon-button"  onClick= {() => this.handleOpenPopup(mobileCP2,'teal' )}>
                  <img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
                  </span>
                    </CopyToClipboard>
                    </span>
                    </span>
                   ): (
                     <span > </span>
                   )
         
                   const renderCompanyMobile = () => 
                    this.state.showContact ?  (
                     < React.Fragment>
                      <div >
              {cmobile1 && (
                <div className='row'>
                  <div className='side'>
                    <b>Phone</b>
                  </div>
                  <div className='main'>
                    <b>{cmobile1}{' '}</b>
                    {cmobile2 && (

                          <b>,{' '} {cmobile2}</b>

                      )}
                  </div>

                      </div>
                    )}
                  </div>

                  <div >
                  {cemail && (
                    <div className='row'>
                      <div className='side'>
                        <b>Email</b>
                      </div>
                      <div className='main'>
                        <b>{cemail}</b>
                      </div>

                    </div>
                  )}
                  </div>
                   </React.Fragment>
                       ): (
                         <div > </div>
                       )
  //******************
// const values= {
//   _id
// }
//**********************

    return (
      
      <div className='maindiv-768'>
{/* modal start */}
<div>
    <Modal
      dimmer='inverted'
      open={this.state.modalOpen} 
      onClose={this.handleClose} 
      size='tiny'
      
    >
        <Modal.Content >
<div>
  <div>
<span><img className= 'yimg ' src='/alertSign.jpg' alt=" " style={{width:"50px", height:"50px",borderRadius:'50px',verticalAlign:'middle'}} /></span>
{' '}<span className='alert' >Contact Details has not been Shown.</span>
</div>
<br/>
<div>
  <b>
You have already viewed the maximum Contact Details allowed for {this.state.msg}.
Please contact feedback@findprop.in if there are any issues or in case it is
an error.</b>
</div>
<div>
  <b>
Thank you. 
</b>
</div>
</div>
      
      </Modal.Content>
      <Modal.Actions>


        <Button color='blue' onClick={this.handleClose} >
          <Icon name='close' />Ok 
        </Button>

      </Modal.Actions>
    </Modal>
    </div>
    {/* modal end */}


          {/* start */}

      
        
      <div   onContextMenu ={ (e) => {e.preventDefault()  }} >
                
                {/*  */}
      <div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
      <div className='heading-2'>
      <span  > {cname}</span>
      </div>
      </div>
                {/*  */}




            <div className='card-attached border-sb pxy-20'>

{/*  */}
<div className='media-600 ' style={{display:'flex',justifyContent:'center'}}> {/*start of div containing cp1 and cp2 detail */}

{ nameCP1 &&  (<div className='card-cp' > {/* cp1 detail*/}
  <div className='flex-value-DA' style={{display:'flex',flexDirection:'column'}}>
  <img 
  className="icon-button"
  onClick={() => this.handleOpenModal(photoCP1)}
  src={`${photoCP1}`} alt="Photo_First contact person" 
  style={{width:"100px", height:"100px",padding:'5px'}} />
  </div>


<div className='flex-value-DB 'style={{padding:'10px',fontWeight:"bold"}}>


<div>{nameCP1 && (
<div >
  {nameCP1}
</div>
)}
</div>  {/* */}

<div>{designationCP1 && (
<div>{designationCP1}</div>
)}</div>  {/* */}

<div>{mobileCP1 && (
rendermobileCP1()
)}</div>  {/* */}

  </div>
  </div>)}
{/*end cp1 details */}

  { nameCP2 &&  (<div className='card-cp' >{/* cp2 detail*/}
  <div className='flex-value-DA' style={{display:'flex',flexDirection:'column'}}>
  <img
  className="icon-button"
  onClick={() => this.handleOpenModal(photoCP2)}
  src={`${photoCP2}`} alt="Photo_Second contact person" 
  style={{width:"100px", height:"100px",padding:'5px'}} />
  </div>


<div className='flex-value-DB 'style={{padding:'10px',fontWeight:"bold"}}>


<div>{nameCP2 && (
<div >
  {nameCP2}
</div>
)}
</div>  {/* */}
<div>{designationCP2 && (
<div>{designationCP2}</div>
)}</div>  {/* */}
<div>{mobileCP2 && (
rendermobileCP2()
)}</div>  {/* */}

  </div>
  </div>)}

</div> 

    {/*end of div containing cp1 and cp2 detail */}
    

              <br />
             {/*  */}
		
			<div className='display-flex'  style= {{ marginBottom:'20px'}} >
			
      {renderContactReqButtonOrNot()}
      {renderViewContact()}
      { renderShortlistButton()}
      
			</div>
		
		{/*  */}
              <div>
              
              <Divider fitted />
                <div >
                {address && (
                  <div className='row'>
                    <div className='side'>
                    <Image
                  floated="left"
                  size="tiny"
                  src={`${cimage}`}
                />
                    </div>
                    <div className='main'style={{ display:'flex',alignItems:'center'}} >
                      <b>{address}{' '}{locality}{' '}{city}{' '}{state}{' '}{pincode}
                     {' '} <span>
                  <a target='_blank' rel="noreferrer noopener"  href={`${locationOnMap}`}>
                  <span className="ml-05">
                  {' '} <img className= 'yimg' src='/mapIcon.png' alt="Location on Map" style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
                  </span>
                  </a>
          </span>

          </b>
                    </div>

                  </div>
                )}
              </div>
              {renderCompanyMobile()}

            
          <div >
          {website && (
            <div className='row'>
              <div className='side'>
                <b>Website</b>
              </div>
              <div className='main'>
              <b>{website}</b>
              
               {/* <a  href={website} target='_blank'><b>{website}</b></a> */}
              </div>
             
            </div>
          )}
        </div>

        <div >
        {reraNo && (
        <div className='row'>
        <div className='side'>
        <b>Company's RERA No.</b>
        </div>
        <div className='main'>
        <b>{reraNo}</b>
        </div>

        </div>
        )}
        </div>

        <div >
        {operatingSince && (
          <div className='row'>
            <div className='side'>
              <b>Operating Since</b>
            </div>
            <div className='main'>
              <b>{format(new Date(operatingSince), "dd-MM-yyyy")}</b>
            </div>

          </div>
        )}
      </div>



              <div >
               {typeOfDealings != null &&
                            typeOfDealings.length >= 1 && (
                <div className='row'>
                  <div className='side'>
                    <b>Type of Dealings</b>
                  </div>
                  <div className='main'>
                    {rendertypeOfDealings()}
                  </div>

                </div>
              )}
             </div>
             <div >
             {typeOfPropertyDealsIn != null &&
                          typeOfPropertyDealsIn.length >= 1 && (
                <div className='row'>
                  <div className='side'>
                    <b>Type Of Property deals in</b>
                  </div>
                  <div className='main'>
                     {rendertypeOfPropertyDealsIn()}
                  </div>

                </div>
              )}
             </div>

             <div >
             {description && (
               <div className='row'>
                 <div className='side'>
                   <b>About Company</b>
                 </div>
                 <div className='main'>
                   <b>{description}</b>
                 </div>

               </div>
             )}
             </div>


              </div>

              
              </div>
              
              <div className='card-attached border-sb border-db-bottom '  >
              <FinancerPropResiList id={_id}  />
              <FinancerPropComList id={_id}  />
      
              </div>

          </div>
        



        
      
      {/* end */}
      
<br/><br/><br/><br/>
{/* closing first div */}
      </div> 

    );
  }
}

function mapStateToProps(state) {
  
  return { 
  company: state.companys.company ,
  userA :state.auth.userA
  };
}

export default connect(
  mapStateToProps,
  {fetchCompany, shortlistAddRemoveDealer,createContactReq,viewCountChecker,openPopup, openModal}
)(withRouter(OtherCompanyDetail));
