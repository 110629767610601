import React, { Component } from "react";
import { connect } from "react-redux";

import { Switch, Route, } from "react-router-dom";
import {showBackButton, getUserForAuth,logoutUser } from "../../actions/authAction";

import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";


import ContactReqForDealerList from "./ContactReqForDealerList";
import ContactReqForBuilderList from "./ContactReqForBuilderList";
import ContactReqByPartyList from "./ContactReqByPartyList";
import AdByDealerList from "./AdByDealerList";
import AdByFinancerList from "./AdByFinancerList";
import CreditDetailList from "./CreditDetailList";

import Layout from "../layout/Layout";

class ActivityDashboard extends Component {
  componentDidMount() {
    if(this.props.auth.isAuthenticated){
      this.props.getUserForAuth();
    }

    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
    }

  }
  
  onLogoutClick = () => {
    this.props.logoutUser();
  };

  render() {
    return (
    <div className="mt-10">
    <div className='maindiv'>

    <Layout onLogoutClick={this.onLogoutClick}>
    <Switch>
    
    <Route
      exact
      path="/activity/ContactReqForDealerList"
      component={ContactReqForDealerList}
    />
    <Route
      exact
      path="/activity/ContactReqForBuilderList"
      component={ContactReqForBuilderList}
    />
        <Route
      exact
      path="/activity/ContactReqByPartyList"
      component={ContactReqByPartyList}
    />
    <Route
      exact
      path="/activity/CreditDetailList"
      component={CreditDetailList}
    />
    <Route
      exact
      path="/activity/AdByDealerList"
      component={AdByDealerList}
    />
    <Route
      exact
      path="/activity/AdByFinancerList"
      component={AdByFinancerList}
    />
      

    </Switch>
        </Layout >
        </div>

    <div >
        <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

      <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
    </div>

    </div>
      );


}
}
const mapStateToProps = state => ({
auth: state.auth
});


export default connect(
   mapStateToProps,
  
  {showBackButton, getUserForAuth, logoutUser  }
)(ActivityDashboard);
