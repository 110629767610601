import React, { Component } from "react";

import { connect } from "react-redux";
import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading";
import {searchBuilderFloorSales} from "../../actions/propresiAction";
import { fetchCompanysForPropListRSBF } from "../../actions/staticAction";
import { openModal } from "../../actions/modalAction";

import {
  Icon,
} from "semantic-ui-react";
import DealerSuggestedStatic from '../dealerSuggesteds/DealerSuggestedStatic';
import PropResiSummary from './PropResiSummary';



class BuilderFloorSaleSearchList extends Component {
  state = {
    pageO:'',
    id:'',
  };
  componentDidMount() {
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
   
      const {
        offsetRSBF,
        limitRSBF,
        offsetRSBFF,
        limitRSBFF,
        limitStaticRSBF,
        offsetStaticRSBF,
        offsetStaticDealerRSBF ,
        limitStaticDealerRSBF,
    } = this.props;
      
    this.props.searchBuilderFloorSales(values ,limitRSBF, offsetRSBF ,limitRSBFF, offsetRSBFF,limitStaticRSBF,offsetStaticRSBF);
    this.props.fetchCompanysForPropListRSBF(limitStaticDealerRSBF,offsetStaticDealerRSBF )
  }
  handleSearchBuilderFloorSale = () => {
    this.props.openModal("SearchBuilderFloorSaleModal");
  };

  

  //^^^^^^^^^^^^^^^^^^^^^^^^^
    onChange=(e) => {
      this.setState({ [e.target.name]: e.target.value });
    }
  //*******************************************************

  
  //###########################################

  back = () => {
    
      const {
        offsetRSBF,
        limitRSBF,
        offsetRSBFF,
        limitRSBFF,
        limitStaticRSBF,
        offsetStaticRSBF,
        offsetStaticDealerRSBF ,
        limitStaticDealerRSBF,
    } = this.props;
      
 
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
    if (offsetRSBF === 0) {
      return;
    }

    this.props.searchBuilderFloorSales(values, limitRSBF, (offsetRSBF - limitRSBF),  limitRSBFF, (offsetRSBFF - limitRSBFF), limitStaticRSBF, (offsetStaticRSBF - limitStaticRSBF));
    this.props.fetchCompanysForPropListRSBF( limitStaticDealerRSBF, (offsetStaticDealerRSBF - limitStaticDealerRSBF));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      countRSBF,
      offsetRSBF,
      limitRSBF,
      offsetRSBFF,
      limitRSBFF,
      limitStaticRSBF,
      offsetStaticRSBF,
      offsetStaticDealerRSBF ,
      limitStaticDealerRSBF,
  } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) ||{localityA:[]};
    if (offsetRSBF + limitRSBF > countRSBF) {
      return;
    }

    this.props.searchBuilderFloorSales(values, limitRSBF, (offsetRSBF + limitRSBF),  limitRSBFF, (offsetRSBFF + limitRSBFF), limitStaticRSBF, (offsetStaticRSBF + limitStaticRSBF));
    this.props.fetchCompanysForPropListRSBF( limitStaticDealerRSBF, (offsetStaticDealerRSBF + limitStaticDealerRSBF));
    window.scroll(0,0)
  };
//***************** */
handleGoToPage = () => {
  const {
    countRSBF,
    limitRSBF,
    limitRSBFF,
    limitStaticRSBF,
    limitStaticDealerRSBF,
} = this.props;
  const values =
    (this.props.form !== undefined && this.props.form.values) ||{localityA:[]};
   // use Math.abs for strict equality ===  to work and safe guard against negative value from user
   if (Math.abs(this.state.pageO) === 0) {
    return; 
  }

const page=(Math.abs(this.state.pageO*limitRSBF) > countRSBF)?(Math.ceil(countRSBF/limitRSBF-1)):(Math.abs(this.state.pageO)-1)


  //const page = (this.state.pageO-1)
    this.props.searchBuilderFloorSales(values, limitRSBF, (page * limitRSBF),  limitRSBFF, (page * limitRSBFF),  limitStaticRSBF, (page * limitStaticRSBF));
    this.props.fetchCompanysForPropListRSBF( limitStaticDealerRSBF, (page * limitStaticDealerRSBF));
    window.scroll(0,0)

};

//****************** */
  renderPaginator=()=> {
    if (this.props.propresisRSBF.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offsetRSBF}
          limit={this.props.limitRSBF}
          count={this.props.countRSBF}
        />
      );
    }
  }

  renderLoading=()=> {
    if (this.props.fetchingRSBF===true) {
      return (<Loading/>)
    }
  }



  render() {
  
    return (  

    <div className='maindiv-820 '>
      
      
      <div> {/* main column start */}
      
      <div className='card-attached border-radius-top border-sb' style={{textAlign:'center',backgroundColor:'dodgerblue'}} >
        
      {/**/}
      <div style={{display:'flex',color:'white',padding:'5px'}}>
      
      <div
      style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
      onClick={this.handleSearchBuilderFloorSale}>
            <span className='icon-button2 border1' > <Icon name='search' /></span >
          </div>
      
      
      
      <div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
      <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
      {this.props.countRSBF} Residential Property For Sale in {this.props.city}
      </div>
      </div>
      
      
      </div>
      {/**/}
      
        </div>
        {/*  */}
      
          
        <div className='card-attached border-sb pxy-05'  >
      
      {this.renderLoading()}
      
      {this.props.staticTop1.map((item)=> (<PropResiSummary   key={item._id}   propresi={item} nameClass={'static'}/>))}
      {this.props.staticTop2.map((item)=> (<PropResiSummary   key={item._id}   propresi={item} nameClass={'static'}/>))}
      
      {this.props.propresisRSBFF.map((item)=> (<PropResiSummary   key={item._id}   propresi={item}/>))}
      {this.props.propresisRSBF.map((item)=> (<PropResiSummary   key={item._id}   propresi={item}/>))}
      
      {this.props.staticBottom1.map((item)=> (<PropResiSummary   key={item._id}   propresi={item} nameClass={'static'}/>))}
      {this.props.staticBottom2.map((item)=> (<PropResiSummary   key={item._id}   propresi={item} nameClass={'static'}/>))}
      
      
      </div>
      
      
      
      {/* static dealers ad */}
      <div className='card-attached border-sb '  >
      {this.props.staticsDealer && this.props.staticsDealer.length >0 && (
      
      <div  style={{padding:'10px',lineHeight:'24px', fontSize:'16px',textAlign:'center',backgroundColor:'dodgerblue',color:'white'}} >
      
      <div ><b>Dealers For Assistance And Consultancy To Buy/Sell/Rent In All Sectors Of Dwarka, Delhi</b></div>
      
      </div>
      )}
      
      
      
      <div className='disflex-DLMP media-600'
      
      //style={{flexDirection:'column',justifyContent:'center',alignItems:'center'}}
      >
      {this.props.staticsDealer.map((item)=>(<DealerSuggestedStatic company={item}/>))}
      </div>
      
      </div>
      {/*  */}
      
      <div className='card-attached border-sb '  >
      
      <div className='disflex-paginator card-paginator media-600'>
      {this.renderPaginator()}
      <div className="disflex-paginator  " >
        <div className= 'font-paginator'>Go To Page</div>
        <form>
              <input
              className='inputPage'
                placeholder="number"
                name="pageO"
                type="number"
                value={this.state.pageO}
                onChange={this.onChange}
              />
              <span  className="btnNumber"  onClick={this.handleGoToPage} >
                Submit
              </span>
            </form>
      
              
      </div>
      <br/>
      </div>
      
      </div>
      {/*  */}
      
      
      <br/><br/><br/><br/>
      
      </div> {/* main column end */}
      {/* side column */}
      
      {/* side column end */}
      {/* side column display at bottom in mobile start */}
      
      {/* side column mobile end */}
      
      
      </div>
      
      
      
          );
    //@@@@@@
    
  }
}


const mapStateToProps = ({  propresis,auth, form,statics, }) => {
  const {city, fetchingRSBF,limitRSBF, offsetRSBF, countRSBF,limitRSBFF, offsetRSBFF, } = propresis
 
  const { limitStaticRSBF, offsetStaticRSBF, countStaticRSBF,limitStaticDealerRSBF, offsetStaticDealerRSBF } = statics

  return {
    city,
    fetchingRSBF,
    limitRSBF,
    offsetRSBF,
    countRSBF,
    limitRSBFF,
    offsetRSBFF,
    limitStaticRSBF,
    offsetStaticRSBF,
    countStaticRSBF,
    form: form.PropResiSaleSearch,
    propresisRSBF: propresis.propresisRSBF,
    propresisRSBFF: propresis.propresisRSBFF,
    staticTop1: statics.staticTop1,
    staticTop2: statics.staticTop2,
    staticBottom1: statics.staticBottom1,
    staticBottom2: statics.staticBottom2,
    userA: auth.userA,
    limitStaticDealerRSBF,
    offsetStaticDealerRSBF,
    staticsDealer: statics.staticsDealer
  };
};

export default connect(
  mapStateToProps,
 {searchBuilderFloorSales,openModal, fetchCompanysForPropListRSBF,}
)(BuilderFloorSaleSearchList);
