import React, { Component } from "react";
import { Link, } from "react-router-dom";
import { connect } from "react-redux";
import writtenNumber from "written-number";
import format from "date-fns/format";
import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading"; 
import {searchReqComsUnlocked} from "../../actions/reqcomAction";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { getUserForAuth } from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";
import { openPopup } from "../../actions/popupAction";
import {
  Icon,
  Button,
} from "semantic-ui-react";



class ReqComSearchList extends Component {
  state = {
    pageO:'',
      open: false,
      result: "show the modal to capture a result"
    };
  componentDidMount() {
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
      
      const { offsetULock,limit} = this.props.reqcoms;
      
    
    if (this.props.auth.isAuthenticated) {
     
      this.props.searchReqComsUnlocked(values, limit,offsetULock);
    }else{
      
      this.props.openModal('LoginModal')
    }
  }
  handleSearchReqCom = () => {
    this.props.openModal("SearchReqComUnlockedModal");
  };

  handleOpenPopup = (ph , col) => {
    const message= `${ph} Mobile Number Copied` 
    this.props.openPopup("CopyPhoneFixedPopup" , {message:message, color:col,});
  };
  //***** */

  capitalizeFirst=(input)=> {
  var words = input.split(' ');
  var CapitalizedWords = [];
  words.forEach(element => {
  CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
  });
  return CapitalizedWords.join(' ');
  }
  //^^^^^^^^^^^^^^^^^^^^^^^^^
  onChange=(e) => {
  this.setState({ [e.target.name]: e.target.value });
  }
  //*******************************************************
  renderList=(reqcom)=> {
  const {
  name,
  phone1,
  phone2,
  email,
  whatsapp,
  _id,
  // creditUsed,
  // veiwLimit,
  // dealers,
  requiredFor,
  propertyType,
  furnishedStatus,
  projectName,
  locality,
  budgetMin,
  budgetMax,
  areaCarpetMin,
  areaCarpetMax,
  createdAt,
  } = reqcom;

  
  const renderpropertyType = () => {
  return propertyType.map(item => {
  return (
  <span key={item}>
  {" "}
  <b>{item} |</b>
  </span>
  );
  });
  };

  const renderfurnishedStatus = () => {
  return furnishedStatus.map(item => {
  return (
  <span key={item}>
  {" "}
  <b>{item} |</b>
  </span>
  );
  });
  };
  const renderprojectName = () => {
  return projectName.map(item => {
  return (
  <span key={item}>
  {" "}
  <b>{item} |</b>
  </span>
  );
  });
  };
  const renderlocality = () => {
  return locality.map(item => {
  return (
  <span key={item}>
  {" "}
  <b>{item} |</b>
  </span>
  );
  });
  };



  //**************************
  // const daysAgo = Math.round((Date.now()-Date.parse(createdAt))/(24*60*60*1000));

  return (
  <div key={_id}>
    {/*  */}
<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > Requirement For Commercial Property For {requiredFor}</span>
	</div>

	</div>

	<div className='card-attached border-radius-bottom border-sb pxy-20 mb-010'  >

  		{/*  */}
  


  <div style={{padding:'10px',}} >


  <div >
  {name && (
  <div className='row'>
  <div className='side'>
  <b>Name</b>
  </div>
  <div className='main'>
  <b>{name}</b>
  </div>

  </div>
  )}
  </div>
  <div >
  {phone1 && (
  <div className='row'>
  <div className='side'>
  <b>Phone1</b>
  </div>
  <div className='main'>
  <b>{phone1}</b>{' '}
      <span className='nodisplay-tab'>
      <CopyToClipboard text={phone1}  >
      <span className="icon-button"  onClick= {() => this.handleOpenPopup(phone1,'blue' )}>
      <img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
      </span>
      </CopyToClipboard>
      </span>
  </div>

  </div>
  )}
  </div>

  <div >
  {phone2 && (
  <div className='row'>
  <div className='side'>
  <b>Phone2</b>   
  </div>
  <div className='main'>
  <b>{phone2}</b>{' '}
    <span className='nodisplay-tab'>
    <CopyToClipboard text={phone2}  >
    <span className="icon-button"  onClick= {() => this.handleOpenPopup(phone2,'teal' )}>
    <img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
    </span>
    </CopyToClipboard>
    </span>
  </div>

  </div>
  )}
  </div>

  <div >
  {email && (
  <div className='row'>
  <div className='side'>
  <b>Email</b>
  </div>
  <div className='main'>
  <b>{email}</b>
  </div>

  </div>
  )}
  </div>

  <div >
  {whatsapp && (
  <div className='row'>
  <div className='side'>
  <b>WhatsApp ID</b>
  </div>
  <div className='main'>
  <b>{whatsapp}</b>
  </div>

  </div>
  )}
  </div>


  <div >
  {requiredFor && (
  <div className='row'>
  <div className='side'>
  <b>Require For</b>
  </div>
  <div className='main'>
  <b>{requiredFor}</b>
  </div>

  </div>
  )}
  </div>

  <div >
  {propertyType != null &&
  propertyType.length > 0 && (
  <div className='row'>
  <div className='side'>
  <b>Type Of Property</b>
  </div>
  <div className='main'>
  {renderpropertyType()}
  </div>

  </div>
  )}
  </div>

  <div >
  {furnishedStatus != null &&
  furnishedStatus.length > 0 && (
  <div className='row'>
  <div className='side'>
  <b>Furnished Status</b>
  </div>
  <div className='main'>
  {renderfurnishedStatus()}
  </div>

  </div>
  )}
  </div>


  <div >
  {budgetMin > 0 && (
  <div className='row'>
  <div className='side'>
  <b>Minimum Budget</b>
  </div>
  <div className='main'>
  <Icon className="rupee" ></Icon>
  <b> {budgetMin}</b>
  <b>
  {' '} ({this.capitalizeFirst(writtenNumber(budgetMin, {
  lang: "enIndian"
  }))}
  ){" "}
  </b>
  </div>

  </div>
  )}
  </div>

  <div >
  {budgetMax > 0 && (
  <div className='row'>
  <div className='side'>
  <b>Maximum Budget</b>
  </div>
  <div className='main'>
  <Icon className="rupee" ></Icon>
  <b> {budgetMax}</b>
  <b>
  {' '} ({this.capitalizeFirst(writtenNumber(budgetMax, {
  lang: "enIndian"
  }))}
  ){" "}
  </b>
  </div>

  </div>
  )}
  </div>
  <div >
  {areaCarpetMin > 0 && (
  <div className='row'>
  <div className='side'>
  <b>Minimum Carpet Area</b>
  </div>
  <div className='main'>

  <b> {areaCarpetMin}</b>
  <b>
  {' '} ({this.capitalizeFirst(writtenNumber(areaCarpetMin, {
  lang: "enIndian"
  }))}
  ){" "}sq ft
  </b>
  </div>

  </div>
  )}
  </div>

  <div >
  {areaCarpetMax > 0 && (
  <div className='row'>
  <div className='side'>
  <b>Maximum Carpet Area</b>
  </div>
  <div className='main'>

  <b> {areaCarpetMax}</b>
  <b>
  {' '} ({this.capitalizeFirst(writtenNumber(areaCarpetMax, {
  lang: "enIndian"
  }))}
  ){" "}sq ft
  </b>
  </div>

  </div>
  )}
  </div>

  <div >
  {projectName  != null &&
  projectName.length > 0 && (
  <div className='row'>
  <div className='side'>
  <b>Complex/Project Preferred</b>
  </div>
  <div className='main'>
  {renderprojectName ()}
  </div>

  </div>
  )}
  </div>

  <div >
  {locality != null &&
  locality.length > 0 && (
  <div className='row'>
  <div className='side'>
  <b>Locality</b>
  </div>
  <div className='main'>
  {renderlocality()}
  </div>
  </div>
  )}
  </div>


  <div >
  {createdAt && (
  <div className='row'>
  <div className='side'>
  <b>Created On</b>
  </div>
  <div className='main'>

  <b>{format(new Date(createdAt), "dd-MM-yyyy")}</b>
  </div>

  </div>
  )}
  </div>
  {/**/}
  <div  >

  <React.Fragment>
  <div className='nodisplay'><Button
  // basic
  // inverted
  floated="right"
  color="teal"
  type="button"
  as={Link}
  to={`/reqcom/ReqComDetail/${_id}`}
  >
  Detail
  </Button> </div>
  <div className='nodisplay-tab'><Button
  // basic
  // inverted
  floated="right"
  color="teal"
  type="button"
  as={Link}
  to={`/reqcom/ReqComDetail/${_id}`}
  >
  Detail
  </Button> </div>
  </React.Fragment>


  </div>
  <br/>
  {/**/}
  </div >{/* end  3rd div */}

  </div>
  </div>

  );
  }
  back = () => {
  const {
  offsetULock,
  limit

  } = this.props;
  const values =
  (this.props.form !== undefined && this.props.form.values) || {};
  if (offsetULock === 0) {
  return;
  }

  this.props.searchReqComsUnlocked(values, limit, (offsetULock - limit));
  window.scroll(0,0)
  };

  advance = () => {
  const {
  offsetULock,
  limit,
  countULock

  } = this.props;

  const values =
  (this.props.form !== undefined && this.props.form.values) || {};
  if (offsetULock + limit > countULock) {
  return;
  }

  this.props.searchReqComsUnlocked(values, limit, (offsetULock + limit));
  window.scroll(0,0)
  };
  //***************** */
  handleGoToPage = () => {
  const {

  limit,
  countULock

  } = this.props;

  const values =
  (this.props.form !== undefined && this.props.form.values) || {};
  // use Math.abs for strict equality ===  to work and safe guard against negative value from user
  if (Math.abs(this.state.pageO) === 0) {
  return; 
  }

  const page=(Math.abs(this.state.pageO*limit) > countULock)?(Math.ceil(countULock/limit-1)):(Math.abs(this.state.pageO)-1)


  this.props.searchReqComsUnlocked(values, limit, (page * limit));
  window.scroll(0,0)


  };

  //****************** */
  renderPaginator=()=> {
  if (this.props.reqcomsULock.length) {
  return (
  <Paginator
  advance={this.advance}
  back={this.back}
  offset={this.props.offsetULock}
  limit={this.props.limit}
  count={this.props.countULock}
  />
  );
  }
  }

  renderLoading() {
  if (this.props.fetching===true) {
    return (<Loading/>)
  }
  }


  render() {
  return (
  <div className='maindiv-820'>
  <div>

  <div>

  
  
{/**/}
<div className='cashbook-H box-shadow'>

<div
style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
onClick={this.handleSearchReqCom}>
      <span className='icon-button2 border1' > <Icon name='search' /></span >
    </div>



<div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
{this.props.countULock} Unlocked Requirement For Commercial Property in {this.props.city}
</div>
</div>


</div>
{/**/}

  {this.renderLoading()}

  {this.props.reqcomsULock.map(this.renderList)}

  {/*  */}
<div className='disflex-paginator card-paginator media-600'>
{this.renderPaginator()}
<div className="disflex-paginator  " >
  <div className= 'font-paginator'>Go To Page</div>
  <form>
        <input
        className='inputPage'
          placeholder="number"
          name="pageO"
          type="number"
          value={this.state.pageO}
          onChange={this.onChange}
        />
        <span  className="btnNumber"  onClick={this.handleGoToPage} >
          Submit
        </span>
      </form>

        
</div>
<br/>
</div>
{/*  */}

  <br/><br/><br/><br/>

  </div>
  


  </div>
  </div>


  );
  }
  }


  const mapStateToProps = ({ reqcoms, selection, form,auth }) => {
  const {city, fetching,limit, offsetULock, countULock } = reqcoms;

  return {
    city,
  reqcoms,
  auth,
  fetching,
  limit,
  offsetULock,
  countULock,
  form: form.ReqComSearch,
  selection,
  reqcomsULock: reqcoms.reqcomsULock,
  userA: auth.userA,
  };
  };
  export default connect(
  mapStateToProps,
  {getUserForAuth,searchReqComsUnlocked,openModal,openPopup}
  )(ReqComSearchList);
