import React, { Component } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import {
  Button,
} from "semantic-ui-react";

class BookStaticAd extends Component {

  
  handleBookStaticAd = async (values) => { 
    

    if(values.balanceCreditProp > values.creditUsed){
      const id=values.id;
      const balanceCreditProp=Number(values.balanceCreditProp)-Number(values.creditUsed);

      try {
      
        const res = await axios.put(`/api/AddDealerToStaticAd`, {id:id,balanceCreditProp:balanceCreditProp});
    
        if (res.data.msg === 'errorAlready') { 
        toastr.error('Already Booked');
        }

        if (res.data.msg ==='success'){
        this.props.handleRefresh()
        this.props.handleModalClose()
        toastr.success('Booked Successfully');
        }
        
      } catch (error) {
        
        toastr.error("Oops", "Something went wrong.Please Try Again CreateAd");
      }
    }else{
      toastr.error("You Do Not Have Enough Credit To Book Static Ad");
  
    }
    
  };
  

  render() {
    
const {
  balanceCreditProp,
  creditUsed,
} = this.props.valueToBook 



    //*************** */
    return (
      <div className = 'maindiv-768' >
        {/*  */}
<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > Book Static Ad</span>
	</div>

	</div>

	<div className='card-attached border-radius-bottom border-sb pxy-10 mb-010'  >

  		{/*  */}
      
      <div >
  {balanceCreditProp >=0 && (
  <div className='row'>
  <div className='side flex-value-B'>
  <b>Total Credit Available</b>
  </div>
  <div className='main flex-value-A'>
  <b>{balanceCreditProp}{' '}</b>{' '}
  </div>

  </div>
  )}
  </div>

  <div >
  {creditUsed >=0 && (
  <div className='row'>
  <div className='side flex-value-B'>
  <b>Credit Will Be Used</b>
  </div>
  <div className='main flex-value-A'>
  <b>{creditUsed}{' '}</b>{' '}
  </div>

  </div>
  )}
  </div>
  <br/>
  <div className = 'disflex-paginator'>
  <Button
  //  floated="right"
  color="orange"
  onClick={()=> this.handleBookStaticAd (this.props.valueToBook)}

  >
  Book Static Ad
  </Button>
  <Button
  //  floated="right"
  color="blue"
  onClick={this.props.handleModalClose}
  >
  cancel
  </Button>
  </div>
        {/*  */}
        </div>
      </div>

    );
  }
}

export default BookStaticAd
