import React, { Component } from 'react';
import { Link, } from "react-router-dom";
import { connect } from "react-redux";
import { Menu,  Button, 
  Icon,
  Sidebar} from "semantic-ui-react";
  
  import {getUserForAuth, logoutUser,hideSidebar } from "../../actions/authAction";
  import { openModal } from "../../actions/modalAction";


class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {menuVisible: false,modalOpenL: false };
  }

  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      if(this.props.auth.isAuthenticated) {this.props.getUserForAuth();}
      //window.scroll(0,0)
    }
     }

onLogoutClick = () => {
  this.props.logoutUser();
};
onSignInClick = () => {
  this.props.openModal('LoginModal');
};

  handleSidebarHide = () => this.setState({ menuVisible: false })

 //#####################################
 handleSearchPropResiSaleFH = () => {
  this.props.openModal("SearchPropResiSaleModalFH");
};
handleSearchPropResiRentFH = () => {
  this.props.openModal("SearchPropResiRentModalFH");
};
handleSearchPropComSaleFH = () => {
  this.props.openModal("SearchPropComSaleModalFH");
};
handleSearchPropComRentFH = () => {
  this.props.openModal("SearchPropComRentModalFH");
};
handleSearchProjectRFH = () => {
  this.props.openModal("SearchProjectRModalFH");
};
handleSearchProjectCFH = () => {
  this.props.openModal("SearchProjectCModalFH");
};
handleSearchCompanyiesFH = () => {
  this.props.openModal("SearchCompanyModalFH");
};
handleSearchRequireComFH = () => {
  this.props.openModal("SearchReqComModalFH");
};
handleSearchRequireResiFH = () => {
  this.props.openModal("SearchReqResiModalFH");
};

//######################################################



    render () {
    const { isAuthenticated,  } = this.props.auth;
		const renderLoginLogoutButton=()=>{
      return isAuthenticated ? (

        <Button onClick={this.onLogoutClick}
        color='red'
        size="mini"
        style={{maxWidth:'105px',padding:'7px',fontSize:'16px'}}
        ><Icon name="power" />Sign Out</Button>
      ) : (
        <Button
        onClick={this.onSignInClick}
        size="mini"
        color='teal'
        style={{maxWidth:'105px',padding:'7px',fontSize:'16px'}}
        >
        <Icon name="power" />
        Sign In
        </Button>
        )}

        //@@@ 
        const renderRegisterButton=()=>{
          return  (
            <Button 
            as={Link}
            to={`/register`}
            color='blue'
            size="mini"
            style={{maxWidth:'150px',padding:'7px',fontSize:'18px'}}
            >
              <Icon name="signup" />
            Register
            </Button>
            ) 
          }
          //*** */
        const linkHeadClass = 'linkSidebar-head linkSidebar-head-padding'
        const linkClass = 'linkSidebar textAlign-center'
        
        //########################################
        const userA=this.props.userA !== null && this.props.userA 
        const renderProfileActivityLink=() => {
          
          switch (userA.userTypeW) {
        case 'Individual':
          return (<div>
            
            <div className={linkClass}>
          <Link to={`/company/UserDetail/${userA._id}`} >
          <b>User Details</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={`/passchange`} >
          <Icon name='settings' />
          <b>Change Password</b>
        </Link>
          </div>
          {/* <div className={linkHeadClass} style={{backgroundColor:'lightgrey'}}>
            REQUESTS</div>
          <div className={linkClass}>
          <Link to={'/activity/ContactReqByPartyList'} >
          <b>Contact Requests Sent</b>
        </Link>
          </div> */}

          <div className={linkHeadClass} style={{backgroundColor:'lightgrey'}}>
            SHORTLISTS</div>

            <div className={linkClass}>
          <Link to={'/company/ShortlistCompany'} >
          <b>Shortlist Dealers</b>
        </Link>
          </div>
            <div className={linkClass}>
          <Link to={'/projectR/ShortlistProject'} >
          <b>Shortlist Projects</b>
        </Link>
          </div>
          
          <div className={linkClass}>
          <Link to={'/propresi/ShortlistResi'} >
          <b>Shortlist Residential Properties</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/propcom/ShortlistCom'} >
          <b>Shortlist Commercial Properties</b>
        </Link>
          </div>

          <div className={linkHeadClass} style={{backgroundColor:'lightgrey'}}>
            PROPERTIES</div>
          <div className={linkClass}>
          <Link to={'/propresi/MyPropResiList'} >
          <b>My Residential Properties</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/propcom/MyPropComList'} >
          <b>My Commercial Properties</b>
        </Link>
          </div>

          
          <div className={linkHeadClass} style={{backgroundColor:'lightgrey'}}>
            REQUIREMENTS</div>
          <div className={linkClass}>
          <Link to={'/reqresi/MyReqResiList'} >
          <b>My Requirement For Residential Properties</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/reqcom/MyReqComList'} >
          <b>My Requirement For Commercial Properties</b>
        </Link>
          </div>
          </div>)
          
          
        case 'Dealer':
          return (<div>
            
            <div className={linkClass}>
          <Link to={'/activity/CreditDetailList'} >
          <b>Credit Detail</b>
        </Link>
          </div>
            <div className={linkClass}>
          <Link to={`/company/UserDetail/${userA._id}`} >
          <b>User Details</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/company/mycompany'} >
          <b>Company Details</b>
        </Link>
          </div>

          
          <div className={linkClass}>
          <Link to={`/passchange`} >
          <Icon name='settings' />
          <b>Change Password</b>
        </Link>
          </div>

          <div className={linkHeadClass} style={{backgroundColor:'lightgrey'}}>
            REQUESTS</div>
          <div className={linkClass}>
          <Link to={'/activity/ContactReqForDealerList'} >
          <b>Contact Requests Received</b>
        </Link>
          </div>

          <div className={linkHeadClass} style={{backgroundColor:'lightgrey'}}>
            ADVERTISEMENTS</div>
            <div className={linkClass}>
          <Link to={'/static/StaticSearchList'} >
          <b>Book Static Advertisement</b>
        </Link>
          </div>
          
            <div className={linkClass}>
          <Link to={'/activity/AdByFinancerList'} >
          <b>Financer Advertisement Details</b>
        </Link>
          </div>
          
          <div className={linkClass}>
          <Link to={'/activity/AdByDealerList'} >
          <b>Dynamic Advertisement Details</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/static/StaticDealerAdByDealer'} >
          <b>Static Dealer Advertisement Details</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/static/StaticPropAdByDealer'} >
          <b>Static Property Advertisement Details</b>
        </Link>
          </div>
          

          {/* <div className={linkHeadClass} style={{backgroundColor:'lightgrey'}}>
            SHORTLISTS</div>
            <div className={linkClass}>
          <Link to={'/projectR/ShortlistProject'} >
          <b>Shortlist Projects For Ad</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/propresi/ShortlistResi'} >
          <b>Shortlist Residential Properties For Ad</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/propcom/ShortlistCom'} >
          <b>Shortlist Commercial Properties For Ad</b>
        </Link>
          </div> */}
         
         

          <div className={linkHeadClass} style={{backgroundColor:'lightgrey'}}>
            REQUIREMENTS</div>
          <div className={linkClass}>
          <Link to={'/reqresi/ReqResiUnlockedList'} >
          <b>Unlocked Residential Requirements</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={'/reqcom/ReqComUnlockedList'} >
          <b>Unlocked Commercial Requirements</b>
        </Link>
          </div>

          </div>)
          
          case 'Builder':
            return (<div>

<div className={linkClass}>
          <Link to={`/company/UserDetail/${userA._id}`} >
          <b>User Details</b>
        </Link>
          </div>
          <div className={linkClass}>
          <Link to={`/builder/mycompany`} >
          <b>Company Details</b>
        </Link>
          </div>

          
          <div className={linkClass}>
          <Link to={`/passchange`} >
          <Icon name='settings' />
          <b>Change Password</b>
        </Link>
          </div>

          <div className={linkHeadClass} style={{backgroundColor:'lightgrey'}}>
            REQUESTS</div>
          <div className={linkClass}>
          <Link to={'/activity/ContactReqForBuilderList'} >
          <b>Contact Requests Received</b>
        </Link>
          </div>
            </div>)
            

          default:
            return (
            
              <div className='card-attached pxy-20 '
              style={{minHeight:'60vh',minWidth:'40vh'}}
              >
                
                
                <div className="border pxy-20">
                <b>To View Please</b>
                {' '}{renderLoginLogoutButton()}
                </div>
                
                <div className="border pxy-20">
                <b>If Not Registered Please </b>
                {' '}{renderRegisterButton()}
                </div>
  
                </div>
            
            )
        }


        }
//@@@@@@@@@@@@@@@@

const postPropertyRequirementLink=() => {

  return (
<div >
  <div className={linkHeadClass} >
    POST PROPERTIES</div> 

  <div className={linkClass}>
  <Link to={'/propresi/PropResiCreate'} >
  <b> Residential </b>
</Link>
  </div>

  <div className={linkClass}>
  <Link to={'/propcom/PropComCreate'} >
  <b> Commercial </b>
</Link>
  </div>

  
  <div className={linkHeadClass} >
    POST REQUIREMENTS</div>

  <div className={linkClass}>
  <Link to={'/reqresi/ReqResiCreate'} >
  <b>Residential</b>
</Link>
  </div>

  <div className={linkClass}>
  <Link to={'/reqcom/ReqComCreate'} >
  <b>Commercial</b>
</Link>
  </div>
  
  
  </div>
  )

}




//@@@@@@@@@@@@
        
//########################################
        return (
            <div style={{marginTop:"50px",}}>

            <Sidebar.Pushable >
            <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            // inverted
            vertical
            visible={this.props.visible}
            onClick={this.props.hideSidebar  }
            style={{minWidth:'320px'}}
          >
           
            <div className='pxy-10'> {renderLoginLogoutButton()}</div>
          
          <div className={linkHeadClass} style={{backgroundColor:'skyblue'}}>
            BUY</div>
            <div
            className={linkClass}
            onClick={this.handleSearchPropResiSaleFH}
            >

        <span>Residential Property</span>

          </div>
          <div
            className={linkClass}
            onClick={this.handleSearchPropComSaleFH}
            >

        <span>Commercial Property</span>

          </div>
          <div className={linkClass} >
          <Link to="/propresi/BuilderFloorSaleSearchList" >
          <b>Builder Floors</b>
        </Link>
          </div>
          {/*  */}
          <div className={linkHeadClass} style={{backgroundColor:'skyblue'}}>
            RENT</div>
            <div
            className={linkClass}
            onClick={this.handleSearchPropResiRentFH}
            >

        <span>Residential Property</span>

          </div>
          <div
            className={linkClass}
            onClick={this.handleSearchPropComRentFH}
            >

        <span>Commercial Property</span>

          </div>
          {/*  */}
          <div className={linkHeadClass} style={{backgroundColor:'skyblue'}}>
            PROJECTS</div>

            <div
            className={linkClass}
            onClick={this.handleSearchProjectRFH}
            >

          <span>Residential Project</span>

          </div>
          <div
            className={linkClass}
            onClick={this.handleSearchProjectCFH}
            >

            <span>Commercial Project</span>

          </div>

            
          {/*  */}
          <div className={linkHeadClass} style={{backgroundColor:'skyblue'}}>
            DEALERS</div>

            <div
            className={linkClass}
            onClick={this.handleSearchCompanyiesFH}
            >

            <span>All</span>

          </div>

            <div className={linkClass}>
          <Link to="/company/FinancerSearchList" >
          <b>Financers</b>
        </Link>
          </div>

          <div className={linkClass}>
          <Link to="/builder/BuilderSearchList" >
          <b>Builders</b>
        </Link>
          </div>
          
          {/*  */}
          <div className={linkHeadClass} style={{backgroundColor:'skyblue'}}>
            REQUIRE</div>

            <div
            className={linkClass}
            onClick={this.handleSearchRequireResiFH}
            >

        <span>Residential Property</span>

          </div>
          <div
            className={linkClass}
            onClick={this.handleSearchRequireComFH}
            >

        <span>Commercial Property</span>

          </div>
            {/*  */}
            {postPropertyRequirementLink()}
          {/*  */}
          <div className={linkHeadClass} >
          
        <Icon name='user outline' />PROFILE|ACTIVITY</div>
        {renderProfileActivityLink()}
          {/*  */}


<br/><br/>

              </Sidebar>
          <Sidebar.Pusher
          style={{minHeight:'100vh'}}
          >
         

        {this.props.children}
         </Sidebar.Pusher>
            </Sidebar.Pushable>

            </div>
        )
    }
}
const mapStateToProps = state => ({
  auth: state.auth,
  visible: state.auth.visible,
  userA: state.auth.userA,
});

export default connect(
  mapStateToProps,
  {getUserForAuth, logoutUser,hideSidebar,openModal } )(Layout);
