import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { toastr } from "react-redux-toastr";

import { closeModal, openModal } from "./modalAction";

import {
  SET_CURRENT_USER,
  FETCH_USER,
  FETCH_USERDETAIL, 
  SHOW_SIDEBAR,
  HIDE_SIDEBAR,
  SHOW_BACKBUTTON ,
  HIDE_BACKBUTTON , 

} from "./types";


//Update password
export const updatePassword = (userData, history) => async () => {
  try {
    const res = await axios.post('/api/users/updatePassword', userData);

    
const message = res.data.msg
  history.goBack() 
   toastr.success(message)
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};



// Login - Get User Token
export const loginUser = (values) => async dispatch => {
  try {
    const res = await axios.post("/api/users/login", values);

  //***************************
  if (res.data.msg ==='errorPass') {
    toastr.error('Password Incorrect');
    }
    if (res.data.msg ==='errorNoUser') {
      if(values.userTypeW === 'Individual' ){
        toastr.error('Either your User Id is Incorrect or there is No User with this Id');
      }else{
        toastr.error('Either your Company Id or User Id is Incorrect or there is No User with this Id');
      }
      
    }
    if (res.data.msg ==='errorInactive') {
      toastr.error('Your are not Active User. Please contact Support');
    }
    if (res.data.msg ==='errorSubscription') {
      toastr.error('Your Subscription has expired. Please contact Support');
    }


if(res.data.msg ==='success'){
  const { token } = res.data;


  // Set token to ls
  localStorage.setItem("jwtToken", token);

  // Set token to Auth header
  setAuthToken(token);
  // Decode token to get user data
  const decoded = jwt_decode(token);

  // Set current user
  dispatch(setCurrentUser(decoded));
  dispatch(closeModal());
  dispatch({ type: FETCH_USER, payload: res.data.userA });
  //dispatch({ type: TRANSPORT, payload: {parameter:res.data.parameter} });
  toastr.success('Login Successfully')
  
}


  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//##############################
//Form ReqForOTP
export const formPassReset = (userData, history) => async () => {
  try {
    const res = await axios.post('/api/ReqForOTP', userData);

    if(res.data.msg === 'error'){
      toastr.error("Please check you have entered  Wrong Details Or there is No User with these Details")
    }

      if(res.data.msg ==='success'){
        history.push(`/passreset`);   
        toastr.success('OTP is send at your Registered Email.')
      }
  } catch (error) {
   
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//****************************
//RESET PASSWORD
export const resetPassLogin = (userData, history) => async dispatch => {
  try {
    const res = await axios.put(`/api/resetPassword`, userData);

    if(res.data.msg === 'error'){
      toastr.error("OTP have Expired Or Invalid. Please Get Another.")
    }
      if(res.data.msg ==='success'){
        history.push(`/`); 
        dispatch(openModal('LoginModal'));
        toastr.success('Password Changed.You can Login with New Password ')
      }
    
    

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Log user out
export const logoutUser = () => async dispatch => {
  try {
    
        
      // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));

  dispatch({ type: FETCH_USER, payload: {userA:null,user:null} });
    window.location.href = '/';
   toastr.success('Logout Successfully')
} catch (error) {
  
  toastr.error("Oops", "Something went wrong.Please Try Again");
}


};
//*************************************************
export const clearStorage = () =>dispatch => {

  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  window.location.href = '/login';

};
//####################################################################


//fetch single User
export const fetchUser = id => async dispatch => {
  try {
    const res = await axios.get(`/api/UserDetail/${id}`);
    
    dispatch({ type: FETCH_USERDETAIL, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//*************************************************
export const getUserForAuth = () => async dispatch => {
  try {
    const res = await axios.get(`/api/GetUserForAuth`);
    
    dispatch({ type: FETCH_USER, payload: res.data });
  } catch (error) {
    //toastr.error("Oops", "Something went wrong.Please Try Again");(
    
  }
};

//****************************
////update User by admin user
export const updateUser = (id, values, history) => async () => {
  try {
    const res = await axios.put(`/api/UserEdit/${id}`, values);
   
    if (res.data.msg==='success') {
      
      toastr.success('User Updated Successfully');
     
    }
    history.push(`/company/UserDetail/${id}`);  
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};



//################################################# SHORTLIST

//add / remove property to residential shortlist
export const shortlistAddRemoveResi = (id) => async dispatch => {
 
try {
  const res = await axios.post("/api/ShortlistAddRemoveResi",{
    id:id,

  } );
  if(res.data.msg === 'removed'){
    toastr.error("Property Removed from Shortlist.")
  }else {
    if(res.data.msg ==='added'){
      toastr.success('Property Added to Shortlist')
    }
  }
 
  dispatch({ type: FETCH_USER, payload: res.data.userA });
} catch (error) {
  if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
}
};

//add / remove property to residential shortlist
export const shortlistAddRemoveCom = (id) => async dispatch => {
 
try {
  const res = await axios.post("/api/ShortlistAddRemoveCom",{
    id:id,

  } );
  if(res.data.msg === 'removed'){
    toastr.error("Property Removed from Shortlist.")
  }else {
    if(res.data.msg ==='added'){
      toastr.success('Property Added to Shortlist')
    }
  }
  
  dispatch({ type: FETCH_USER, payload: res.data.userA });
} catch (error) {
  if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
}
};

//add / remove Project to Project shortlist
export const shortlistAddRemoveProject = (id) => async dispatch => {
  
try {
  const res = await axios.post("/api/ShortlistAddRemoveProject",{
    id:id,

  } );
  if(res.data.msg === 'removed'){
    toastr.error("Project Removed from Shortlist.")
  }else {
    if(res.data.msg ==='added'){
      toastr.success('Project Added to Shortlist')
    }
  }
  
  dispatch({ type: FETCH_USER, payload: res.data.userA });
} catch (error) {
  if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
}
};

//add / remove Dealer to Dealer shortlist
export const shortlistAddRemoveDealer = (id) => async dispatch => {
 
try {
  const res = await axios.post("/api/ShortlistAddRemoveDealer",{
    id:id,

  } );
  if(res.data.msg === 'removed'){
    toastr.error("Dealer Removed from Shortlist.")
  }else {
    if(res.data.msg ==='added'){
      toastr.success('Dealer Added to Shortlist')
    }
  }
  
  dispatch({ type: FETCH_USER, payload: res.data.userA });
} catch (error) {
  if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
}
};

//@@@@@@@@@
//add / remove property to residential rejectlist
export const rejectlistAddRemoveResi = (id) => async dispatch => {
 
  try {
    const res = await axios.post("/api/RejectlistAddRemoveResi",{
      id:id,
  
    } );
    if(res.data.msg === 'removed'){
      toastr.error("Property Removed from Rejectlist.")
    }else {
      if(res.data.msg ==='added'){
        toastr.success('Property Added to Rejectlist')
      }
    }
   
    dispatch({ type: FETCH_USER, payload: res.data.userA });
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }
  };
  
  //add / remove property to residential rejectlist
  export const rejectlistAddRemoveCom = (id) => async dispatch => {
   
  try {
    const res = await axios.post("/api/RejectlistAddRemoveCom",{
      id:id,
  
    } );
    if(res.data.msg === 'removed'){
      toastr.error("Property Removed from Rejectlist.")
    }else {
      if(res.data.msg ==='added'){
        toastr.success('Property Added to Rejectlist')
      }
    }
    
    dispatch({ type: FETCH_USER, payload: res.data.userA });
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }
  };
  


//********************************** */
export const showSidebar = () => async dispatch => {
  try {

    dispatch({ type: SHOW_SIDEBAR, payload: true });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

export const hideSidebar = () => async dispatch => {
  try {

    dispatch({ type: HIDE_SIDEBAR, payload: false });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//********************************** */

export const showBackButton = () => async dispatch => {
  try {

    dispatch({ type: SHOW_BACKBUTTON, payload: true });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

export const hideBackButton = () => async dispatch => {
  try {

    dispatch({ type: HIDE_BACKBUTTON, payload: false });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//******************* */ viewCountChecker

export const viewCountChecker = ( values ) => async dispatch => {
  try {
   
    const res = await axios.put(`/api/ViewCountChecker`, values);
       dispatch({ type: FETCH_USER, payload: res.data }); 

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};