import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import PropResiReducer from './PropResiReducer';
//import PropResiRentReducer from './PropResiRentReducer';
import PropComReducer from './PropComReducer';
//import PropComRentReducer from './PropComRentReducer';
import ReqResiReducer from './ReqResiReducer';
//import ReqResiRentReducer from './ReqResiRentReducer';
import ReqComReducer from './ReqComReducer';
import BuildersReducer from './BuildersReducer';
import CompanysReducer from './CompanysReducer';
import ProjectRReducer from './ProjectRReducer';
import ProjectCReducer from './ProjectCReducer';
import DealerSuggestedReducer from './DealerSuggestedReducer';
import ImageReducer from './ImageReducer';
import VideoReducer from './VideoReducer';
import ContactReqReducer from './ContactReqReducer';
import ContactUsReducer from './ContactUsReducer';
import CreditAddReducer from './CreditAddReducer';
import ContactDetailRequireReducer from './ContactDetailRequireReducer';
import StaticReducer from './StaticReducer';
import ErrorReducer from './ErrorReducer';
//import SelectionReducer from './SelectionReducer';
import AuthReducer from './AuthReducer';
import ModalsReducer from './ModalReducer';
import PopupsReducer from './PopupReducer';
import CommonReducer from './CommonReducer';

export default combineReducers({
  commons: CommonReducer,
  modals: ModalsReducer,
  popups: PopupsReducer,
  auth: AuthReducer,
  form: formReducer,
  reqresis: ReqResiReducer,
  //reqresiRents: ReqResiRentReducer,
  reqcoms: ReqComReducer,
//  reqcomRents: ReqComRentReducer,
  propresis: PropResiReducer,
  propcoms: PropComReducer,
//  propcomRents: PropComRentReducer,

  statics: StaticReducer,
  builders: BuildersReducer,
  companys: CompanysReducer,
  projectCs: ProjectCReducer,
  projectRs: ProjectRReducer,
  dealerSuggesteds: DealerSuggestedReducer,
  videos: VideoReducer,
  images: ImageReducer,
  contactDetailRequires: ContactDetailRequireReducer,
  creditAdds: CreditAddReducer,
  contactReqs: ContactReqReducer,
  contactUss: ContactUsReducer,
  errors: ErrorReducer,
  toastr: toastrReducer,
});
